export function EditIcon2(props: any) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.3425 2.36662C26.0804 2.38502 25.8141 2.50408 25.6046 2.71361L24.7275 3.58061L26.4542 5.3192L27.3157 4.43663C27.7465 3.98335 27.7808 3.35907 27.3505 2.92876L27.0557 2.62297C26.863 2.43032 26.6045 2.34823 26.3425 2.36662ZM23.8934 4.41283L14.6384 13.6798L13.6185 15.9238C13.505 16.1844 13.8 16.4787 14.0607 16.3769L16.3834 15.4019L25.6384 6.14684L23.8934 4.41283ZM7.97882 5.16631C6.42773 5.16631 5.16632 6.42771 5.16632 7.97881V22.0212C5.16632 23.5723 6.42773 24.8337 7.97882 24.8337H22.0212C23.5723 24.8337 24.8337 23.5723 24.8337 22.0212V8.94011L22.9596 10.8151V22.0212C22.9596 22.5382 22.5391 22.9587 22.0221 22.9587H7.97882C7.46179 22.9587 7.04132 22.5382 7.04132 22.0212V7.97881C7.04132 7.46178 7.46179 7.04131 7.97882 7.04131H19.2819L21.1542 5.16631H7.97882Z"
        fill={props.color ? props.color : "#3FB2D2"}
      />
    </svg>
  );
}
