import {
  Box,
  Container,
  Image,
  Input,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { NewsVector } from '../../assets/NewsVector';
import newsHeader from '../../assets/newsHeader.png';
import style from '../../assets/style.json';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TasklistService from '../../services/tasklist-service';
import { PrimaryButton } from '../../components/Footer/styleComponent/PrimaryButton';

const RefreshUser = () => {
  const { t } = useTranslation();
  const [isPc] = useMediaQuery('(min-width: 1300px)');

  const [userId, setUserId] = useState('');

  const handleSubmit = async () => {
    try {
      await TasklistService.refreshUser(userId);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container
      position={'absolute'}
      top="200px"
      maxW="full"
      minH="100vh"
      padding={isPc ? 'unset' : 0}
      bg="#f4fbff"
      centerContent
    >
      <Box
        position={'relative'}
        width="full"
        rounded={'10px'}
        maxW={style['pc-view']}
        bg={isPc ? 'white' : '#f4fbff'}
        minHeight={'300px'}
        boxShadow={isPc ? '0px 0px 25px rgba(62, 62, 62, 0.08)' : 'unset'}
        borderTop={isPc ? '9px solid #20273D' : 'unset'}
        display={'flex'}
        flexDirection="column"
        paddingBlock={'20px'}
        paddingInline="30px"
      >
        <Text dir="rtl" mb={16} fontWeight="bold" fontSize={30}>
          REFRESH TASKLISTS
        </Text>
        <div className="flex flex-col items-center gap-10">
          <Input
            value={userId}
            color="#0E314E"
            bg="#fff"
            border="1.2521px solid #E2E2E2"
            fontWeight={'600'}
            onChange={(ev) => {
              setUserId(ev.target.value);
            }}
            placeholder="User ID (e.g. 6a7a5347-233b-4ab8-a6a7-17db4f98e246)"
          />
          <PrimaryButton styles={{ minW: 'full' }} onClick={handleSubmit}>
            {t('tasksForm.save')}
          </PrimaryButton>
        </div>
      </Box>
    </Container>
  );
};

export default RefreshUser;
