export function ArrowDownIcon(props: any) {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.71429L1.77273 0L6.5 4.57143L11.2273 0L13 1.71429L6.5 8L0 1.71429Z"
        fill={props.color ? props.color : "#20273D"}
      />
    </svg>
  );
}
