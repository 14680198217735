export function DateIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 6C6.8455 6 5.5 7.3455 5.5 9V23C5.5 24.6545 6.8455 26 8.5 26H23.5C25.1545 26 26.5 24.6545 26.5 23V9C26.5 7.3455 25.1545 6 23.5 6H8.5ZM8.5 11.5H23.5C24.0515 11.5 24.5 11.9485 24.5 12.5V23C24.5 23.5515 24.0515 24 23.5 24H8.5C7.9485 24 7.5 23.5515 7.5 23V12.5C7.5 11.9485 7.9485 11.5 8.5 11.5ZM13.5 13C13.224 13 13 13.224 13 13.5V14.5C13 14.776 13.224 15 13.5 15H14.5C14.776 15 15 14.776 15 14.5V13.5C15 13.224 14.776 13 14.5 13H13.5ZM17.5 13C17.224 13 17 13.224 17 13.5V14.5C17 14.776 17.224 15 17.5 15H18.5C18.776 15 19 14.776 19 14.5V13.5C19 13.224 18.776 13 18.5 13H17.5ZM21.5 13C21.224 13 21 13.224 21 13.5V14.5C21 14.776 21.224 15 21.5 15H22.5C22.776 15 23 14.776 23 14.5V13.5C23 13.224 22.776 13 22.5 13H21.5ZM9.5 16.5C9.224 16.5 9 16.724 9 17V18C9 18.276 9.224 18.5 9.5 18.5H10.5C10.776 18.5 11 18.276 11 18V17C11 16.724 10.776 16.5 10.5 16.5H9.5ZM13.5 16.5C13.224 16.5 13 16.724 13 17V18C13 18.276 13.224 18.5 13.5 18.5H14.5C14.776 18.5 15 18.276 15 18V17C15 16.724 14.776 16.5 14.5 16.5H13.5ZM17.5 16.5C17.224 16.5 17 16.724 17 17V18C17 18.276 17.224 18.5 17.5 18.5H18.5C18.776 18.5 19 18.276 19 18V17C19 16.724 18.776 16.5 18.5 16.5H17.5ZM21.5 16.5C21.224 16.5 21 16.724 21 17V18C21 18.276 21.224 18.5 21.5 18.5H22.5C22.776 18.5 23 18.276 23 18V17C23 16.724 22.776 16.5 22.5 16.5H21.5ZM9.5 20C9.224 20 9 20.224 9 20.5V21.5C9 21.776 9.224 22 9.5 22H10.5C10.776 22 11 21.776 11 21.5V20.5C11 20.224 10.776 20 10.5 20H9.5ZM13.5 20C13.224 20 13 20.224 13 20.5V21.5C13 21.776 13.224 22 13.5 22H14.5C14.776 22 15 21.776 15 21.5V20.5C15 20.224 14.776 20 14.5 20H13.5ZM17.5 20C17.224 20 17 20.224 17 20.5V21.5C17 21.776 17.224 22 17.5 22H18.5C18.776 22 19 21.776 19 21.5V20.5C19 20.224 18.776 20 18.5 20H17.5Z"
        fill="#20273D"
      />
    </svg>
  );
}
