import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Container,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Textarea,
  Divider,
  list,
  Alert,
  AlertIcon,
  AccordionIcon,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CompanyService from '../../services/company-service';
import TasklistService from '../../services/tasklist-service';
import Swal from 'sweetalert2';
import style from '../../assets/style.json';
import { PrimaryButton } from '../../components/Footer/styleComponent/PrimaryButton';
import { CirclePlusIcon } from '../../assets/CirclePlusIcon';
import { IntervalPicker } from '../../components/IntervalPicker';
import { TrashIcon } from '../../assets/TrashIcon';
import UtilSrvice from '../../services/util-service';

import { appConfig } from '../../configs/app';

import { EditIcon } from '../../assets/EditIcon';
import { DownloadIcon } from '../../assets/DownloadIcon';
import UserService from '../../services/user-service';
import { useTranslation } from 'react-i18next';

import { saveAs } from 'file-saver'; // You can keep file-saver for frontend use
import { getMimeType } from '../../services/mime';

const TIME_UNITS: { [key: string]: number } = {
  minute: 1000 * 60,
  day: 1000 * 60 * 60 * 24 * 1,
  week: 1000 * 60 * 60 * 24 * 7,
  month: 1000 * 60 * 60 * 24 * 30,
  year: 1000 * 60 * 60 * 24 * 365,
};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (
  source: any[],
  destination: any[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone,
  };
};

const AddTasksForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [isHaveCopyFrom, setIsHaveCopyFrom] = useState(false);
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const [company, setCompany] = useState<any>({});
  const [title, setTitle] = useState('');
  const [items, setItems] = useState<{ [key: string]: { items: any[] } }>({});
  const [categories, setCategories] = useState<any[]>([]);
  const [assigneeId, setAssigneeId] = useState<string>('');
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const [companyTaskLists, setCompanyTaskLists] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [filesMap, setFilesMap] = useState<Record<string, any[]>>({});
  const [fileId, setFileId] = useState('');
  const [userId, setUserId] = useState('');
  const [downloadPath, setDownloadPath] = useState('');

  const [
    isHaveAnotherTaskListWithThisAssigneeId,
    setIsHaveAnotherTaskListWithThisAssigneeId,
  ] = useState(false);

  useEffect(() => {
    setIsHaveAnotherTaskListWithThisAssigneeId(
      companyTaskLists.some((taskList) => taskList.assigneeId === assigneeId),
    );
  }, [assigneeId, companyTaskLists]);

  const load = async () => {
    const companyId = searchParams.get('company_id');
    const copyFromId = searchParams.get('copyFrom');

    if (companyId) {
      const res = await CompanyService.read(companyId);
      const user = await UserService.info();
      setUserId(user.id);
      const companyTaskLists = await TasklistService.listCompany(companyId);
      console.log('companyTaskLists', companyTaskLists);
      setCompanyTaskLists(companyTaskLists);
      setFiles(
        companyTaskLists.map((item: any) => ({
          id: item.id,
          name: item.fileName,
          path: item.filePath,
          file: item.filePath,
          serverSide: true,
        })),
      );
      setFilesMap(
        companyTaskLists.reduce((acc: any, taskList: any) => {
          console.log({ taskList, keys: Object.keys(taskList) });
          taskList.list.forEach((item: any) => {
            const files = (item.filePaths ?? []).map(
              (filePath: string, idx: number, arr: any[]) => {
                return {
                  id: UtilSrvice.genId(),
                  name: item.fileNames[idx],
                  path: filePath,
                  file: filePath,
                  serverSide: true,
                };
              },
            );
            acc[item.id] = files;
          });

          return acc;
        }, {}),
      );
      setCompany(res);
      setCategories(res.exposureCategories);
      setItems(
        Object.assign(
          {},
          ...res?.exposureCategories?.map((category: any, i: number) => ({
            [category.id]: { items: [] },
          })),
        ),
      );
    }
    if (id) {
      const res = await TasklistService.read(id, undefined, true);
      setTitle(res.title);
      setAssigneeId(res.assigneeId);
      setCompany(res.company);
      setCategories(res.company.exposureCategories);
      setItems(
        res.company.exposureCategories.reduce((acc: any, category: any) => {
          acc[category.id] = {
            items: res.list
              ?.filter((item: any) => item.categoryId === category.id)
              .map((item: any) => ({
                ...item,
                interval: {
                  ...item.interval,
                  start: item.interval.start.split('T')[0],
                  end: item.interval.end.split('T')[0],
                },
              })),
          };
          console.log(acc);

          return acc;
        }, {}),
      );
    }

    if (copyFromId) {
      setIsHaveCopyFrom(true);
      const res = await TasklistService.read(copyFromId, undefined, true);
      setAssigneeId(res.assigneeId);
      setCategories(res.company.exposureCategories);
      const companyItems = res.list.reduce((acc: any, item: any) => {
        const newItem = {
          ...item,
          interval: {
            ...item.interval,
            start: item.interval.start.split('T')[0],
            end: item.interval.end.split('T')[0],
          },
        };
        delete newItem._id;
        delete newItem.categoryId;

        if (acc[item?.categoryId] && acc[item?.categoryId].items)
          acc[item?.categoryId].items.push(newItem);
        else acc[item?.categoryId] = { items: [newItem] };
        console.log(acc);

        return acc;
      }, {});
      console.log(res.company.exposureCategories);
      res?.company?.exposureCategories?.forEach((category: any) => {
        if (!companyItems[category.id])
          companyItems[category.id] = { items: [] };
      });

      setItems(companyItems);
    } else setIsHaveCopyFrom(false);
  };

  const deleteFile = async (id: string, taskId: string) => {
    const file = filesMap
      ? filesMap[taskId].find((file: any) => file.id === id)
      : null;
    if (file) {
      const result = await Swal.fire({
        title: t('addTasksForm.deleteFile') + `"${file.name}"?`,
        text: t('addTasksForm.deleteWarning'),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: t('addTasksForm.cancel'),
        confirmButtonText: t('addTasksForm.delete'),
      });
      if (result.isConfirmed) {
        const newFilesMap = { ...filesMap };
        newFilesMap[taskId] = newFilesMap[taskId].filter(
          (file: any) => file.id !== id,
        );
        setFilesMap(newFilesMap);
      }
    }
  };

  useEffect(() => {
    load();
  }, []);

  function onDragEnd(result: DropResult) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const newItems = reorder(
        items[source.droppableId].items,
        source.index,
        destination.index,
      );
      setItems({ ...items, [source.droppableId]: { items: newItems } });
    } else {
      const result = move(
        items[source.droppableId].items,
        items[destination.droppableId].items,
        source,
        destination,
      );

      setItems({
        ...items,
        [source.droppableId]: { items: result[source.droppableId] },
        [destination.droppableId]: { items: result[destination.droppableId] },
      });
    }
  }

  const isValid = () => {
    const isHaveItems = Object.keys(items).some((k) => {
      return items[k].items.map((i) => i).length > 0;
    });
    const haveEmpties = Object.keys(items).some((k) =>
      items[k].items.some((item) => !item.text),
    );

    return !haveEmpties && isHaveItems;
  };

  const addItem = (section: string) => {
    const newItems = items[section].items;
    const newItem = {
      text: '',
      id: (Math.random() + 1).toString(36).substring(7),
      interval: {
        amount: 1,
        unit: 'month',
        time: '08:00',
        start: UtilSrvice.dateLocalStringToInputFormat(
          new Date().toLocaleDateString('en-GB'),
        ),
        end: UtilSrvice.dateLocalStringToInputFormat(
          new Date(1735689600000).toLocaleDateString('en-GB'),
        ),
      },
    };
    newItems.push(newItem);
    setItems({ ...items, [section]: { items: newItems } });
  };

  const removeItem = (section: string, index: number) => {
    const newItems = items[section].items.filter((i, idx) => idx !== index);
    setItems({ ...items, [section]: { items: newItems } });
  };

  const onChangeText = (value: string, section: string, index: number) => {
    const newItems = items[section].items;
    newItems[index] = { ...newItems[index], text: value };
    setItems({ ...items, [section]: { items: newItems } });
  };

  const changeInterval = (section: string, index: number, value: any) => {
    const newItems = items[section].items;
    newItems[index] = { ...newItems[index], interval: value };
    setItems({ ...items, [section]: { items: newItems } });
  };

  const handleFileInput = (e: any) => {
    const id = fileId;
    const fileArr = Array.from(e.target!.files);
    let newFilesMap = { ...filesMap };
    fileArr.forEach((file: any) => {
      const itemFiles = newFilesMap[id] ? [...newFilesMap[id]] : [];
      const newFileId = UtilSrvice.genId();
      itemFiles.push({ id: newFileId, name: file.name, data: file });
      newFilesMap[id] = itemFiles;
    });
    setFilesMap(newFilesMap);
    e.target.value = '';
    e.target.files = null;
  };

  const downloadFile = async (fileName: string, path: string) => {
    /*
        DO
        NOT
        TOUCH
      */

    // now you can touch
    setDownloadPath(path);
    try {
      let response = await fetch(`${path}&fileName=${fileName}`);
      console.log({ response });

      let data = await response.blob();

      console.log({ data });

      let splittedFileName = fileName.split('.');
      let extension = splittedFileName.at(splittedFileName.length - 1);

      let fixedData = new Blob([data], {
        type: getMimeType(extension ?? ''),
      });
      saveAs(fixedData, fileName);
    } finally {
      setDownloadPath('');
    }
  };

  const uploadFile = async (id: string) => {
    setFileId(id);
    const input = document.getElementById('fileInput');

    input?.click();
  };

  const submit = async () => {
    setSubmitting(true);
    const dt = {
      companyId: company.id,
      title,
      assigneeId,
      list: Object.keys(items)
        ?.map((k) =>
          items[k].items?.map((x) => ({
            categoryId: k,
            ...x,
            interval: {
              ...x.interval,
              ms: x.interval?.amount * TIME_UNITS[x.interval?.unit],
            },
          })),
        )
        .flat(),
    };
    if (
      dt.list.some((task) => {
        if (!task.interval.start || !task.interval.end) return true;
        const startParts = task.interval.start.split('-');
        const endParts = task.interval.end.split('-');
        if ([...startParts, ...endParts].some((part) => isNaN(+part)))
          return true;
        if (
          new Date(task.interval.end).getTime() -
            new Date(task.interval.start).getTime() <=
          0
        )
          return true;
      })
    ) {
      Swal.fire(
        t('addTasksForm.error'),
        t('addTasksForm.invalidDate'),
        'warning',
      );
      setSubmitting(false);
      return;
    }
    console.log({ id });

    const res = !!id
      ? await TasklistService.update(id, dt, filesMap)
      : await TasklistService.create(dt, filesMap, userId);

    console.log(res);

    setSubmitting(false);
    navigate('/admin/tasklists?company_id=' + company.id);
    Swal.fire(
      t('addTasksForm.success'),
      t('addTasksForm.taskListCreated'),
      'success',
    );
  };

  return (
    <Container
      position="absolute"
      top={'90px'}
      maxW="full"
      py={20}
      centerContent
    >
      <Text
        color={'white'}
        dir="rtl"
        marginBottom={'77px'}
        fontWeight={500}
        fontSize={32}
      >
        {t('addTasksForm.addList')}
      </Text>
      <Box
        display={'flex'}
        flexDirection="column"
        alignItems={'center'}
        maxW={style['pc-view']}
        width="full"
        boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
        rounded="10px"
        bg="white"
        padding="21px"
      >
        {isPc && (
          <Box width={'100%'} display="flex" flexDirection="row-reverse">
            <Menu closeOnSelect={false}>
              <MenuButton
                rounded={'100px'}
                color="white"
                bg={'#3793AC'}
                as={Button}
                minWidth="188px"
                mb={'10px'}
              >
                {t('addTasksForm.job') +
                  ' ' +
                  company?.roles?.find((r: any) => r.id === assigneeId)?.name}
              </MenuButton>
              <MenuList overflowY="auto" maxH={'300px'}>
                <Text px={3} fontWeight="bold" mb={2}>
                  {t('addTasksForm.roles')}
                </Text>
                {company?.roles?.map((r: any, i: number) => (
                  <MenuItem
                    onClick={() => {
                      setAssigneeId(r.id);
                    }}
                    _hover={{
                      bg: '#F5F8FA',
                    }}
                    key={'role-' + i + '-' + r.id}
                    display="flex"
                    justifyContent={'space-between'}
                  >
                    <Text>{r.name}</Text>
                    {r.id === assigneeId && <Text color={'#3FB2D2'}>✓</Text>}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Box>
        )}

        <Text color={'#475154'} fontSize={'22px'} fontWeight="400">
          {company.name}
        </Text>
        {!isPc && (
          <Box
            width={'100%'}
            display="flex"
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Menu closeOnSelect={false}>
              <MenuButton
                rounded={'100px'}
                color="white"
                bg={'#3793AC'}
                as={Button}
                minWidth="188px"
                mb={'10px'}
                mt={'10px'}
              >
                {t('addTasksForm.job') +
                  ' ' +
                  company?.roles?.find((r: any) => r.id === assigneeId)?.name}
              </MenuButton>
              <MenuList overflowY="auto" maxH={'300px'}>
                <Text px={3} fontWeight="bold" mb={2}>
                  {t('addTasksForm.roles')}
                </Text>
                {company?.roles?.map((r: any, i: number) => (
                  <MenuItem
                    onClick={() => {
                      setAssigneeId(r.id);
                    }}
                    _hover={{
                      bg: '#F5F8FA',
                    }}
                    key={'role-' + i + '-' + r.id}
                    display="flex"
                    justifyContent={'space-between'}
                  >
                    <Text>{r.name}</Text>
                    {r.id === assigneeId && <Text color={'#3FB2D2'}>✓</Text>}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Box>
        )}

        <Text
          fontWeight="400"
          lineHeight={'60px'}
          fontSize={18}
          color="#A9A9A9"
          textAlign={'start'}
          width="100%"
        >
          {t('addTasksForm.tasks')}
        </Text>

        <DragDropContext onDragEnd={onDragEnd}>
          <Accordion
            display={'flex'}
            flexDirection="column"
            gap={'21px'}
            width={'100%'}
            allowMultiple
          >
            {!Object.keys(items)?.length && (
              <>
                <Text opacity={0.7}>{t('addTasksForm.noRisks')}!</Text>
                <Button
                  mt={5}
                  onClick={() => navigate(`/admin/company/${company.id}`)}
                >
                  {t('addTasksForm.addRisk')}
                </Button>
              </>
            )}
            {Object.keys(items)?.map((section, sectionIndex) => (
              <AccordionItem
                w={'full'}
                margin={'0px'}
                borderTop="0"
                borderBottom="0"
                background="#F5F8FA"
                rounded="10px"
              >
                <AccordionButton
                  justifyContent="space-between"
                  paddingBlock={'20px'}
                  _focus={{ outline: 0 }}
                  rounded={'10px'}
                >
                  <Text fontSize={22} fontWeight="600">
                    {categories.filter((x) => x.id === section)[0]?.name}
                  </Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel rounded={'10px'} padding={'21px'}>
                  <Droppable droppableId={section}>
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        w="100%"
                      >
                        {items[section].items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={sectionIndex + '-' + index}
                            index={index}
                          >
                            {(provided) => (
                              <Box>
                                <Box
                                  pos="relative"
                                  w="100%"
                                  py={1}
                                  display={'flex'}
                                  gap="23px"
                                  flexDirection={isPc ? 'row' : 'column'}
                                  borderBottom={
                                    isPc ? 'unset' : '1.5px solid #DFDFDF'
                                  }
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Box flexGrow={1}>
                                    <Text
                                      color={'#aaaaaa'}
                                      fontSize={'14px'}
                                      fontWeight="400"
                                      marginBottom="5px"
                                    >
                                      {t('addTasksForm.taskName')}
                                    </Text>
                                    <Textarea
                                      height={'54px'}
                                      bg="#fff"
                                      border="1.2521px solid #E2E2E2"
                                      borderRadius={'10px'}
                                      fontStyle="18px"
                                      paddingBlock={'15px'}
                                      paddingInline="20px"
                                      minH="54px"
                                      value={item?.text}
                                      onChange={(e) =>
                                        onChangeText(
                                          e.target.value,
                                          section,
                                          index,
                                        )
                                      }
                                      placeholder={t(
                                        'addTasksForm.taskDescription',
                                      )}
                                    />
                                  </Box>
                                  <Box
                                    display="flex"
                                    flexDirection="column-reverse"
                                    paddingBottom="10px"
                                  >
                                    <Button
                                      disabled={false}
                                      onClick={() => uploadFile(item.id)}
                                      minW={'188px'}
                                      textAlign="center"
                                      fontSize="18px"
                                      fontWeight={700}
                                      rounded={'100px'}
                                      borderWidth={'1.5px'}
                                      borderColor={style['primary-color']}
                                      bg={'#fff'}
                                      color={style['primary-color']}
                                    >
                                      <Box
                                        gap={'17px'}
                                        display={'flex'}
                                        alignItems={'center'}
                                      >
                                        <Text>{t('addTasksForm.addFile')}</Text>
                                        <CirclePlusIcon />
                                      </Box>
                                    </Button>
                                  </Box>

                                  <Box>
                                    <Text
                                      color={'#aaaaaa'}
                                      fontSize={'14px'}
                                      fontWeight="400"
                                      marginBottom="5px"
                                    >
                                      {t('addTasksForm.taskDetails')}
                                    </Text>
                                    <IntervalPicker
                                      changeInterval={(newInterval: any) => {
                                        changeInterval(
                                          section,
                                          index,
                                          newInterval,
                                        );
                                      }}
                                      interval={item?.interval}
                                    />
                                  </Box>
                                  <Button
                                    mt={isPc ? '30px' : '-10px'}
                                    bg="transparent"
                                    color={'#3FB2D2'}
                                    onClick={() => removeItem(section, index)}
                                    display="flex"
                                    gap="8px"
                                    justifyContent={
                                      isPc ? 'center' : 'flex-end'
                                    }
                                  >
                                    {isPc ? '' : t('addTasksForm.delete')}
                                    <TrashIcon />
                                  </Button>
                                </Box>
                                <Box>
                                  {filesMap[item.id] &&
                                    (filesMap[item.id] ?? []).map((file) => {
                                      const { name, id, path } = file;
                                      return (
                                        <Box
                                          display={'flex'}
                                          gap={'20px'}
                                          alignItems={'center'}
                                        >
                                          <Text
                                            fontSize={'18px'}
                                            lineHeight="60px"
                                            color={'#A9A9A9'}
                                            fontWeight={400}
                                          >
                                            {name ??
                                              t('addTasksForm.file') + id}
                                            {file.date
                                              ? ` - ${UtilSrvice.timestampToDDMMYYYY(
                                                  file.date.getTime(),
                                                )}`
                                              : ''}
                                          </Text>
                                          <Button
                                            display="flex"
                                            padding={'15px'}
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={'20px'}
                                            color={style['primary-color']}
                                            width="max-content"
                                            height="max-content"
                                            bg={'#fff'}
                                            isLoading={downloadPath === path}
                                            isDisabled={downloadPath === path}
                                            boxShadow="0px 0px 20px rgba(62, 62, 62, 0.11)"
                                            onClick={() =>
                                              downloadFile(
                                                name,
                                                `${
                                                  appConfig.apiUrl
                                                }/file?path=${path}&token=${localStorage.getItem(
                                                  'token',
                                                )}`,
                                              )
                                            }
                                          >
                                            <DownloadIcon />
                                            <Text color="#2D2F32">
                                              {t('addTasksForm.download')}
                                            </Text>
                                          </Button>

                                          {/* @ts-ignore */}
                                          <Button
                                            display="flex"
                                            padding={'15px'}
                                            alignItems="center"
                                            justifyContent="center"
                                            gap={'20px'}
                                            color={style['primary-color']}
                                            width="max-content"
                                            height="max-content"
                                            bg={'#fff'}
                                            boxShadow="0px 0px 20px rgba(62, 62, 62, 0.11)"
                                            onClick={() =>
                                              deleteFile(file.id, item.id)
                                            }
                                          >
                                            <TrashIcon />
                                            <Text color="#2D2F32">
                                              {t('addTasksForm.delete')}
                                            </Text>
                                          </Button>
                                        </Box>
                                      );
                                    })}
                                </Box>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                  {items[section].items.length === 0 ? (
                    <Button
                      width={'full'}
                      onClick={() => addItem(section)}
                      alignSelf="center"
                      color={'#3FB2D2'}
                      bg="transparent"
                      height="78px"
                      display={'flex'}
                      gap="16px"
                      border="1.2521px dashed #3FB2D2"
                    >
                      <Text>{t('addTasksForm.addTask')}</Text>
                      <CirclePlusIcon />
                    </Button>
                  ) : (
                    <Box display="flex" flexDirection="row-reverse">
                      <Button
                        onClick={() => addItem(section)}
                        alignSelf="center"
                        color={'#3FB2D2'}
                        bg="transparent"
                        marginTop={'20px'}
                        display={'flex'}
                        gap="16px"
                      >
                        <Text>{t('addTasksForm.addTask')}</Text>
                        <CirclePlusIcon />
                      </Button>
                    </Box>
                  )}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </DragDropContext>
        <Box
          marginTop={'44px'}
          display={'flex'}
          flexDirection="row-reverse"
          width={'full'}
        >
          <Button
            color={'white'}
            minW={isPc ? '200px' : 'full'}
            height="60px"
            textAlign="center"
            border="3px solid #FFFFFF"
            background={'#3FB2D2'}
            fontSize="18px"
            disabled={!assigneeId || !isValid() || submitting}
            fontWeight={700}
            rounded={'100px'}
            filter="drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))"
            onClick={async () => {
              if (isHaveAnotherTaskListWithThisAssigneeId && !isHaveCopyFrom) {
                const result = await Swal.fire({
                  title: `Delete the old tasklist?`,
                  text: 'You already have a tasklist that connect to this role create this will delete the old one.',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!',
                });
                if (result.isConfirmed) submit();
              } else {
                submit();
              }
            }}
          >
            {t('addTasksForm.save')}
          </Button>
        </Box>
      </Box>
      <input
        type="file"
        id="fileInput"
        style={{ visibility: 'hidden', width: 0, height: 0 }}
        onChange={(e) => handleFileInput(e)}
        multiple
      ></input>
    </Container>
  );
};

export default AddTasksForm;
