import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import i18n from './i18n';

type defaultValue = {
  locale: string;
  setLocale: Dispatch<SetStateAction<string>>;
};

export const LanguageContext = React.createContext({} as any);

const LanguageContextProvider = ({ children }: any) => {

  const cachedLocale = localStorage.getItem('locale');

  const [locale, setLocale] = useState<any>(cachedLocale);
  const [isLoading, setIsLoading] = useState(true);


useEffect(()=>{
  i18n.changeLanguage(locale)
},[locale])

  return (
    <LanguageContext.Provider value={{ locale, setLocale, isLoading, setIsLoading }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
