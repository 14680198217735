export function TrashIcon(props: any) {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `scale(${props.scale ? props.scale : 1})`,
      }}
    >
      <path
        d="M8.125 0.28125C6.83266 0.28125 5.78125 1.33266 5.78125 2.625V4.03125H1.09375C0.57625 4.03125 0.15625 4.45125 0.15625 4.96875C0.15625 5.48625 0.57625 5.90625 1.09375 5.90625H2.08252L2.8717 20.1244C2.95514 21.6141 4.1876 22.7812 5.67963 22.7812H14.3204C15.8119 22.7812 17.0449 21.6141 17.1283 20.1244L17.9175 5.90625H18.9062C19.4237 5.90625 19.8438 5.48625 19.8438 4.96875C19.8438 4.45125 19.4237 4.03125 18.9062 4.03125H14.2188V2.625C14.2188 1.33266 13.1673 0.28125 11.875 0.28125H8.125ZM8.125 2.15625H11.875C12.1337 2.15625 12.3438 2.36672 12.3438 2.625V4.03125H7.65625V2.625C7.65625 2.36672 7.86625 2.15625 8.125 2.15625ZM3.95935 5.90625H16.0406L15.256 20.02C15.2284 20.5169 14.8177 20.9062 14.3204 20.9062H5.67963C5.18275 20.9062 4.77161 20.5173 4.74396 20.0209L3.95935 5.90625ZM10 7.89844C9.54672 7.89844 9.17969 8.26594 9.17969 8.71875V18.0938C9.17969 18.5466 9.54672 18.9141 10 18.9141C10.4533 18.9141 10.8203 18.5466 10.8203 18.0938V8.71875C10.8203 8.26594 10.4533 7.89844 10 7.89844ZM6.55121 7.89935C6.09886 7.91529 5.74472 8.29432 5.76019 8.74713L6.08704 18.1624C6.1025 18.6054 6.46675 18.9543 6.90643 18.9543C6.91628 18.9543 6.92589 18.9543 6.93573 18.9543C7.38807 18.9384 7.74221 18.5585 7.72675 18.1057L7.3999 8.69037C7.38397 8.23802 7.00308 7.88857 6.55121 7.89935ZM13.4479 7.89935C12.9946 7.8867 12.616 8.23802 12.6001 8.69037L12.2733 18.1057C12.2573 18.5585 12.6115 18.9384 13.0643 18.9543C13.0741 18.9548 13.0837 18.9543 13.0936 18.9543C13.5333 18.9543 13.8975 18.6054 13.913 18.1624L14.2398 8.74713C14.2557 8.29432 13.9007 7.91529 13.4479 7.89935Z"
        fill="currentColor"
      />
    </svg>
  );
}
