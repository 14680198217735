export function EditIcon(props: any) {
  return (
    <svg
      style={{
        transform: `scale(${props.scale ? props.scale : 1})`,
      }}
      width="20"
      height="21"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5845 0.0449219C15.6564 0.0449219 14.7285 0.396749 14.0252 1.10035L2.57904 12.5454C2.36186 12.7626 2.20281 13.0327 2.11849 13.3292L0.584321 18.7013C0.546486 18.8342 0.544851 18.9748 0.579587 19.1086C0.614324 19.2424 0.684169 19.3644 0.781898 19.4622C0.879628 19.5599 1.00169 19.6297 1.13547 19.6645C1.26924 19.6992 1.40987 19.6976 1.5428 19.6597L6.91591 18.1256C6.91625 18.1252 6.91658 18.1249 6.91692 18.1246C7.21143 18.0399 7.48084 17.8828 7.69865 17.665L19.1438 6.21893C20.5509 4.81181 20.5509 2.50747 19.1438 1.10035C18.4405 0.396749 17.5125 0.0449219 16.5845 0.0449219ZM16.5845 1.58716C17.1124 1.58716 17.6395 1.79056 18.0459 2.19719C18.0463 2.19719 18.0466 2.19719 18.0469 2.19719C18.8613 3.01157 18.8613 4.30771 18.0469 5.12209L17.044 6.125L14.1191 3.2001L15.122 2.19719C15.5285 1.79056 16.0566 1.58716 16.5845 1.58716ZM13.0223 4.29694L15.9472 7.22184L6.6008 16.5682C6.57048 16.5983 6.53208 16.6214 6.48869 16.6338L2.45885 17.7852L3.61023 13.7544C3.61023 13.754 3.61023 13.7537 3.61023 13.7534C3.62209 13.7117 3.64381 13.6743 3.67588 13.6423L13.0223 4.29694Z"
        fill="currentColor"
      />
    </svg>
  );
}
