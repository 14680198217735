import { Button } from '@chakra-ui/react';

function RedButton() {
  return null;
  return (
    <Button
      background="red.500"
      color="white"
      position="fixed"
      bottom="10px"
      right="10px"
    >
      Red Button
    </Button>
  );
}

export default RedButton;
