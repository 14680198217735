import {
  Box,
  Container,
  Text,
  Image,
  useMediaQuery,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Input,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Checkbox,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { Logo } from '../../components/Footer/styleComponent/Logo';
import style from '../../assets/style.json';
import bg from '../../assets/bg.png';
import { NewsVector } from '../../assets/NewsVector';
import newsHeader from '../../assets/newsHeader.png';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import UserService from '../../services/user-service';
import CompanyService from '../../services/company-service';
import QuestionList from './components/question-list';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../locales/LocaleContext';
import { ArrowDownIcon } from '../../assets/ArrowDownIcon';
import { getDirection } from '../../locales/locale';

const steps = [
  { title: 'onboarding.personalInformation' },
  { title: 'onboarding.toc' },
  { title: 'onboarding.form' },
];

type OnboardingProps = {
  userInfo: any;
};

function Onboarding({ userInfo }: OnboardingProps) {
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale, setLocale } = useContext(LanguageContext);
  const { activeStep, goToNext, setActiveStep, goToPrevious } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [questIndex, setQuestIndex] = useState(0);

  const dir = getDirection(locale);

  const toggleLanguage = (newLanguage: string) => {
    localStorage.setItem('locale', newLanguage);
    setLocale(newLanguage);
  };

  const [personalInformation, setPersonalInformation] = useState<{
    name: string;
    roleIds: string[];
  }>({
    name: '',
    roleIds: [],
  });
  const [onboarding, setOnboarding] = useState<any>(null);
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [onboardingAnswers, setOnboardingAnswers] = useState<
    Record<string, string | string[]>
  >({});

  useEffect(() => {
    async function loadOnboarding() {
      const res = await CompanyService.getOnboardingQuestionnaire();
      setOnboarding(res);
      toggleLanguage(res.lang ?? 'he');
    }
    loadOnboarding();
  }, []);

  const onSubmit = async () => {
    await UserService.onFinishOnboarding(
      Object.entries(onboardingAnswers).map(([questionId, answers]) => ({
        questionId,
        answers,
      })),
      personalInformation.name,
      personalInformation.roleIds,
    );
    navigate('/');
  };

  const totalQuestions = onboarding?.questions.length || 0;
  const isHaveNextQuestion = questIndex < totalQuestions - 1;
  const ishavePrevQuestion = questIndex > 0;

  const isCurrentStepRequired = onboarding?.questions[questIndex].isRequired;
  console.log({ isCurrentStepRequired });

  if (!onboarding) return null;
  return (
    <>
      <Box position="absolute" width={'100%'} height="16px" bg="#3FB2D2"></Box>
      <Container
        backgroundColor={false ? '' : '#20273d'}
        backgroundPosition="center"
        backgroundImage={bg}
        width={'100%'}
        height={'400px'}
        maxW={'100%'}
        position="absolute"
        display="flex"
        justifyContent={'center'}
      >
        <Box marginTop={'60px'}>
          <Logo isShowStand={false} isDark={false} />
        </Box>
      </Container>
      <Container
        position={'absolute'}
        top="200px"
        maxW="full"
        minH="100vh"
        padding={isPc ? 'unset' : 0}
        bg="#f4fbff"
        centerContent
      >
        <Box
          position={'relative'}
          width="full"
          rounded={'10px'}
          maxW={style['pc-view']}
          bg={isPc ? 'white' : '#f4fbff'}
          minHeight={'300px'}
          boxShadow={isPc ? '0px 0px 25px rgba(62, 62, 62, 0.08)' : 'unset'}
          borderTop={isPc ? '9px solid #20273D' : 'unset'}
          display={'flex'}
          flexDirection="column"
          paddingBlock={'20px'}
          paddingInline="30px"
        >
          {isPc && (
            <>
              <Box position={'absolute'} left="0" top="0">
                <NewsVector />
              </Box>

              <Image
                src={newsHeader}
                position="absolute"
                left={'34px'}
                top={0}
              />
            </>
          )}

          <Text
            mb={16}
            mt={16}
            fontWeight="bold"
            fontSize={30}
            style={{ textAlign: 'center' }}
          >
            {t('onboarding.header')}
          </Text>

          <Box>
            <Stepper
              sx={{
                marginInline: 'auto',
              }}
              index={activeStep}
            >
              {steps.map((step, index) => (
                <Step>
                  <Box
                    display="flex"
                    flexDirection={'column'}
                    alignItems="center"
                    onClick={() => {
                      if (activeStep >= index) setActiveStep(index);
                    }}
                    key={index}
                    opacity={activeStep >= index ? 1 : 0.8}
                  >
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>{t(step.title)}</StepTitle>
                    </Box>
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <Box>
                <Box>
                  <Text
                    marginBottom={'5px'}
                    fontSize={'14px'}
                    color="#aaa"
                    fontWeight="14px"
                  >
                    {t('onboarding.name')}
                  </Text>
                  <Input
                    placeholder={t('onboarding.name')}
                    borderColor="#E2E2E2"
                    bg={'white'}
                    rounded={'10px'}
                    fontSize="16px"
                    fontWeight={400}
                    paddingInline="12px"
                    paddingBlock="30px"
                    value={personalInformation.name}
                    onChange={(e) => {
                      setPersonalInformation({
                        ...personalInformation,
                        name: e.target.value,
                      });
                    }}
                    maxLength={50}
                  />
                </Box>
                <Box display="flex" flexDirection="column" gap={4}>
                  <Text
                    marginBottom={'5px'}
                    fontSize={'14px'}
                    color="#aaa"
                    fontWeight="14px"
                  >
                    {t('onboarding.roles')}
                  </Text>
                  {!!onboarding.roles.length && (
                    <Menu closeOnSelect={false}>
                      <MenuButton
                        as={Box}
                        bg="white"
                        height={'54px'}
                        border="1px solid #e2e2e2"
                        rounded="10px"
                        width={'100%'} // was 350
                      >
                        <Box
                          padding="12px 17px"
                          display="flex"
                          flexDirection={'row'}
                          alignItems="center"
                          width={'full'}
                          justifyContent="space-between"
                        >
                          <Box width={'calc(100% - 20px)'}>
                            <Text
                              textOverflow={'ellipsis'}
                              width={'100%'}
                              overflow={'hidden'}
                              whiteSpace="nowrap"
                            >
                              {onboarding.roles
                                ?.filter((r: any) =>
                                  personalInformation.roleIds?.includes(r.id),
                                )
                                .map((r: any) => r.name)
                                .join(',') || t('onboarding.roles')}
                            </Text>
                          </Box>
                          <Box flexShrink={0}>
                            <ArrowDownIcon />
                          </Box>
                        </Box>
                      </MenuButton>
                      <MenuList overflowY="auto" maxH={'300px'}>
                        <Text px={3} fontWeight="bold" mb={2}>
                          {t('modifyCompany.roles')}
                        </Text>
                        {(onboarding.roles ?? []).map((r: any, i: number) => (
                          <MenuItem key={'role-' + i + '-' + r.id}>
                            <Checkbox
                              mr={2}
                              colorScheme="white"
                              borderColor="#3FB2D2"
                              iconColor="#3FB2D2"
                              iconSize={'17px'}
                              size="lg"
                              id={r.id}
                              name={r.id}
                              isChecked={personalInformation.roleIds.includes(
                                r.id,
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setPersonalInformation({
                                    ...personalInformation,
                                    roleIds: [
                                      ...personalInformation.roleIds,
                                      r.id,
                                    ],
                                  });
                                } else {
                                  setPersonalInformation({
                                    ...personalInformation,
                                    roleIds: personalInformation.roleIds.filter(
                                      (id) => id !== r.id,
                                    ),
                                  });
                                }
                              }}
                            >
                              {r.name}
                            </Checkbox>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  )}

                  <Button
                    isDisabled={
                      !personalInformation.name.trim() ||
                      (!personalInformation.roleIds.length &&
                        onboarding.roles.length)
                    }
                    onClick={goToNext}
                  >
                    {t('onboarding.next')}
                  </Button>
                </Box>
              </Box>
            )}
            {activeStep === 1 && (
              <Box>
                <pre dir={dir} style={{ whiteSpace: 'pre-wrap' }}>
                  {t('termsOfUse.contents')}
                </pre>
                {userInfo && (
                  <FormControl
                    display="flex"
                    flexDirection="column"
                    gap="1rem"
                    mt="2rem"
                  >
                    <FormLabel
                      dir=""
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <Checkbox
                        size="lg"
                        colorScheme="white"
                        borderColor="#3FB2D2"
                        iconColor="#3FB2D2"
                        iconSize={'17px'}
                        isChecked={isAcceptTerms}
                        onChange={() => setIsAcceptTerms((prev) => !prev)}
                      />
                      {t('termsOfUse.readAndApprove')}
                    </FormLabel>
                    <Button isDisabled={!isAcceptTerms} onClick={goToNext}>
                      {t('onboarding.next')}
                    </Button>
                  </FormControl>
                )}
              </Box>
            )}
            {activeStep === 2 && (
              <Box display="flex" flexDirection="column" gap={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={4}
                  className="divide-y"
                >
                  <QuestionList
                    onboardingAnswers={onboardingAnswers}
                    setOnboardingAnswers={setOnboardingAnswers}
                    questions={onboarding.questions}
                    questionIndex={questIndex}
                    setQuestionIndex={setQuestIndex}
                  />
                </Box>
                {isHaveNextQuestion ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={4}
                    className="w-full"
                  >
                    <span className="w-full text-center">
                      {questIndex + 1}/{totalQuestions}
                    </span>
                    <Box display="flex" gap={4} className="w-full">
                      {ishavePrevQuestion && (
                        <Button
                          onClick={() => setQuestIndex((prev) => prev - 1)}
                          className="w-full"
                        >
                          {t('onboarding.prev')}
                        </Button>
                      )}
                      <Button
                        onClick={() => setQuestIndex((prev) => prev + 1)}
                        className="w-full"
                        isDisabled={
                          isCurrentStepRequired &&
                          !onboardingAnswers[
                            onboarding.questions[questIndex].id
                          ]
                        }
                      >
                        {t('onboarding.next')}
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={4}
                    className="w-full"
                  >
                    <span className="w-full text-center">
                      {questIndex + 1}/{totalQuestions}
                    </span>

                    <Box display="flex" gap={4} className="w-full">
                      <Button
                        onClick={() => setQuestIndex((prev) => prev - 1)}
                        className="w-full"
                      >
                        {t('onboarding.prev')}
                      </Button>

                      <Button
                        isDisabled={(onboarding?.questions ?? []).some(
                          (q: any) =>
                            q.isRequired &&
                            (!onboardingAnswers[q.id] ||
                              !onboardingAnswers[q.id].length),
                        )}
                        onClick={onSubmit}
                        colorScheme="blue"
                        className="w-full"
                      >
                        {t('onboarding.next')}
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Onboarding;
