export function LogoutIcon() {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2688 26.8809C21.6037 26.8809 27.5703 20.9064 27.5703 13.562C27.5703 6.21755 21.6037 0.243137 14.2688 0.243136C9.77864 0.243136 5.79943 2.47595 3.39199 5.89671L5.19324 7.18004C7.198 4.32724 10.5104 2.46294 14.2688 2.46294C20.4043 2.46295 25.3534 7.4185 25.3534 13.562C25.3534 19.7055 20.4043 24.661 14.2688 24.661C10.5104 24.661 7.20233 22.7968 5.19324 19.944L3.39199 21.2273C5.79943 24.648 9.77864 26.8809 14.2688 26.8809ZM6.1285 18.7994L7.72192 17.2039L5.19324 14.6719L18.7026 14.6719L18.7026 12.4521L5.19324 12.4521L7.72192 9.92012L6.12851 8.32463L1.69466 12.7643L0.93259 13.562L1.69466 14.3597L6.1285 18.7994Z"
        fill="#FF0000"
      />
    </svg>
  );
}
