import { Box, Container, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { NewsVector } from "../../assets/NewsVector";
import newsHeader from "../../assets/newsHeader.png";
import style from "../../assets/style.json";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  const [isPc] = useMediaQuery("(min-width: 1300px)");
  return (
    <Container
      position={"absolute"}
      top="200px"
      maxW="full"
      minH="100vh"
      padding={isPc ? "unset" : 0}
      bg="#f4fbff"
      centerContent
    >
      <Box
        position={"relative"}
        width="full"
        rounded={"10px"}
        maxW={style["pc-view"]}
        bg={isPc ? "white" : "#f4fbff"}
        minHeight={"300px"}
        boxShadow={isPc ? "0px 0px 25px rgba(62, 62, 62, 0.08)" : "unset"}
        borderTop={isPc ? "9px solid #20273D" : "unset"}
        display={"flex"}
        flexDirection="column"
        paddingBlock={"20px"}
        paddingInline="30px"
      >
        <Text dir="rtl" mb={16} fontWeight="bold" fontSize={30}>
          {t("about.title")}
        </Text>
        {t("about.contents")}
      </Box>
    </Container>
  );
};
export default About;
