export function ReapetIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1338 6.00307C22.9567 6.02057 22.7843 6.10739 22.6631 6.27169L21.3899 7.99786C19.163 6.49969 16.3447 5.83397 13.4263 6.40646C8.60836 7.3517 5.04727 11.5937 4.99955 16.4579C4.93751 22.7669 10.3874 27.5595 16.5478 26.947C21.9719 26.4072 26.0161 21.8689 25.999 16.5798C25.9971 16.0292 25.5041 15.606 24.9467 15.6613C24.4485 15.7109 24.0906 16.1247 24.0901 16.6158C24.0877 20.9299 20.7848 24.6252 16.3577 25.0657C11.3188 25.5667 6.85974 21.648 6.90842 16.4875C6.94612 12.4868 9.90472 8.99338 13.8737 8.24617C16.2134 7.80581 18.4658 8.34183 20.2602 9.5311L18.9507 11.3062C18.6276 11.7443 18.9439 12.3594 19.4913 12.3594L24.8283 12.3604C25.2817 12.3604 25.6037 11.9232 25.4658 11.4954L23.8412 6.46092C23.7368 6.13806 23.429 5.97391 23.1338 6.00307Z"
        fill="currentColor"
      />
    </svg>
  );
}
