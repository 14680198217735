import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Container,
  SimpleGrid,
  Text,
  VStack,
  SlideFade,
  ScaleFade,
  Spinner,
  Heading,
  useMediaQuery,
  Skeleton,
  SkeletonCircle
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ExposureService from '../../services/exposure-service';
import CompanyService from '../../services/company-service';
import UserService from '../../services/user-service';
import style from '../../assets/style.json';
import { PrimaryButton } from '../../components/Footer/styleComponent/PrimaryButton';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../locales/LocaleContext';

const Exposure = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [company, setCompany] = useState<any>({});
  const [totalExposure, setTotalExposure] = useState<number | null>(null);
  const [categories, setCategories] = useState<any>({});
  const { companyId } = useParams();
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const { isLoading, setIsLoading } = useContext(LanguageContext);


  const load = async () => {
    setIsLoading(true);

    try {
      const user = await UserService.info();
      console.log(companyId);

      let exposure = { total: 0 };
      let company = await CompanyService.read(
        companyId != undefined ? companyId : user.companyId,
      );
      setCompany(company);

      if (companyId) {
        exposure = await ExposureService.getById(companyId);
      } else {
        exposure = await ExposureService.read();
        console.log({ exposure });
      }

      setTotalExposure(exposure.total);

      let categories = { categories: {} };

      if (companyId) {
        categories = await ExposureService.companyCategories(companyId);
        console.log({ categories });
      } else {
        categories = await ExposureService.categories();
        console.log({ categories });
      }

      setCategories(categories.categories);
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    load();
  }, []);

  const companyExposure = useMemo(() => {
    const categoriesArray = Object.values(categories);
    if (!categoriesArray) return 0;
    if (categoriesArray.length === 0) return 1;
    const total = categoriesArray.reduce(
      (acc: number, category: any) => acc + category.exposure,
      0,
    );
    return total / categoriesArray.length;
  }, [categories]);

  return (
    //@ts-ignore
    <Container
      maxW="full"
      position="absolute"
      top={'90px'}
      py={20}
      centerContent
    >
      <SlideFade in>
        <Text
          color={'white'}
          dir="rtl"
          marginBottom={'77px'}
          fontWeight={500}
          fontSize={32}
        >
          {t('exposure.performance')}
        </Text>
      </SlideFade>
      <Box
        w="100%"
        maxW={style['pc-view']}
        bg="#fff"
        p={6}
        rounded="10px"
        boxShadow={'0px 0px 25px rgba(62, 62, 62, 0.08)'}
      >
        <SlideFade in>
          <Box
            w="100%"
            display={'flex'}
            flexDirection={isPc ? 'row' : 'column'}
            justifyContent="center"
            alignItems="center"
            gap={isPc ? '30px' : '10px'}
            paddingBottom="20px"
          >
            <Box textAlign={isPc ? 'start' : 'center'} paddingTop="25px">
              <Text color={'#475154'} fontWeight="400" fontSize={22}>
                {company.name}
              </Text>

              <Text
                lineHeight={isPc ? '60px' : '25px'}
                fontWeight="400"
                color={'#A9A9A9'}
                fontSize={18}
              >
                {t('exposure.generalPerformance')}
              </Text>
            </Box>
            <CircularProgress
              mt={3}
              value={
                Object.keys(categories).length === 0
                  ? 0
                  : (companyExposure || 0) * 100
              }
              size="140px"
              thickness="10px"
              color={
                (companyExposure || 0) > 0.8
                  ? '#3FB2D2'
                  : (companyExposure || 0) > 0.4
                    ? '#F6A860'
                    : '#E55353'
              }
            >
              <CircularProgressLabel
                fontWeight="bold"
                fontSize={companyExposure === null ? 15 : 22}
                opacity={companyExposure === null ? 0.8 : 1}
              >
                {Object.keys(categories).length === 0
                  ? t('exposure.processing') + '...'
                  : (companyExposure * 100).toFixed(0) + '%'}
              </CircularProgressLabel>
            </CircularProgress>
          </Box>
        </SlideFade>

        <Text
          lineHeight={isPc ? '60px' : '18px'}
          fontWeight="400"
          fontSize={18}
          textAlign={isPc ? 'center' : 'start'}
          color="#A9A9A9"
        >
          {t('exposure.risks')}
        </Text>

        <Box
          w="100%"
          minH={240}
          py={'21px'}
        >
          {!!Object.keys(categories).length ? (
            <SimpleGrid w="100%" columns={isPc ? 3 : 1} spacing={4}>
              {Object.keys(categories)?.map((id, sectionIndex) => (
                <Box
                  bg="#F5F8FA"
                  key={'section-' + sectionIndex}
                  w="100%"
                  display={'flex'}
                  justifyContent="space-between"
                  padding={'20px'}
                  rounded="10px"
                >
                  <Box
                    height={'100%'}
                    display="flex"
                    flexDirection={'column'}
                    justifyContent="space-between"
                    alignItems={'flex-start'}
                  >
                    <Text
                      fontWeight="600"
                      fontSize={18}
                      opacity={1}
                      color="#0E314E"
                    >
                      {categories[id].name}
                    </Text>
                    <PrimaryButton
                      onClick={() =>
                        navigate(
                          companyId
                            ? `/admin/exposure/${id}/${companyId}`
                            : `/exposure/${id}`,
                        )
                      }
                    >
                      {t('exposure.check')}
                    </PrimaryButton>
                  </Box>

                  <ScaleFade in initialScale={0}>
                    <VStack w="100%" spacing={1}>
                      <CircularProgress
                        value={(categories[id].exposure || 0) * 100}
                        size="100px"
                        thickness="10px"
                        color={
                          categories[id].exposure > 0.8
                            ? '#3FB2D2'
                            : categories[id].exposure > 0.4
                              ? '#F6A860'
                              : '#E55353'
                        }
                      >
                        <CircularProgressLabel fontWeight="bold" fontSize={19}>
                          {((categories[id].exposure || 0) * 100).toFixed(0)}%
                        </CircularProgressLabel>
                      </CircularProgress>
                    </VStack>
                  </ScaleFade>
                </Box>
              ))}
            </SimpleGrid>
          ) : (
            <SimpleGrid w="100%" columns={isPc ? 3 : 1} spacing={4}>
              {[...Array(3)].map((_, index) => (
                <Box
                  key={index}
                  bg="#F5F8FA"
                  w="100%"
                  display={'flex'}
                  justifyContent="space-between"
                  padding={'20px'}
                  rounded="10px"
                >
                  <Box
                    height={'100%'}
                    display="flex"
                    flexDirection={'column'}
                    justifyContent="space-between"
                    alignItems={'flex-start'}
                  >
                    <Skeleton height="20px" width="60%" mb="12px" />
                    <Skeleton height="36px" width="200px" borderRadius={"3xl"} />
                  </Box>

                  <VStack w="100%" spacing={1} justifyContent={"end"} alignItems={"end"}>
                    <SkeletonCircle size='100' />
                  </VStack>
                </Box>
              ))}
            </SimpleGrid>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Exposure;
