import getLocale from './locale';
import { LanguageDetectorAsyncModule } from 'i18next';

// https://github.com/i18next/react-i18next/blob/master/example/v9.x.x/reactnative-expo/js/i18n.js
const languageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  detect: (callback: Function) => getLocale().then(locale => callback(locale)),
  init: () => {},
  cacheUserLanguage: () => {},
};

export default languageDetector;
