import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dateService } from "../services/dateService";

type PrivateProps = {
  date: Date;
  setDate: (date: Date) => void;
  changeShowDateRef: any;
  setSelectedMonth: (number: number) => void;
  setSelectedYear: (number: number) => void;
};

export function Calander({
  date = new Date(),
  setDate,
  changeShowDateRef,
  setSelectedMonth,
  setSelectedYear,
}: PrivateProps) {
  const { t } = useTranslation();
  const [showDate, setShowDate] = useState(date);
  const startMonth = dateService.startMonth(showDate);
  const endMonth = dateService.endMonth(showDate);
  const currMonthDays = dateService.diffrenceInDays(startMonth, endMonth) + 1;
  const startMonthDay = startMonth.getDay();
  const endMonthDay = endMonth.getDay();
  const prevMonthDate = dateService.prevMonth(showDate);
  const nextMonthDate = dateService.nextMonth(showDate);
  const prevMonthLastDay = dateService.endMonth(prevMonthDate).getDate();
  if (changeShowDateRef) changeShowDateRef.current = setShowDate;

  const days = [
    t('calendar.sunday'),
    t('calendar.monday'),
    t('calendar.tuesday'),
    t('calendar.wednesday'),
    t('calendar.thursday'),
    t('calendar.friday'),
    t('calendar.saturday'),
  ];

  const month = [
    t('month.january'),
    t('month.february'),
    t('month.march'),
    t('month.april'),
    t('month.may'),
    t('month.june'),
    t('month.july'),
    t('month.august'),
    t('month.september'),
    t('month.october'),
    t('month.november'),
    t('month.december'),
  ];

  useEffect(() => {
    setSelectedMonth(showDate.getMonth());
    setSelectedYear(showDate.getFullYear());
  }, [showDate]);

  const onShowedMonthDateClicked = (selectedDate: Date) => {
    setDate(selectedDate);
  };

  const onUnshowedMonthClicked = (selectedDate: Date) => {
    setDate(selectedDate);
    setShowDate(selectedDate);
  };

  const prevMonth = () => {
    setShowDate((prev) => dateService.prevMonth(prev));
  };

  const nextMonth = () => {
    setShowDate((prev) => dateService.nextMonth(prev));
  };

  const prevYear = () => {
    setShowDate((prev) => dateService.prevYear(prev));
  };

  const nextYear = () => {
    setShowDate((prev) => dateService.nextYear(prev));
  };

  return (
    <div className="calander">
      <div className="week-days">
        {Array.from(Array(7).keys()).map((day) => (
          <div key={day} className="week-day">
            {days[day]}
          </div>
        ))}
      </div>
      <div className="calender-days">
        <div className="month-days">
          {Array.from(Array(startMonthDay).keys()).map((idx) => {
            const day = prevMonthLastDay - startMonthDay + idx + 1;
            const cellDate = new Date(
              prevMonthDate.getFullYear(),
              prevMonthDate.getMonth(),
              day
            );
            const isSelectedDate = dateService.isSameDate(date, cellDate);
            const isToday = dateService.isToday(cellDate);
            return (
              <button
                key={day}
                className={`prev-month-day ${
                  isSelectedDate ? "selected" : ""
                } ${isToday ? "today" : ""}`}
                onClick={() => {
                  onUnshowedMonthClicked(cellDate);
                }}
              >
                {day}
              </button>
            );
          })}
          {Array.from(Array(currMonthDays).keys()).map((idx) => {
            const day = idx + 1;
            const cellDate = new Date(
              showDate.getFullYear(),
              showDate.getMonth(),
              day
            );
            const isSelectedDate = dateService.isSameDate(cellDate, date);
            const isToday = dateService.isToday(cellDate);
            return (
              <button
                key={day}
                className={`this-month-day ${
                  isSelectedDate ? "selected" : ""
                } ${isToday ? "today" : ""}`}
                onClick={() => {
                  onShowedMonthDateClicked(cellDate);
                }}
              >
                {day}
              </button>
            );
          })}
          {Array.from(Array(6 - endMonthDay).keys()).map((idx) => {
            const day = idx + 1;
            const cellDate = new Date(
              nextMonthDate.getFullYear(),
              nextMonthDate.getMonth(),
              day
            );
            const isSelectedDate = dateService.isSameDate(cellDate, date);
            const isToday = dateService.isToday(cellDate);
            return (
              <button
                key={day}
                className={`next-month-day ${
                  isSelectedDate ? "selected" : ""
                } ${isToday ? "today" : ""}`}
                onClick={() => {
                  onUnshowedMonthClicked(cellDate);
                }}
              >
                {day}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
