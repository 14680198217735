import {
  Box,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { ArrowDownIcon } from '../assets/ArrowDownIcon';
import { ClockIcon } from '../assets/ClockIcon';
import { DateIcon } from '../assets/DateIcon';
import { LikeIcon } from '../assets/LikeIcon';
import { ReapetIcon } from '../assets/ReapetIcon';
import { Calander } from './Calander';
import { PrimaryButton } from './Footer/styleComponent/PrimaryButton';
import { useTranslation } from 'react-i18next';

export function IntervalPicker({ interval, changeInterval }: any) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isShowStartDate, setIsShowStartDate] = useState(true);
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const changeShowDateRef = useRef<any>(null);
  const changeShowDateRef2 = useRef<any>(null);
  const [selectedMonth, setSelectedMonth] = useState(
    new Date(interval.start).getMonth(),
  );
  const [selectedYear, setSelectedYear] = useState(
    new Date(interval.start).getFullYear(),
  );
  const units = {
    minute: t('intervalPicker.minute'),
    day: t('intervalPicker.day'),
    week: t('intervalPicker.week'),
    month: t('intervalPicker.month'),
    year: t('intervalPicker.year'),
  };
  const month = [
    t('month.january'),
    t('month.february'),
    t('month.march'),
    t('month.april'),
    t('month.may'),
    t('month.june'),
    t('month.july'),
    t('month.august'),
    t('month.september'),
    t('month.october'),
    t('month.november'),
    t('month.december'),
  ];
  const thisYear = new Date().getFullYear();
  const years = Array.from(Array(10).keys()).map((idx) => thisYear - 5 + idx);

  useEffect(() => {
    if (isShowStartDate && changeShowDateRef.current) {
      const start = new Date(interval.start);
      changeShowDateRef.current(start);
    } else if (!isShowStartDate && changeShowDateRef2.current) {
      const end = new Date(interval.end);
      changeShowDateRef2.current(end);
    }
  }, [isShowStartDate]);

  useEffect(() => {
    if (isShowStartDate && changeShowDateRef.current) {
      const start = new Date(interval.start);
      changeShowDateRef.current(
        (prev: Date) => new Date(prev.setFullYear(selectedYear, selectedMonth)),
      );
    } else if (!isShowStartDate && changeShowDateRef2.current) {
      const end = new Date(interval.end);
      changeShowDateRef2.current(
        (prev: Date) => new Date(prev.setFullYear(selectedYear, selectedMonth)),
      );
    }
  }, [selectedMonth, selectedYear]);

  function formatDate(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  function formatString(str: string) {
    const [year, month, day] = str.split('-');
    return [day, month, year.slice(-2)].join('/');
  }

  return (
    <div>
      <Button
        color="0E314E"
        padding={'12px 15px'}
        border="1.2521px solid #E2E2E2"
        onClick={onOpen}
        bg="#fff"
        fontSize={isPc ? 18 : 12}
        gap={isPc ? 'unset' : '5px'}
        height={isPc ? '54px' : 'max-content'}
        display={'flex'}
        flexDirection={isPc ? 'row' : 'column'}
        width={isPc ? '480px' : '100%'}
      >
        <Box
          display="flex"
          alignItems={'center'}
          gap="8px"
          color="0E314E"
          flexDirection={isPc ? 'row' : 'row-reverse'}
          justifyContent={isPc ? 'unset' : 'space-between'}
          paddingInlineEnd={isPc ? '14px' : 'unset'}
          borderInlineEnd={isPc ? '1px solid #E2E2E2' : ''}
          width={isPc ? 'unset' : '100%'}
        >
          <DateIcon />
          {formatString(interval.start)}-{formatString(interval.end)}
        </Box>
        <Box
          display="flex"
          alignItems={'center'}
          gap="8px"
          justifyContent={isPc ? 'unset' : 'space-between'}
          flexDirection={isPc ? 'row' : 'row-reverse'}
          paddingInline={isPc ? '14px' : 'unset'}
          width={isPc ? 'unset' : '100%'}
          borderInlineEnd={isPc ? '1px solid #E2E2E2' : ''}
        >
          <ClockIcon />
          {interval.time}
        </Box>
        <Box
          display="flex"
          alignItems={'center'}
          flexDirection={isPc ? 'row' : 'row-reverse'}
          justifyContent={isPc ? 'unset' : 'space-between'}
          width={isPc ? 'unset' : '100%'}
          gap="8px"
          paddingInlineStart={isPc ? '14px' : 'unset'}
        >
          <ReapetIcon />
          {interval.amount} {(units as any)[interval.unit]}
        </Box>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          maxW={'100%'}
          width={isPc ? '840px' : 'calc(100% - 20px)'}
        >
          <ModalHeader
            className="interval"
            display="flex"
            alignItems={'center'}
            justifyContent="center"
            textAlign={'center'}
            gap="30px"
            position={'relative'}
          >
            <Text color="#475154">{t('intervalPicker.taskDetails')}</Text>
          </ModalHeader>
          <ModalCloseButton
            position={'absolute'}
            top={isPc ? '-40px' : '5px'}
            color={isPc ? 'white' : 'unset'}
            border={isPc ? '1px solid white' : '1px solid rgb(26,32,44)'}
            rounded={'100px'}
            left={isPc ? '0' : '25px'}
            right="unset"
          />
          <ModalBody>
            <Box
              display={'grid'}
              gap="23px"
              gridTemplateColumns={isPc ? '2fr 1fr' : '1fr'}
            >
              <Box rounded={'10px'} padding={'20px'} bg={'#F5F8FA'}>
                <Text
                  fontSize={'18px'}
                  fontWeight="600"
                  display={'flex'}
                  alignItems="center"
                  mb={'10px'}
                >
                  <DateIcon />
                  {t('intervalPicker.startDate')}
                </Text>
                <Box
                  display={'flex'}
                  justifyContent="space-between"
                  alignItems={'center'}
                  mb={'30px'}
                  flexDirection={isPc ? 'row' : 'column'}
                >
                  <Box
                    display={'flex'}
                    gap="9px"
                    paddingInlineStart={isPc ? '20px' : '0px'}
                  >
                    <Menu>
                      <MenuButton>
                        <Box
                          display={'flex'}
                          flexDirection="row"
                          textAlign={'right'}
                          alignItems="center"
                          justifyContent="space-between"
                          width={'90px'}
                          color={'#3FB2D2'}
                        >
                          <Box>
                            <Text
                              color={'#3FB2D2'}
                              fontSize="18px"
                              fontWeight="700"
                            >
                              {month[selectedMonth]}
                            </Text>
                          </Box>
                          <Box>
                            <ArrowDownIcon color="#3FB2D2" />
                          </Box>
                        </Box>
                      </MenuButton>
                      <MenuList overflowY="auto" maxH={'300px'}>
                        {month.map((month: string, idx) => {
                          return (
                            <MenuItem
                              // color="red.600"
                              _hover={{
                                bg: '#F5F8FA',
                              }}
                              display="flex"
                              justifyContent={'space-between'}
                              onClick={() => {
                                setSelectedMonth(idx);
                              }}
                            >
                              <Box>{month}</Box>
                              {idx === selectedMonth ? (
                                <Box
                                  display={'flex'}
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Text fontSize="14px" fontWeight="700">
                                    ✓
                                  </Text>
                                </Box>
                              ) : null}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                    <Menu>
                      <MenuButton>
                        <Box
                          display={'flex'}
                          flexDirection="row"
                          textAlign={'right'}
                          alignItems="center"
                          justifyContent="space-between"
                          width={'60px'}
                        >
                          <Box>
                            <Text fontSize="18px" fontWeight="700">
                              {selectedYear}
                            </Text>
                          </Box>
                          <Box>
                            <ArrowDownIcon />
                          </Box>
                        </Box>
                      </MenuButton>
                      <MenuList overflowY="auto" maxH={'300px'}>
                        {years.map((year: number) => {
                          return (
                            <MenuItem
                              // color="red.600"
                              _hover={{
                                bg: '#F5F8FA',
                              }}
                              display="flex"
                              justifyContent={'space-between'}
                              onClick={() => {
                                setSelectedYear(year);
                              }}
                            >
                              <Box>{year}</Box>
                              {year === selectedYear ? (
                                <Box
                                  display={'flex'}
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Text fontSize="14px" fontWeight="700">
                                    ✓
                                  </Text>
                                </Box>
                              ) : null}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  </Box>

                  <Box display={'flex'} gap={'5px'} alignItems="center">
                    <Button
                      bg={'#fff'}
                      boxShadow={
                        isShowStartDate
                          ? '0px 4px 30px rgba(63, 178, 210, 0.34)'
                          : '0px 0px 25px rgba(62, 62, 62, 0.08)'
                      }
                      border={
                        isShowStartDate
                          ? '1.25px solid #3FB2D2'
                          : '1.25px solid #fff'
                      }
                      rounded={'10px'}
                      onClick={() => {
                        setIsShowStartDate(true);
                      }}
                    >
                      {formatString(interval.start)}
                    </Button>
                    <Text>-</Text>
                    <Button
                      bg={'#fff'}
                      boxShadow={
                        !isShowStartDate
                          ? '0px 4px 30px rgba(63, 178, 210, 0.34)'
                          : '0px 0px 25px rgba(62, 62, 62, 0.08)'
                      }
                      border={
                        !isShowStartDate
                          ? '1.25px solid #3FB2D2'
                          : '1.25px solid #fff'
                      }
                      rounded={'10px'}
                      onClick={() => {
                        setIsShowStartDate(false);
                      }}
                    >
                      {formatString(interval.end)}
                    </Button>
                  </Box>
                </Box>

                {isShowStartDate ? (
                  <Calander
                    setSelectedYear={setSelectedYear}
                    setSelectedMonth={setSelectedMonth}
                    date={
                      interval.start
                        ? new Date(interval.start as string)
                        : new Date()
                    }
                    changeShowDateRef={changeShowDateRef}
                    setDate={(date) => {
                      changeInterval({
                        ...interval,
                        start: formatDate(date),
                      });
                    }}
                  />
                ) : (
                  <Calander
                    setSelectedYear={setSelectedYear}
                    setSelectedMonth={setSelectedMonth}
                    changeShowDateRef={changeShowDateRef2}
                    date={
                      interval.end
                        ? new Date(interval.end as string)
                        : new Date()
                    }
                    setDate={(date) => {
                      changeInterval({
                        ...interval,
                        end: formatDate(date),
                      });
                    }}
                  />
                )}
              </Box>
              <Box
                display={'grid'}
                gridTemplateRows={'1fr 1fr'}
                gap="20px"
                flexShrink={0}
                flexGrow={1}
              >
                <Box rounded={'10px'} padding={'20px'} bg={'#F5F8FA'}>
                  <Text
                    fontSize={'18px'}
                    fontWeight="600"
                    display={'flex'}
                    alignItems="center"
                  >
                    <ClockIcon />
                    {t('intervalPicker.hour')}
                  </Text>

                  <Box
                    dir="ltr"
                    display={'flex'}
                    gap="4px"
                    alignItems={'center'}
                    width="100%"
                    height="100%"
                    justifyContent="center"
                  >
                    <Input
                      value={interval.time.split(':')[0]}
                      type="number"
                      fontSize="50px"
                      width={'93px'}
                      height="80px"
                      color="#0E314E"
                      bg="#fff"
                      border="1.2521px solid #E2E2E2"
                      fontWeight={'600'}
                      onChange={(ev) => {
                        changeInterval({
                          ...interval,
                          time:
                            ('0' + (+ev.target.value % 24)).slice(-2) +
                            ':' +
                            interval.time.split(':')[1],
                        });
                      }}
                    />
                    <Text fontWeight="600" fontSize="50px" color="#0E314E">
                      :
                    </Text>
                    <Input
                      value={interval.time.split(':')[1]}
                      type="number"
                      width={'93px'}
                      height="80px"
                      fontSize="50px"
                      color="#0E314E"
                      bg="#fff"
                      border="1.2521px solid #E2E2E2"
                      fontWeight={'600'}
                      onChange={(ev) => {
                        changeInterval({
                          ...interval,
                          time:
                            interval.time.split(':')[0] +
                            ':' +
                            ('0' + (+ev.target.value % 60)).slice(-2),
                        });
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  height={'100%'}
                  rounded={'10px'}
                  padding={'20px'}
                  bg={'#F5F8FA'}
                >
                  <Text
                    fontSize={'18px'}
                    fontWeight="600"
                    display={'flex'}
                    alignItems="center"
                  >
                    <ReapetIcon />
                    {t('intervalPicker.back')}
                  </Text>
                  <Box
                    display={'flex'}
                    flexDirection="column"
                    height="calc(100% - 32px) "
                    justifyContent={'space-around'}
                    gap="8px"
                  >
                    <Box>
                      <Text
                        fontSize={'14px'}
                        fontWeight="400"
                        color={'#aaa'}
                        lineHeight="19px"
                        mb={'5px'}
                      >
                        {t('intervalPicker.every')}
                      </Text>
                      <Input
                        bg={'#fff'}
                        border={'1.2521px solid #E2E2E2'}
                        rounded="10px"
                        height={'54px'}
                        fontSize="18px"
                        type="number"
                        value={interval.amount}
                        onChange={(ev) => {
                          changeInterval({
                            ...interval,
                            amount: +ev.target.value,
                          });
                        }}
                        maxWidth={'100%'}
                      />
                    </Box>
                    <Menu>
                      <MenuButton
                        bg={'#fff'}
                        border={'1.2521px solid #E2E2E2'}
                        rounded="10px"
                        height={'54px'}
                        fontSize="18px"
                        paddingInline={'13px'}
                      >
                        <Box
                          justifyContent="space-between"
                          alignItems={'center'}
                          display={'flex'}
                        >
                          <Text fontSize="18px" fontWeight="700">
                            {(units as any)[interval.unit]}
                          </Text>
                          <ArrowDownIcon />
                        </Box>
                      </MenuButton>

                      <MenuList overflowY="auto" maxH={'300px'}>
                        {Object.keys(units).map((key: string) => {
                          return (
                            <MenuItem
                              // color="red.600"
                              _hover={{
                                bg: '#F5F8FA',
                              }}
                              display="flex"
                              justifyContent={'space-between'}
                              onClick={() => {
                                changeInterval({
                                  ...interval,
                                  unit: key,
                                });
                              }}
                            >
                              <Box>{(units as any)[key]}</Box>
                              {key === interval.unit ? (
                                <Box
                                  display={'flex'}
                                  flexDirection="row"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Text fontSize="14px" fontWeight="700">
                                    ✓
                                  </Text>
                                </Box>
                              ) : null}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter width={'full'}>
            <PrimaryButton styles={{ minW: 'full' }} onClick={onClose}>
              {t('intervalPicker.save')}
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
