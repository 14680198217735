import {
  Button,
  Box,
  Checkbox,
  Container,
  Text,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  HStack,
  SlideFade,
  Alert,
  AlertIcon,
  Textarea,
  Spinner,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { appConfig } from '../../configs/app';
import TasklistService from '../../services/tasklist-service';
import UserService from '../../services/user-service';
import UtilSrvice from '../../services/util-service';
import style from '../../assets/style.json';
import { PrimaryButton } from '../../components/Footer/styleComponent/PrimaryButton';
import { EditIcon } from '../../assets/EditIcon';
import { CirclePlusIcon } from '../../assets/CirclePlusIcon';
import { DownloadIcon } from '../../assets/DownloadIcon';
import { FileInfoButton } from '../../assets/FileInfoButton';
import { LikeIcon } from '../../assets/LikeIcon';
import { InfoIcon } from '../../assets/InfoIcon';
import { TrashIcon } from '../../assets/TrashIcon';
import { useTranslation } from 'react-i18next';
import { getMimeType } from '../../services/mime';

const FileSaver = require('file-saver');
const mime = require('mime');

const getShortName = (name: string = ''): string => {
  const splitted = name.split('.');
  const withoutLast = splitted.slice(0, splitted.length - 1);
  return withoutLast.join('.');
};

const TasksForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [userId, setUserId] = useState('');
  const [user, setUser] = useState<any>(null);
  const [date, setDate] = useState(new Date());
  const [fileId, setFileId] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const [isLoad, setIsLoad] = useState(false);

  const [data, setData] = useState<any>({});
  const [checked, setChecked] = useState<
    { id: string; checked: boolean; notes?: string }[]
  >([]);
  const [files, setFiles] = useState<any[]>([]);
  const [downloadPath, setDownloadPath] = useState('');
  const [filesMap, setFilesMap] = useState<Record<string, any[]>>({});
  const [companyFilesMap, setCompanyFilesMap] = useState<Record<string, any[]>>(
    {},
  );

  useEffect(() => {
    if (!isOpen) setSelectedItem(null);
  }, [isOpen]);

  const load = async () => {
    setLoading(true);
    if (!!searchParams.get('user_id')) {
      setUserId(searchParams.get('user_id') || '');
      const res = await UserService.info(searchParams.get('user_id') || '');

      setUser(res);
    }
    const res = await TasklistService.read(
      id,
      searchParams.get('user_id') || '',
    );

    const companyTaskLists = await TasklistService.listCompany(res.companyId);
    const companyTaskList = companyTaskLists.find((c: any) => c.id === res.id);
    if (companyTaskList)
      setCompanyFilesMap(
        companyTaskList.list.reduce((acc: Record<string, any[]>, item: any) => {
          const files = (item.filePaths ?? []).map(
            (filePath: string, idx: number, arr: any[]) => {
              return {
                name: item.fileNames[idx],
                path: filePath,
                file: filePath,
              };
            },
          );
          acc[item.id] = files;
          return acc;
        }, {}),
      );

    setData(res);
    setDate(new Date());

    setFiles(
      res.list.map((item: any) => ({
        id: item.id,
        name: item.fileName,
        path: item.filePath,
        file: item.filePath,
        serverSide: true,
      })),
    );
    setFilesMap(
      res.list.reduce((acc: Record<string, any[]>, item: any) => {
        const resultBetweenNamesAndDates =
          (item.fileNames ?? []).length - (item.fileUploadDates ?? []).length;
        const files = (item.filePaths ?? []).map(
          (filePath: string, idx: number, arr: any[]) => {
            const dateTime =
              item.fileUploadDates?.[idx - resultBetweenNamesAndDates];

            return {
              id: UtilSrvice.genId(),
              name: item.fileNames[idx],
              path: filePath,
              date: dateTime ? new Date(dateTime) : null,
              file: filePath,
              serverSide: true,
            };
          },
        );
        acc[item.id] = files;
        return acc;
      }, {}),
    );
    setChecked(res.list);
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  const uploadFile = async (id: string) => {
    setFileId(id);
    const input = document.getElementById('fileInput');

    input?.click();
  };

  const deleteFile = async (id: string, taskId: string) => {
    const file = filesMap
      ? filesMap[taskId].find((file: any) => file.id === id)
      : null;
    if (file) {
      const result = await Swal.fire({
        title: t('tasksForm.deleteFile') + ' ' + file.name + '?',
        text: t('tasksForm.deleteWarning'),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: t('tasksForm.cancel'),
        confirmButtonText: t('tasksForm.delete'),
      });
      if (result.isConfirmed) {
        const newFilesMap = { ...filesMap };
        newFilesMap[taskId] = newFilesMap[taskId].filter(
          (file: any) => file.id !== id,
        );
        setFilesMap(newFilesMap);
      }
    }
  };

  const handleFileInput = (e: any) => {
    const id = fileId;
    const fileArr = Array.from(e.target!.files);
    let newFilesMap = { ...filesMap };
    fileArr.forEach((file: any) => {
      const itemFiles = newFilesMap[id] ? [...newFilesMap[id]] : [];
      const newFileId = UtilSrvice.genId();
      itemFiles.push({ id: newFileId, name: file.name, data: file });
      newFilesMap[id] = itemFiles;
    });
    setFilesMap(newFilesMap);
    e.target.value = '';
    e.target.files = null;
  };

  const downloadFile = async (fileName: string, path: string) => {
    /*
        DO
        NOT
        TOUCH
      */

    // now you can touch
    setDownloadPath(path);
    try {
      let response = await fetch(`${path}&fileName=${fileName}`);
      let data = await response.blob();

      let splittedFileName = fileName.split('.');
      let extension = splittedFileName.at(splittedFileName.length - 1);

      let fixedData = new Blob([data], { type: getMimeType(extension ?? '') });
      FileSaver.saveAs(fixedData, fileName);
    } finally {
      setDownloadPath('');
    }
  };

  const submit = async () => {
    setIsLoad(true);
    if (!id) {
      return;
    }
    setSubmitting(true);
    try {
      const result = await Swal.fire({
        title: t('tasksForm.tasksApproval'),
        text: t('tasksForm.appreciation'),
        input: 'checkbox',
        inputPlaceholder: t('tasksForm.comprehension'),
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: t('tasksForm.cancel'),
        confirmButtonText: t('tasksForm.approve'),
        inputValidator: (result: string): any => {
          return !result && t('tasksForm.readAndConfirm');
        },
      });
      if (result.isConfirmed && result.value) {
        const res = await TasklistService.submit(id, checked, filesMap, userId);
        navigate('/home');
        setSubmitting(false);
        setIsLoad(false);

        Swal.fire(t('tasksForm.success'), t('tasksForm.tasksSent'), 'success');
      } else {
        setIsLoad(false);
        setSubmitting(false);
      }
    } catch (e) {
      setSubmitting(false);
      Swal.fire(t('tasksForm.error'), t('tasksForm.tryAgainLater'), 'error');
    }
    setIsLoad(false);
  };

  return (
    //@ts-ignore
    <Container
      maxW="full"
      minH="100vh"
      bg={'transparent'}
      position="absolute"
      centerContent
      top={'140px'}
      padding={0}
    >
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            display="flex"
            alignItems={'center'}
            justifyContent="center"
            textAlign={'center'}
            color={style['primary-color']}
            gap="30px"
            position={'relative'}
          >
            <LikeIcon scale={0.7} />
            <Text color="#475154">{t('tasksForm.addComments')}</Text>
          </ModalHeader>
          <ModalCloseButton
            position={'absolute'}
            top={'-40px'}
            color={'white'}
            border={'1px solid white'}
            rounded={'100px'}
            left={0}
            right="unset"
          />
          <ModalBody>
            <Textarea
              placeholder={t('tasksForm.enterComment')}
              isDisabled={!data?.isOwner}
              defaultValue={
                checked.filter((i: any) => i.id == selectedItem)[0]?.notes
              }
              disabled={!!user || !data?.date}
              bg={'#ECEDF0'}
              rows={10}
              marginBottom={'30px'}
              resize="none"
              onChange={(e) => {
                let newChecked = [...checked];
                let item = newChecked.filter(
                  (x: any) => x.id == selectedItem,
                )[0];
                newChecked = newChecked.filter(
                  (x: any) => x.id !== selectedItem,
                );
                newChecked.push({
                  ...item,
                  notes: e.target.value,
                });
                setChecked(newChecked);
              }}
            />
          </ModalBody>
          <ModalFooter width={'full'}>
            <PrimaryButton styles={{ minW: 'full' }} onClick={onClose}>
              {t('tasksForm.save')}
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SlideFade in>
        <Text pb={date ? 0 : 7} fontWeight={500} color={'#fff'} fontSize={32}>
          {!!user ? t('tasksForm.planningExecution') : t('tasksForm.myTasks')}

          <iframe
            style={{ display: 'none' }}
            id="preview-iframe"
            width="100%"
            height="600px"
          />
        </Text>
      </SlideFade>
      {date && (
        <SlideFade in>
          <Text
            pb={3}
            color={'#fff'}
            fontWeight="400"
            fontSize={18}
            cursor="pointer"
            lineHeight={'60px'}
          >
            {UtilSrvice.timestampToDDMMYYYY(
              date?.getTime() ?? new Date().getTime(),
            )}
          </Text>
        </SlideFade>
      )}

      {!!loading && (
        <Box
          display="flex"
          minH={500}
          alignItems="center"
          justifyContent="center"
        >
          <div className="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Box>
      )}
      <Box
        display={'flex'}
        alignItems="center"
        justifyContent="center"
        bg={'#fafafa'}
        width="full"
      >
        {!loading && !!data?.list?.length ? (
          <Accordion w="100%" maxW={style['pc-view']} allowMultiple={true}>
            {!!user && (
              <Box
                bg="#FFFFFF"
                boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
                mt="-50px"
                width={isPc ? '444px' : '300px'}
                mb={8}
                rounded="10px"
                padding="18px"
                textAlign="center"
                marginInline="auto"
                position="relative"
              >
                <Box
                  right={'50%'}
                  top={'0'}
                  transform="translate(50%,-50%)"
                  bg={'white'}
                  position="absolute"
                  padding={'5px'}
                  rounded="50%"
                >
                  <InfoIcon />
                </Box>
                <Text fontSize={'18px'}>{t('tasksForm.formBelongs')}</Text>
                <Box
                  rounded={'8px'}
                  display={'flex'}
                  bg="#F5F8FA"
                  padding={'10px'}
                  justifyContent="space-between"
                >
                  <Text fontSize={'20px'} fontWeight="600">
                    {user.name}
                  </Text>
                  <Text fontSize={'20px'} fontWeight="600">
                    {user.phone}+
                  </Text>
                </Box>
              </Box>
            )}

            <input
              type="file"
              id="fileInput"
              style={{ visibility: 'hidden', width: 0, height: 0 }}
              onChange={(e) => handleFileInput(e)}
              multiple
            ></input>

            {(data.company.exposureCategories ?? []).map(
              (section: any, sectionIndex: number) => {
                const list = data?.list?.filter(
                  (c: any) => c.categoryId === section.id,
                );
                if (!list.length) {
                  return null;
                }
                return (
                  <AccordionItem
                    w={'full'}
                    margin={'0px'}
                    borderTop="0"
                    borderBottom={'1.5px solid #F0EEEE'}
                  >
                    <AccordionButton
                      display="flex"
                      justifyContent="space-between"
                      paddingBlock={'30px'}
                    >
                      <Text fontWeight={600} fontSize={22}>
                        {section.name}{' '}
                      </Text>
                      <Box display={'flex'} alignItems="center" gap={'20px'}>
                        <Text
                          fontSize={'18px'}
                          fontWeight={500}
                          color={
                            list.filter(
                              (item: any) =>
                                item.checked ||
                                checked.find(
                                  (checked) => checked.id === item.id,
                                )?.checked,
                            ).length === list?.length
                              ? '#3FB2D2'
                              : '#aaa'
                          }
                        >
                          {
                            list.filter(
                              (item: any) =>
                                item.checked ||
                                checked.find(
                                  (checked) => checked.id === item.id,
                                )?.checked,
                            ).length
                          }
                          /{list?.length}
                        </Text>
                        <AccordionIcon />
                      </Box>
                    </AccordionButton>
                    <AccordionPanel>
                      <VStack
                        key={'section-' + sectionIndex}
                        w="100%"
                        alignItems="flex-start"
                      >
                        <VStack w="100%" spacing={1}>
                          {(list ?? []).map((item: any, i: number) => {
                            const nextReset = (function (): Date {
                              const start = new Date(item.interval.start);
                              const end = new Date(item.interval.end);
                              const now = new Date();
                              if (now > end) {
                                return end;
                              }
                              if (now < start) {
                                return start;
                              }

                              const resetDate = new Date(start);
                              let jump = item.interval.amount ?? 1;
                              while (resetDate < now) {
                                switch (item.interval.unit) {
                                  case 'minute':
                                    resetDate.setMinutes(
                                      resetDate.getMinutes() + jump,
                                    );
                                    break;
                                  case 'day':
                                    resetDate.setDate(
                                      resetDate.getDate() + jump,
                                    );
                                    break;
                                  case 'week':
                                    resetDate.setDate(
                                      resetDate.getDate() + jump * 7,
                                    );
                                    break;
                                  case 'month':
                                    resetDate.setMonth(
                                      resetDate.getMonth() + jump,
                                    );
                                    break;
                                  case 'year':
                                    resetDate.setFullYear(
                                      resetDate.getFullYear() + jump,
                                    );
                                    break;
                                }
                              }
                              return resetDate;
                            })();

                            return (
                              <Box
                                key={'task-' + i}
                                bg={
                                  item.checked ||
                                  checked.find(
                                    (checked) => checked.id === item.id,
                                  )?.checked
                                    ? '#F7FCFE'
                                    : 'white'
                                }
                                w="100%"
                                boxShadow={
                                  '0px 0px 25px rgba(62, 62, 62, 0.08)'
                                }
                                rounded="10px"
                              >
                                <Box
                                  padding={'25px 20px'}
                                  display="flex"
                                  alignItems={'center'}
                                  gap={'33px'}
                                >
                                  <Box
                                    display="flex"
                                    alignItems={'center'}
                                    flex={1}
                                    gap={'33px'}
                                  >
                                    <Checkbox
                                      disabled={!!user || !data?.date}
                                      size="lg"
                                      colorScheme="white"
                                      borderColor="#3FB2D2"
                                      iconColor="#3FB2D2"
                                      iconSize={'17px'}
                                      spacing="1rem"
                                      p={2}
                                      rounded="xl"
                                      wordBreak="break-word"
                                      isDisabled={data?.locked || !data?.date}
                                      defaultChecked={item.checked}
                                      onChange={(e) => {
                                        let newChecked = [...checked];
                                        newChecked = newChecked.filter(
                                          (x: any) => x.id !== item.id,
                                        );
                                        const it = checked.find(
                                          (x: any) => x.id === item.id,
                                        );
                                        if (e.target.checked) {
                                          newChecked.push({
                                            ...it!,
                                            checked: true,
                                          });
                                        } else {
                                          newChecked.push({
                                            ...it!,
                                            checked: false,
                                          });
                                        }
                                        setChecked(newChecked);
                                      }}
                                    >
                                      {item.text}
                                    </Checkbox>
                                    <Box
                                      display="flex"
                                      alignItems={'center'}
                                      flexWrap="wrap"
                                      gap={'20px'}
                                    >
                                      {companyFilesMap[item.id] &&
                                        (companyFilesMap[item.id] ?? []).map(
                                          (file) => {
                                            console.log({ file });
                                            const { name, id, path } = file;
                                            const shortName = getShortName(
                                              name ?? '',
                                            );

                                            return (
                                              <Button
                                                size="sm"
                                                fontSize={21}
                                                bg={style['primary-color']}
                                                width="34px"
                                                isLoading={
                                                  downloadPath === path
                                                }
                                                isDisabled={
                                                  downloadPath === path
                                                }
                                                height="34px"
                                                color={'white'}
                                                onClick={() =>
                                                  downloadFile(
                                                    name,
                                                    `${
                                                      appConfig.apiUrl
                                                    }/file?path=${path}&token=${localStorage.getItem(
                                                      'token',
                                                    )}`,
                                                  )
                                                }
                                              >
                                                <FileInfoButton />
                                              </Button>
                                            );
                                          },
                                        )}
                                    </Box>
                                  </Box>

                                  {isPc && (
                                    <>
                                      {data?.isOwner && (
                                        <>
                                          <Button
                                            disabled={!!user || !data?.date}
                                            onClick={() => uploadFile(item.id)}
                                            minW={'188px'}
                                            textAlign="center"
                                            fontSize="18px"
                                            fontWeight={700}
                                            rounded={'100px'}
                                            borderWidth={'1.5px'}
                                            borderColor={
                                              !!files.find(
                                                (f) => f.id === item.id,
                                              )?.name
                                                ? '#E55353'
                                                : style['primary-color']
                                            }
                                            bg={'#fff'}
                                            color={
                                              !!files.find(
                                                (f) => f.id === item.id,
                                              )?.name
                                                ? '#E55353'
                                                : style['primary-color']
                                            }
                                          >
                                            {!!files.find(
                                              (f) => f.id === item.id,
                                            )?.name ? (
                                              <Box>{t('tasksForm.delete')}</Box>
                                            ) : (
                                              <Box
                                                gap={'17px'}
                                                display={'flex'}
                                                alignItems={'center'}
                                              >
                                                <Text>
                                                  {t('tasksForm.addFile')}
                                                </Text>
                                                <CirclePlusIcon />
                                              </Box>
                                            )}
                                          </Button>
                                          {filesMap[item.id] && (
                                            <span>
                                              {filesMap[item.id].length}
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}

                                  <Button
                                    size="sm"
                                    fontSize={21}
                                    bg={style['primary-color']}
                                    width="34px"
                                    height="34px"
                                    color={'white'}
                                    onClick={() => {
                                      setSelectedItem(item.id);
                                      onOpen();
                                    }}
                                  >
                                    <EditIcon scale={1.5} />
                                  </Button>
                                </Box>

                                <Box
                                  boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
                                  roundedBottom={'10px'}
                                >
                                  <Accordion allowMultiple={true}>
                                    <AccordionItem>
                                      <AccordionButton
                                        display={'flex'}
                                        justifyContent={
                                          isPc ? 'center' : 'space-between'
                                        }
                                        alignItems={'center'}
                                        fontSize="16px"
                                        fontWeight={400}
                                        color="#3FB2D2"
                                        gap={'9px'}
                                      >
                                        {!isPc && (
                                          <>
                                            {data?.isOwner && (
                                              <Box
                                                display={'flex'}
                                                gap={'9px'}
                                                alignItems={'center'}
                                              >
                                                <Button
                                                  disabled={
                                                    !!user || !data?.date
                                                  }
                                                  onClick={(ev) => {
                                                    ev.stopPropagation();
                                                    uploadFile(item.id);
                                                  }}
                                                  textAlign="center"
                                                  fontSize="18px"
                                                  fontWeight={700}
                                                  rounded={'100px'}
                                                  bg={'#fff'}
                                                  color={style['primary-color']}
                                                >
                                                  <Box
                                                    gap={'17px'}
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                  >
                                                    <Text>
                                                      {t('tasksForm.addFile')}
                                                    </Text>
                                                    <CirclePlusIcon />
                                                  </Box>
                                                </Button>
                                                {filesMap[item.id] && (
                                                  <span>
                                                    {filesMap[item.id].length}
                                                  </span>
                                                )}
                                              </Box>
                                            )}
                                          </>
                                        )}
                                        <Box
                                          display={'flex'}
                                          justifyContent="center"
                                          alignItems={'center'}
                                          fontSize="16px"
                                          fontWeight={400}
                                          color={
                                            !!files.find(
                                              (f) => f.id === item.id,
                                            )?.name || item.notes
                                              ? 'red'
                                              : '#3FB2D2'
                                          }
                                          gap={'9px'}
                                        >
                                          {t('tasksForm.additionalDetails')}
                                          <AccordionIcon />
                                        </Box>
                                      </AccordionButton>
                                      <AccordionPanel>
                                        {nextReset && (
                                          <Box
                                            display="flex"
                                            alignItems={'center'}
                                            gap={'20px'}
                                          >
                                            <Text
                                              fontSize={'18px'}
                                              lineHeight="60px"
                                              color={'#A9A9A9'}
                                              fontWeight={400}
                                            >
                                              {t('tasksForm.nextReset') +
                                                ': ' +
                                                UtilSrvice.timestampToDDMMYYYY(
                                                  nextReset.getTime(),
                                                )}
                                            </Text>
                                          </Box>
                                        )}
                                        {filesMap[item.id] &&
                                          (filesMap[item.id] ?? []).map(
                                            (file) => {
                                              const { name, id, path } = file;
                                              return (
                                                <Box
                                                  display={'flex'}
                                                  gap={'20px'}
                                                  alignItems={'center'}
                                                >
                                                  <Text
                                                    fontSize={'18px'}
                                                    lineHeight="60px"
                                                    color={'#A9A9A9'}
                                                    fontWeight={400}
                                                  >
                                                    {name ??
                                                      t('tasksForm.file') +
                                                        ' ' +
                                                        id}
                                                    {file.date
                                                      ? ` - ${UtilSrvice.timestampToDDMMYYYY(
                                                          file.date.getTime(),
                                                        )}`
                                                      : ''}
                                                  </Text>
                                                  <Button
                                                    display="flex"
                                                    padding={'15px'}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    gap={'20px'}
                                                    color={
                                                      style['primary-color']
                                                    }
                                                    width="max-content"
                                                    height="max-content"
                                                    bg={'#fff'}
                                                    isDisabled={
                                                      downloadPath === path
                                                    }
                                                    boxShadow="0px 0px 20px rgba(62, 62, 62, 0.11)"
                                                    onClick={() =>
                                                      downloadFile(
                                                        name,
                                                        `${
                                                          appConfig.apiUrl
                                                        }/file?path=${path}&token=${localStorage.getItem(
                                                          'token',
                                                        )}`,
                                                      )
                                                    }
                                                  >
                                                    <DownloadIcon />
                                                    <Text color="#2D2F32">
                                                      {t('tasksForm.download')}
                                                    </Text>
                                                  </Button>

                                                  {/* @ts-ignore */}
                                                  <Button
                                                    display="flex"
                                                    padding={'15px'}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    gap={'20px'}
                                                    color={
                                                      style['primary-color']
                                                    }
                                                    width="max-content"
                                                    height="max-content"
                                                    bg={'#fff'}
                                                    boxShadow="0px 0px 20px rgba(62, 62, 62, 0.11)"
                                                    disabled={
                                                      !files.find(
                                                        (f) =>
                                                          f.id === item.id &&
                                                          f.serverSide,
                                                      )
                                                    }
                                                    onClick={() =>
                                                      deleteFile(
                                                        file.id,
                                                        item.id,
                                                      )
                                                    }
                                                  >
                                                    <TrashIcon />
                                                    <Text color="#2D2F32">
                                                      {t('tasksForm.delete')}
                                                    </Text>
                                                  </Button>
                                                </Box>
                                              );
                                            },
                                          )}

                                        {item.notes && (
                                          <Box>
                                            <Text
                                              fontSize={'18px'}
                                              lineHeight="60px"
                                              fontWeight={400}
                                              color={'#A9A9A9'}
                                            >
                                              {t('tasksForm.comments')}
                                            </Text>

                                            <Text
                                              fontSize={'16px'}
                                              fontWeight={400}
                                              bg={'#F8F9FA'}
                                              padding="15px"
                                              rounded={'3px'}
                                            >
                                              {item.notes}
                                            </Text>
                                          </Box>
                                        )}
                                      </AccordionPanel>
                                    </AccordionItem>
                                  </Accordion>
                                </Box>
                              </Box>
                            );
                          })}
                        </VStack>
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                );
              },
            )}
            {data?.isOwner && (
              <Box display={'flex'} flexDirection="row-reverse">
                <Button
                  color={'white'}
                  minW={isPc ? '200px' : 'full'}
                  height="60px"
                  textAlign="center"
                  border="3px solid #FFFFFF"
                  background={'#3FB2D2'}
                  fontSize="18px"
                  fontWeight={700}
                  rounded={'100px'}
                  filter="drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))"
                  disabled={user || !data?.date || isLoad}
                  isLoading={submitting}
                  // isDisabled={data?.locked}
                  marginTop="44px"
                  onClick={submit}
                >
                  {t('tasksForm.approve')}
                </Button>
              </Box>
            )}
          </Accordion>
        ) : (
          !loading && (
            <Text mt={40} fontSize={23} opacity={0.4}>
              {t('tasksForm.noTasks')}
            </Text>
          )
        )}
      </Box>
    </Container>
  );
};

export default TasksForm;
