export function ClockIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 5C9.9345 5 5 9.9345 5 16C5 22.0655 9.9345 27 16 27C22.0655 27 27 22.0655 27 16C27 9.9345 22.0655 5 16 5ZM16 7C20.6242 7 24.4409 10.5068 24.9404 15H24.4912C23.9392 15 23.4912 15.448 23.4912 16C23.4912 16.552 23.9392 17 24.4912 17H24.9404C24.4775 21.1644 21.1644 24.4775 17 24.9404V24.4912C17 23.9392 16.552 23.4912 16 23.4912C15.448 23.4912 15 23.9392 15 24.4912V24.9404C10.8356 24.4775 7.52255 21.1644 7.05957 17H7.50879C8.06079 17 8.50879 16.552 8.50879 16C8.50879 15.448 8.06079 15 7.50879 15H7.05957C7.37232 12.1869 8.98842 9.76763 11.2871 8.34668L16 13.0596V7ZM11.6963 10.6963C11.4404 10.6963 11.1845 10.794 10.9893 10.9893C10.5988 11.3798 10.5988 12.0128 10.9893 12.4033L15.293 16.707C15.488 16.902 15.7435 17 16 17C16.239 17 16.4789 16.9151 16.6699 16.7441L19.3721 14.3115C19.7826 13.942 19.8158 13.3099 19.4463 12.8994C19.0778 12.4889 18.4447 12.4547 18.0342 12.8242L16.0361 14.6221L12.4033 10.9893C12.2081 10.794 11.9522 10.6963 11.6963 10.6963Z"
        fill="#20273D"
      />
    </svg>
  );
}
