export function Hamburger({ color }: any) {
  return (
    <svg
      width="26"
      height="21"
      viewBox="0 0 26 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0V1.90909H26V0H0ZM0 9.54545V11.4545H26V9.54545H0ZM0 19.0909V21H26V19.0909H0Z"
        fill={color}
      />
    </svg>
  );
}
