import { useEffect, useRef, useState } from 'react';
import { useToggle } from '../hooks/useToggle';

type PrivateProps = {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  length?: number;
};

export function VerifyCode({ code, setCode, length = 5 }: PrivateProps) {
  const [isFocus, toggleIsFocus] = useToggle();
  const codeChars = code.split('');
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current === null) return;
    const selectedIndex =
      codeChars.length < length ? codeChars.length : length - 1;
    containerRef.current.style.setProperty(
      '--input-position',
      selectedIndex.toString(),
    );
  }, [codeChars]);

  const handleClick = () => {
    if (inputRef.current === null) return;
    inputRef.current.focus();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCode((prev) => {
      if (prev.length >= length) return prev;
      return prev + value.slice(0, length);
    });
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace')
      setCode((prev) => prev.slice(0, prev.length - 1));
  };

  return (
    <div
      dir="ltr"
      ref={containerRef}
      onClick={handleClick}
      className="verify-code"
    >
      {Array.from(Array(length).keys()).map((key, idx) => {
        const selected = codeChars.length === idx;
        const filled = codeChars.length === length && idx === length;
        return (
          <div
            key={idx}
            className={`display ${
              (selected || filled) && isFocus ? 'shadows' : ''
            }`}
          >
            {codeChars[idx] ? '●' : ''}
          </div>
        );
      })}
      <input
        value=""
        type="password"
        ref={inputRef}
        className={`input ${codeChars.length >= length ? 'hide' : ''}`}
        onFocus={toggleIsFocus}
        onBlur={toggleIsFocus}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />
    </div>
  );
}
