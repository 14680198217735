import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtl from "stylis-plugin-rtl";
import { useContext,useEffect } from "react";
import { LanguageContext } from "./locales/LocaleContext";
import { getDirection } from "./locales/locale";

// NB: A unique `key` is important for it to work!
const options = {
  rtl: { key: "css-he", stylisPlugins: [rtl] },
  ltr: { key: "css-en" },
};

export function RtlProvider({ children }: any) {
  
  const { locale } = useContext(LanguageContext)
  const dir = getDirection(locale);
  const cache = createCache(options[dir]);

  useEffect(()=>{
    document.documentElement.setAttribute("dir",dir)
    document.body.setAttribute("dir",dir)
  },[dir])
 
  // const dir = "ltr";
  // const cache = createCache(options[dir]);
  return <CacheProvider value={cache}>{children}</CacheProvider>;
}
