import { Box, Button } from '@chakra-ui/react';
import { Dispatch } from 'react';

type QuestionListProps = {
  questions: any[];
  onboardingAnswers: Record<string, string | string[]>;
  setOnboardingAnswers: Dispatch<
    React.SetStateAction<Record<string, string | string[]>>
  >;
  isMultipleChoice?: boolean;
  questionIndex: number;
  setQuestionIndex: Dispatch<React.SetStateAction<number>>;
};

function QuestionList({
  questions,
  onboardingAnswers,
  setOnboardingAnswers,
  isMultipleChoice = false,
  questionIndex,
  setQuestionIndex,
}: QuestionListProps) {
  const toggleOption = (questionId: string, option: string) => {
    setOnboardingAnswers((prevAnswers) => {
      const selectedOptions = prevAnswers[questionId] || [];

      if (isMultipleChoice) {
        const newSelectedOptions = selectedOptions.includes(option)
          ? typeof selectedOptions === 'string'
            ? selectedOptions === option
              ? []
              : [option]
            : selectedOptions.filter(
                (selectedOption: string) => selectedOption !== option,
              )
          : [
              ...(Array.isArray(selectedOptions) ? selectedOptions : []),
              option,
            ];
        return {
          ...prevAnswers,
          [questionId]: newSelectedOptions,
        };
      }
      return {
        ...prevAnswers,
        [questionId]: prevAnswers[questionId] === option ? '' : option,
      };
    });
  };

  const question = questions[questionIndex];
  const selectedOptions = onboardingAnswers[question.id] || [];

  const toggleQuestionOption = (option: string) => {
    toggleOption(question.id, option);
  };

  return (
    <Box key={question.id} className="pt-4 pb-2">
      <Box fontWeight={600} mb={2}>
        {question.content}{' '}
        {question.isRequired && <span style={{ color: 'red' }}>*</span>}
      </Box>
      <Box display="flex" flexDir="column" gap={2} flexWrap="wrap">
        {question.options.map((option: string, index: number) => {
          const isSelected =
            typeof selectedOptions === 'string'
              ? selectedOptions === option
              : selectedOptions.includes(option);
          const onClick = () => toggleQuestionOption(option);

          return (
            <Button
              variant={isSelected ? 'solid' : 'outline'}
              colorScheme={isSelected ? 'blue' : 'gray'}
              flexGrow={1}
              onClick={onClick}
              key={index}
            >
              {option}
            </Button>
          );
        })}
      </Box>
    </Box>
  );

  return (
    <>
      {questions.map((question) => {
        const selectedOptions = onboardingAnswers[question.id] || [];
        console.log({ selectedOptions });

        const toggleQuestionOption = (option: string) => {
          toggleOption(question.id, option);
        };

        return (
          <Box key={question.id} className="pt-4 pb-2">
            <Box fontWeight={600} mb={2}>
              {question.content}{' '}
              {question.isRequired && <span style={{ color: 'red' }}>*</span>}
            </Box>
            <Box display="flex" flexDir="column" gap={2} flexWrap="wrap">
              {question.options.map((option: string, index: number) => {
                const isSelected =
                  typeof selectedOptions === 'string'
                    ? selectedOptions === option
                    : selectedOptions.includes(option);
                const onClick = () => toggleQuestionOption(option);

                return (
                  <Button
                    variant={isSelected ? 'solid' : 'outline'}
                    colorScheme={isSelected ? 'blue' : 'gray'}
                    flexGrow={1}
                    onClick={onClick}
                    key={index}
                  >
                    {option}
                  </Button>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </>
  );
}

export default QuestionList;
