import { useState } from "react";

export function useToggle(
  startedValue: boolean = false
): [boolean, (value: any) => void] {
  const [bool, setBool] = useState<boolean>(startedValue);

  const toggleBool = (value: any = null): void => {
    if (typeof value === "boolean") setBool(value);
    else setBool((prev) => !prev);
  };

  return [bool, toggleBool];
}
