import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermOfUse from '../pages/TermOfUse';
import About from '../pages/About';
import DowlandFile from '../pages/DowloandFile';
import Contact from '../pages/Contact';
import TasksForm from '../pages/TasksForm';
import Tasklists from '../pages/Tasklists';
import Exposure from '../pages/Exposure';
import NewslattterView from '../pages/NewsletterView';
import Newslattter from '../pages/Newsletter';
import ExposureDetailed from '../pages/ExposureDetailed';
import ModifyNewslattter from '../pages/ModifyNewslatter';
import AddTasksForm from '../pages/AddTasksForm';
import ModifyCompany from '../pages/ModifyCompany';
import Companies from '../pages/Companies';
import Reports from '../pages/Reports';
import Logs from '../pages/Logs';
import Logger from '../pages/Logger';
import RisksFiles from '../pages/RisksFiles';
import Onboarding from '../pages/Onboarding';
import ModifyQuestionnaire from '../pages/ModifyQuestionnaire';
import Sanctions from '../pages/Sanctions';
import CompanyOnboarding from '../pages/CompanyOnboarding';
import OnboardingManagement from '../pages/OnboardingManagement';
import OnboardingResults from '../pages/OnboardingResults';
import GuidingQuestionnaire from '../pages/GuidingQuestionnaire';
import ModifyGuidingQuestionnaire from '../pages/ModifyGuidingQuestionnaire';
import RefreshUser from '../pages/RefreshUser';
import ModifyGuidingQuestionnaireUsers from '../pages/ModifyGuidingQuestionnaire/users';
import GuidingQuestionnaireTest from '../pages/GuidingQuestionnaire/test';

interface AppRoutesProps {
  isAuthenticated: boolean;
  currRole: string | null;
  isNewsletterOnly: boolean;
  userInfo: any;
}

const AppRoutes: React.FC<AppRoutesProps> = ({
  isAuthenticated,
  currRole,
  isNewsletterOnly,
  userInfo,
}) => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/home"
        element={
          isAuthenticated ? (
            <Dashboard isNewsletterOnly={isNewsletterOnly} role={currRole} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route path="/p-privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/onboarding" element={<Onboarding userInfo={userInfo} />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/p-term-of-use" element={<TermOfUse />} />
      <Route path="/term-of-use" element={<TermOfUse />} />
      <Route path="/about" element={<About />} />
      <Route path="/refresh-user" element={<RefreshUser />} />
      <Route path="/download-file" element={<DowlandFile />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/sanctions" element={<Sanctions userInfo={userInfo} />} />
      <Route path="/tasks" element={<TasksForm />} />
      <Route path="/tasklists/:id" element={<Tasklists />} />
      <Route path="/tasklists" element={<Tasklists />} />
      <Route path="/tasks/:id" element={<TasksForm />} />
      <Route path="/exposure" element={<Exposure />} />
      <Route path="/view-newslatter/:id" element={<NewslattterView />} />
      <Route path="/newslatter" element={<Newslattter />} />
      {/* todo guiding-questionnaire */}
	  {/* <Route path="/guiding-questionnaire" element={<GuidingQuestionnaire />} />
      <Route path="/guiding-questionnaire/:id" element={<GuidingQuestionnaireTest />} /> */}
      <Route
        path="/exposure/:id"
        element={<ExposureDetailed role={currRole} />}
      />
      <Route
        path="/admin/modify-questionnaire"
        element={<ModifyQuestionnaire />}
      />
      <Route
        path="/admin/modify-questionnaire/:id"
        element={<ModifyQuestionnaire />}
      />
      <Route path="/admin/modify-newslatter" element={<ModifyNewslattter />} />
      {/* todo guiding-questionnaire */}
	  {/* <Route
        path="/admin/modify-guiding-questionnaire"
        element={<ModifyGuidingQuestionnaire />}
      />
      <Route
        path="/admin/modify-guiding-questionnaire/:id"
        element={<ModifyGuidingQuestionnaire />}
      />
      <Route
        path="/admin/modify-guiding-questionnaire/:id/users"
        element={<ModifyGuidingQuestionnaireUsers />}
      /> */}
      <Route
        path="/admin/modify-newslatter/:id"
        element={<ModifyNewslattter />}
      />
      <Route path="/admin/tasklists" element={<Tasklists />} />
      <Route
        path="/admin/exposure/:id/:companyId"
        element={<ExposureDetailed role={currRole} />}
      />
      <Route path="/admin/exposure/:companyId" element={<Exposure />} />
      <Route path="/admin/tasks/:id" element={<AddTasksForm />} />
      <Route path="/admin/tasks" element={<AddTasksForm />} />
      <Route path="/admin/company/:id" element={<ModifyCompany />} />
      <Route path="/admin/company" element={<ModifyCompany />} />
      <Route path="/admin/companies" element={<Companies />} />
      <Route path="/admin/reports" element={<Reports />} />
      <Route path="/admin/reports/:companyId" element={<Reports />} />
      <Route
        path="/admin/onboarding/:companyId"
        element={<CompanyOnboarding />}
      />
      <Route path="/admin/onboarding-results" element={<OnboardingResults />} />
      <Route path="/admin/onboarding" element={<OnboardingManagement />} />
      <Route path="/admin/risks-files/:companyId" element={<RisksFiles />} />
      <Route path="/admin/logs/:number" element={<Logs />} />
      <Route path="/admin/logger/:daysAgo" element={<Logger />} />
      <Route
        path="*"
        element={<Navigate to={isAuthenticated ? '/home' : '/'} />}
      />
    </Routes>
  );
};

export default AppRoutes;
