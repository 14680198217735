export function CompanyIcon() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46 9.34375C46 8.15494 45.0326 7.1875 43.8438 7.1875H2.15625C0.967437 7.1875 0 8.15494 0 9.34375V20.3622C0 21.9485 0.539781 23.4068 1.4375 24.5784V37.375C1.4375 38.9606 2.72694 40.25 4.3125 40.25H41.6875C43.2731 40.25 44.5625 38.9606 44.5625 37.375V24.5784C45.4602 23.4075 46 21.9485 46 20.3622V9.34375ZM41.6875 38.8125H4.3125C3.51972 38.8125 2.875 38.1678 2.875 37.375V25.9799C4.02141 26.8144 5.42728 27.3125 6.95031 27.3125H39.0497C40.5727 27.3125 41.9786 26.8144 43.125 25.9799V37.375C43.125 38.1678 42.4803 38.8125 41.6875 38.8125ZM44.5625 20.3622C44.5625 23.4025 42.09 25.875 39.0497 25.875H6.95031C5.33456 25.875 3.88412 25.1728 2.875 24.0616V24.0544H2.86853C1.98231 23.0762 1.4375 21.7832 1.4375 20.3622V9.34375C1.4375 8.947 1.7595 8.625 2.15625 8.625H43.8438C44.2405 8.625 44.5625 8.947 44.5625 9.34375V20.3622Z"
        fill="#20273D"
      />
      <path
        d="M5.03125 10.0625C4.6345 10.0625 4.3125 10.3838 4.3125 10.7812V12.2188C4.3125 12.6162 4.6345 12.9375 5.03125 12.9375C5.428 12.9375 5.75 12.6162 5.75 12.2188V10.7812C5.75 10.3838 5.428 10.0625 5.03125 10.0625Z"
        fill="#20273D"
      />
      <path
        d="M1.4375 10.0625C1.04075 10.0625 0.71875 10.3838 0.71875 10.7812V12.2188C0.71875 12.6162 1.04075 12.9375 1.4375 12.9375C1.83425 12.9375 2.15625 12.6162 2.15625 12.2188V10.7812C2.15625 10.3838 1.83425 10.0625 1.4375 10.0625Z"
        fill="#20273D"
      />
      <path
        d="M8.625 10.0625C8.22825 10.0625 7.90625 10.3838 7.90625 10.7812V12.2188C7.90625 12.6162 8.22825 12.9375 8.625 12.9375C9.02175 12.9375 9.34375 12.6162 9.34375 12.2188V10.7812C9.34375 10.3838 9.02175 10.0625 8.625 10.0625Z"
        fill="#20273D"
      />
      <path
        d="M12.2188 10.0625C11.822 10.0625 11.5 10.3838 11.5 10.7812V12.2188C11.5 12.6162 11.822 12.9375 12.2188 12.9375C12.6155 12.9375 12.9375 12.6162 12.9375 12.2188V10.7812C12.9375 10.3838 12.6155 10.0625 12.2188 10.0625Z"
        fill="#20273D"
      />
      <path
        d="M15.8125 10.0625C15.4157 10.0625 15.0938 10.3838 15.0938 10.7812V12.2188C15.0938 12.6162 15.4157 12.9375 15.8125 12.9375C16.2092 12.9375 16.5312 12.6162 16.5312 12.2188V10.7812C16.5312 10.3838 16.2092 10.0625 15.8125 10.0625Z"
        fill="#20273D"
      />
      <path
        d="M19.4062 10.0625C19.0095 10.0625 18.6875 10.3838 18.6875 10.7812V12.2188C18.6875 12.6162 19.0095 12.9375 19.4062 12.9375C19.803 12.9375 20.125 12.6162 20.125 12.2188V10.7812C20.125 10.3838 19.803 10.0625 19.4062 10.0625Z"
        fill="#20273D"
      />
      <path
        d="M23 10.0625C22.6032 10.0625 22.2812 10.3838 22.2812 10.7812V12.2188C22.2812 12.6162 22.6032 12.9375 23 12.9375C23.3967 12.9375 23.7188 12.6162 23.7188 12.2188V10.7812C23.7188 10.3838 23.3967 10.0625 23 10.0625Z"
        fill="#20273D"
      />
      <path
        d="M26.5938 10.0625C26.197 10.0625 25.875 10.3838 25.875 10.7812V12.2188C25.875 12.6162 26.197 12.9375 26.5938 12.9375C26.9905 12.9375 27.3125 12.6162 27.3125 12.2188V10.7812C27.3125 10.3838 26.9905 10.0625 26.5938 10.0625Z"
        fill="#20273D"
      />
      <path
        d="M30.1875 10.0625C29.7907 10.0625 29.4688 10.3838 29.4688 10.7812V12.2188C29.4688 12.6162 29.7907 12.9375 30.1875 12.9375C30.5842 12.9375 30.9062 12.6162 30.9062 12.2188V10.7812C30.9062 10.3838 30.5842 10.0625 30.1875 10.0625Z"
        fill="#20273D"
      />
      <path
        d="M33.7812 10.0625C33.3845 10.0625 33.0625 10.3838 33.0625 10.7812V12.2188C33.0625 12.6162 33.3845 12.9375 33.7812 12.9375C34.178 12.9375 34.5 12.6162 34.5 12.2188V10.7812C34.5 10.3838 34.178 10.0625 33.7812 10.0625Z"
        fill="#20273D"
      />
      <path
        d="M37.375 10.0625C36.9782 10.0625 36.6562 10.3838 36.6562 10.7812V12.2188C36.6562 12.6162 36.9782 12.9375 37.375 12.9375C37.7717 12.9375 38.0938 12.6162 38.0938 12.2188V10.7812C38.0938 10.3838 37.7717 10.0625 37.375 10.0625Z"
        fill="#20273D"
      />
      <path
        d="M40.9688 10.0625C40.572 10.0625 40.25 10.3838 40.25 10.7812V12.2188C40.25 12.6162 40.572 12.9375 40.9688 12.9375C41.3655 12.9375 41.6875 12.6162 41.6875 12.2188V10.7812C41.6875 10.3838 41.3655 10.0625 40.9688 10.0625Z"
        fill="#20273D"
      />
      <path
        d="M44.5625 10.0625C44.1657 10.0625 43.8438 10.3838 43.8438 10.7812V12.2188C43.8438 12.6162 44.1657 12.9375 44.5625 12.9375C44.9592 12.9375 45.2812 12.6162 45.2812 12.2188V10.7812C45.2812 10.3838 44.9592 10.0625 44.5625 10.0625Z"
        fill="#20273D"
      />
      <path
        d="M7.1875 35.2188C7.1875 35.6162 7.5095 35.9375 7.90625 35.9375H38.0938C38.4905 35.9375 38.8125 35.6162 38.8125 35.2188C38.8125 34.8213 38.4905 34.5 38.0938 34.5H7.90625C7.5095 34.5 7.1875 34.8213 7.1875 35.2188Z"
        fill="#3FB2D2"
      />
      <path
        d="M12.2188 29.4688H9.34375C8.947 29.4688 8.625 29.1467 8.625 28.75V23.7188C8.625 23.322 8.947 23 9.34375 23H12.2188C12.6155 23 12.9375 23.322 12.9375 23.7188V28.75C12.9375 29.1467 12.6155 29.4688 12.2188 29.4688Z"
        fill="#F5F8FA"
      />
      <path
        d="M12.2188 30.1875H9.34375C8.55097 30.1875 7.90625 29.5428 7.90625 28.75V23.7188C7.90625 22.926 8.55097 22.2812 9.34375 22.2812H12.2188C13.0115 22.2812 13.6562 22.926 13.6562 23.7188V28.75C13.6562 29.5428 13.0115 30.1875 12.2188 30.1875ZM9.34375 23.7188V28.75H12.2202L12.2188 23.7188H9.34375Z"
        fill="#3FB2D2"
      />
      <path
        d="M36.6562 29.4688H33.7812C33.3845 29.4688 33.0625 29.1467 33.0625 28.75V23.7188C33.0625 23.322 33.3845 23 33.7812 23H36.6562C37.053 23 37.375 23.322 37.375 23.7188V28.75C37.375 29.1467 37.053 29.4688 36.6562 29.4688Z"
        fill="#F5F8FA"
      />
      <path
        d="M36.6562 30.1875H33.7812C32.9885 30.1875 32.3438 29.5428 32.3438 28.75V23.7188C32.3438 22.926 32.9885 22.2812 33.7812 22.2812H36.6562C37.449 22.2812 38.0938 22.926 38.0938 23.7188V28.75C38.0938 29.5428 37.449 30.1875 36.6562 30.1875ZM33.7812 23.7188V28.75H36.6577L36.6562 23.7188H33.7812Z"
        fill="#3FB2D2"
      />
      <path
        d="M31.625 7.1875H31.2908L29.0555 3.83525C28.6558 3.23509 27.9831 2.875 27.2622 2.875H18.7378C18.0169 2.875 17.3434 3.23509 16.9438 3.83525L14.7092 7.1875H14.375V8.625H31.625V7.1875ZM16.4371 7.1875L18.1398 4.63234C18.2735 4.43181 18.497 4.3125 18.7378 4.3125H27.2622C27.503 4.3125 27.7265 4.43181 27.8602 4.63234L29.5629 7.1875H16.4371Z"
        fill="#20273D"
      />
    </svg>
  );
}
