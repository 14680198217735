import { Box } from "@chakra-ui/react";
import style from "../../../assets/style.json";

export function AdminDashboardBox({
  children,
  isPc,
  order = "none",
}: {
  children: any;
  isPc: boolean;
  order: any;
}) {
  return (
    <Box
      w="100%"
      height={"100%"}
      bg="#fff"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      boxShadow={style["box-shadow"]}
      border={style["container-border"]}
      rounded={style["border-radius1"]}
      textAlign="center"
      paddingTop={"16px"}
      paddingBottom={isPc ? style["container-padding-bottom"] : "16px"}
      paddingInline={isPc ? style["container-padding-inline"] : "0px"}
      flexBasis="50%"
      order={order}
      position={"relative"}
    >
      {children}
    </Box>
  );
}
