import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appConfig } from '../../configs/app';
import { getMimeType } from '../../services/mime';
const FileSaver = require('file-saver');
const mime = require('mime');

function DowlandFile() {
  const location = useLocation();
  const navigate = useNavigate();

  const downloadFile = async (fileName: string, path: string) => {
    /*
    DO
    NOT
    TOUCH
    */
    console.log(fileName);
    console.log(path);

    // now you can touch
    let response = await fetch(
      `${appConfig.apiUrl}/file?path=${path}&token=${localStorage.getItem(
        'token',
      )}&fileName=${fileName}`,
    );
    let data = await response.blob();

    let splittedFileName = fileName.split('.');
    let extension = splittedFileName.at(splittedFileName.length - 1);

    let fixedData = new Blob([data], { type: getMimeType(extension ?? '') });
    FileSaver.saveAs(fixedData, fileName);
  };

  const handleDownload = async (path: string, fileName: string) => {
    await downloadFile(fileName, path);
    navigate('/home');
  };

  useEffect(() => {
    console.log(location);
    const path = new URLSearchParams(location.search).get('path');
    const fileName = new URLSearchParams(location.search).get('fileName');
    if (path && fileName) {
      handleDownload(path, fileName);
    }
  }, []);

  return <div></div>;
}

export default DowlandFile;
