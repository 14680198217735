async function getLocale() {
  const cachedLocale = localStorage.getItem('locale');
  const locale = cachedLocale;
  return locale;
};

export default getLocale;


export const getDirection = (locale: string): 'ltr' | 'rtl' =>  {
  return locale === 'he' ? 'rtl' : 'ltr'; 
}