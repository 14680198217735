import {
  Box,
  Container,
  Image,
  Link,
  Skeleton,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { NewsVector } from '../../assets/NewsVector';
import newsHeader from '../../assets/newsHeader.png';
import style from '../../assets/style.json';
import {
  faEnvelope,
  faMailBulk,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const isLoading = false;

  return (
    <Container
      position={'absolute'}
      top="200px"
      maxW="full"
      minH="100vh"
      padding={isPc ? 'unset' : 0}
      bg="#f4fbff"
      centerContent
    >
      <Box
        position={'relative'}
        width="full"
        rounded={'10px'}
        maxW={style['pc-view']}
        bg={isPc ? 'white' : '#f4fbff'}
        minHeight={'300px'}
        boxShadow={isPc ? '0px 0px 25px rgba(62, 62, 62, 0.08)' : 'unset'}
        borderTop={isPc ? '9px solid #20273D' : 'unset'}
        display={'flex'}
        flexDirection="column"
        paddingBlock={'20px'}
        paddingInline="30px"
      >
        <Text mb={2} dir="rtl" fontWeight="bold" fontSize={30}>
          {t('contact.contact')}
        </Text>
        <Box
          display="grid"
          gridTemplateColumns={isPc ? '1fr 1fr' : '1fr'}
          gap="10px"
        >
          <Box display={'flex'} flexDirection="column" gap={'10px'}>
            <Box mb={isPc ? 14 : 2}>
              <Text>{t('contact.instructions')}:</Text>
            </Box>
            <Link
              fontSize={'32px'}
              href="tel:0544689875"
              display={'flex'}
              gap="10px"
              target={'_blank'}
              alignItems="center"
            >
              <FontAwesomeIcon icon={faPhone} />
              <Text fontSize={'18px'}>0544689875</Text>
            </Link>
            <Link
              fontSize={'32px'}
              href="https://wa.me/972502895544"
              display={'flex'}
              gap="10px"
              target={'_blank'}
              alignItems="center"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
              <Text fontSize={'18px'}>0544689875</Text>
            </Link>
            <Link
              fontSize={'32px'}
              href="mailto:support@joinsby.com"
              display={'flex'}
              gap="10px"
              alignItems="center"
              target={'_blank'}
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <Text fontSize={'18px'}>support@joinsby.com</Text>
            </Link>
          </Box>
          <Box display={'flex'} flexDirection="column" gap={'10px'}>
            <Text>{t('contact.address')}</Text>
            {/* {isLoading ? (
              <Box>
                <Skeleton width={'100%'} height={'480px'} />
              </Box>
            ) : ( */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3380.6699094957785!2d34.79613518446676!3d32.07817562659426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4bf826e4b5f1%3A0xdc0e9bca5e76e704!2z15PXqNeaINee16DXl9edINeR15LXmdefIDE0NCwg16rXnCDXkNeR15nXkS3Xmdek15U!5e0!3m2!1siw!2sil!4v1676217174998!5m2!1siw!2sil"
              width="100%"
              height="480"
            ></iframe>
            {/* )} */}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default Contact;
