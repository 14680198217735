import axios from 'axios';
import { appConfig } from '../configs/app';

class AssistantService {
  static async sendMessage(userId: string, message: string) {
    const response = await axios.post(
      `${appConfig.apiUrl}/assistant/message`,
      {
        userId,
        message,
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
    const data = response.data;
    return data;
  }
}

export { AssistantService };
