import { Button, useMediaQuery } from "@chakra-ui/react";
import style from "../../../assets/style.json";

export function PrimaryButton({
  children,
  onClick,
  styles,
  disabled,
}: {
  children: any;
  onClick: () => void;
  styles?: any;
  disabled?: boolean;
}) {
  const [isPc] = useMediaQuery("(min-width: 1300px)");

  return (
    <Button
      color={"white"}
      minW={isPc ? "188px" : "143px"}
      textAlign="center"
      background={style["primary-color"]}
      fontSize="18px"
      fontWeight={700}
      rounded={"100px"}
      onClick={onClick}
      disabled={!!disabled}
      {...styles}
    >
      {children}
    </Button>
  );
}
