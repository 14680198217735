import {
  Box,
  Button,
  Container,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from '../../assets/style.json';
import { useNavigate, useSearchParams } from 'react-router-dom';
import OnboardingAnswersService from '../../services/onboarding-answers-service';
import * as xlsx from 'xlsx';
import CompanyService from '../../services/company-service';

function OnboardingResults() {
  const { t } = useTranslation();
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const [isLoad, toggleIsLoad] = useState(false);
  const [results, setResults] = useState<{
    type: string;
    answers: any[];
    title: string;
  } | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const allQuestions = useMemo(() => {
    if (!results) return [];
    return results.answers.reduce((acc, answer) => {
      answer.answers.forEach((item: any) => {
        if (acc.some((a: any) => a.questionId === item.questionId)) return acc;
        acc.push({
          questionId: item.questionId,
          question: item.questionName,
        });
      });
      return acc;
    }, []);
  }, [results]);

  useEffect(() => {
    const loadCompanyAnswers = async (companyId: string) => {
      toggleIsLoad(true);
      try {
        const [answers, company] = await Promise.all([
          OnboardingAnswersService.getCompanyOnboardingAnswers(companyId),
          CompanyService.read(companyId),
        ]);
        setResults({
          type: 'company',
          answers,
          title: company.name,
        });
      } finally {
        toggleIsLoad(false);
      }
    };

    const loadOnboardingAnswers = async (onboardingId: string) => {
      toggleIsLoad(true);
      try {
        const [answers, onboarding] = await Promise.all([
          OnboardingAnswersService.getOnboardingAnswers(onboardingId),
          CompanyService.getOnboardingQuestionnaireById(onboardingId),
        ]);
        setResults({
          type: 'onboarding',
          answers,
          title: onboarding.name,
        });
      } finally {
        toggleIsLoad(false);
      }
    };
    const companyId = searchParams.get('companyId');
    const onboardingId = searchParams.get('onboardingId');
    if (companyId) loadCompanyAnswers(companyId);
    else if (onboardingId) loadOnboardingAnswers(onboardingId);
    else navigate('home');
  }, []);

  const data = useMemo(() => {
    if (!results) return { keys: [], values: [] };
    const keys = [
      t('onboardingResults.userName'),
      t('onboardingResults.userPhone'),
      results.type === 'company'
        ? t('onboardingResults.questionnaireName')
        : t('onboardingResults.companyName'),
      ...allQuestions.map((q: any) => q.question),
    ];

    const values = results.answers.map((a) => {
      let data: any = {};
      data[keys[0]] = a.userName;
      data[keys[1]] = a.userPhone;
      data[keys[2]] =
        results.type === 'company' ? a.questionnaireName : a.companyName;
      allQuestions.forEach((q: any) => {
        const answer = (a?.answers ?? []).find(
          (ans: any) => ans.questionId === q.questionId,
        );

        const selectedOption = (answer?.selectedOptions ?? [])[0];

        data[q.question] = selectedOption ?? '-';
      });
      return data;
    });

    return { keys, values };
  }, [results]);

  const downloadAllDataAsExcel = () => {
    xlsx.utils.json_to_sheet(data.values);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(
      wb,
      xlsx.utils.json_to_sheet(data.values),
      'Sheet1',
    );
    xlsx.writeFile(wb, 'onboarding-results.xlsx');
  };

  return (
    //@ts-ignore
    <Container
      position="absolute"
      top={'90px'}
      maxW="full"
      minH="100vh"
      py={20}
      centerContent
    >
      <Text
        color={'white'}
        dir="rtl"
        marginBottom={'77px'}
        fontWeight={500}
        fontSize={32}
      >
        {t('onboardingResults.title')}
      </Text>
      <Box
        w="100%"
        boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
        maxW={style['pc-view']}
        p={'21px'}
        rounded="10px"
        bg="white"
        overflowX={'auto'}
      >
        <Box display="flex" flexDirection="column" gap={2} mb={2}>
          <Box
            display="flex"
            rounded={'10px'}
            gap="21px"
            padding="21px"
            bg="#F5F8FA"
            flexDirection={isPc ? 'row' : 'column'}
          >
            <Box
              display={'flex'}
              width="100%"
              flexDirection={isPc ? 'row' : 'column'}
              justifyContent={isPc ? 'space-between' : 'center'}
              alignItems="center"
              gap="16px"
            >
              <Box>
                <Text
                  textAlign={isPc ? 'start' : 'center'}
                  color={'0E314E'}
                  fontWeight="600"
                  fontSize="22px"
                >
                  {results?.title}
                </Text>
              </Box>
              <Box display="flex" gap={2}>
                <Button
                  color={'white'}
                  minW={isPc ? '200px' : 'full'}
                  height="60px"
                  textAlign="center"
                  border="3px solid #FFFFFF"
                  background={'#3FB2D2'}
                  fontSize="18px"
                  fontWeight={700}
                  rounded={'100px'}
                  filter="drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))"
                  display="flex"
                  gap="8px"
                  alignItems="center"
                  onClick={downloadAllDataAsExcel}
                >
                  {t('onboardingResults.download')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        {!isLoad ? (
          <>
            {results && (
              <>
                <Table size="sm">
                  <Thead borderBottom={'1.5px solid #DFDFDF'}>
                    <Tr opacity={0.7}>
                      {data.keys.map((key: string, index: number) => {
                        return (
                          <Th
                            key={`key-${index}`}
                            fontSize={'16px'}
                            fontWeight={700}
                            lineHeight={'29px'}
                            paddingBottom="12px"
                            textAlign="start"
                          >
                            {key}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>

                  <Tbody>
                    {data.values.map((value: any, index: number) => {
                      return (
                        <Tr key={`value-${index}`}>
                          {data.keys.map((key: string, index: number) => {
                            return (
                              <Td
                                key={`value-${index}`}
                                fontSize={'16px'}
                                lineHeight={'29px'}
                                paddingBottom="12px"
                                textAlign="start"
                              >
                                {value[key]}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </>
            )}
          </>
        ) : (
          <Box className="flex w-full gap-3">
            <Skeleton height="50px" width={'80%'} />
            <Skeleton height="50px" width={'20%'} />
          </Box>
        )}
      </Box>
    </Container>
  );
}
export default OnboardingResults;
