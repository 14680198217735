import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/Footer/styleComponent/PrimaryButton';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';

type PhonePickerProps = {
  onSubmit: (phone: string) => void;
};

function PhonePicker({ onSubmit }: PhonePickerProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const [phone, setPhone] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setPhone('');
  }, [isOpen]);

  return (
    <>
      <Button
        color={'white'}
        minW={isPc ? '200px' : 'full'}
        height="60px"
        textAlign="center"
        border="3px solid #FFFFFF"
        background={'#3FB2D2'}
        fontSize="18px"
        fontWeight={700}
        rounded={'100px'}
        filter="drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))"
        display="flex"
        gap="8px"
        alignItems="center"
        onClick={onOpen}
      >
        {t('adminOnboarding.manual')}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          maxW={'100%'}
          width={isPc ? '840px' : 'calc(100% - 20px)'}
        >
          <ModalHeader
            className="interval"
            display="flex"
            alignItems={'center'}
            justifyContent="center"
            textAlign={'center'}
            gap="30px"
            position={'relative'}
          >
            <Text color="#475154">{t('adminOnboarding.manual')}</Text>
          </ModalHeader>
          <ModalCloseButton
            position={'absolute'}
            top={isPc ? '-40px' : '5px'}
            color={isPc ? 'white' : 'unset'}
            border={isPc ? '1px solid white' : '1px solid rgb(26,32,44)'}
            rounded={'100px'}
            left={isPc ? '0' : '25px'}
            right="unset"
          />
          <ModalBody>
            <PhoneInput
              country={'il'}
              inputClass="login-phone-input"
              value={phone}
              onChange={(phoneNumber) => {
                setPhone(phoneNumber);
              }}
            />
          </ModalBody>
          <ModalFooter width={'full'}>
            <PrimaryButton
              styles={{ minW: 'full' }}
              onClick={() => {
                onSubmit(phone);
                onClose();
              }}
            >
              {t('adminOnboarding.save')}
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PhonePicker;
