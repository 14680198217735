import axios from 'axios';
import { appConfig } from '../configs/app';
import { User } from './user-service';

export type Question = {
  id?: string;
  content: string;
  options: string[];
  correctOptionIndexs: number[];
};

export type GuidingQuestionnaire = {
  id?: string;
  name: string;
  questions: Question[];
  companyIds: string[];
  roleIds: string[];
};

export type GuidingQuestionnaireUser = User & { status: string };

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
class GuidingQuestionnaireService {
  static async read(id: string) {
    const response = await axios.get(
      `${appConfig.apiUrl}/guiding-questionnaire/${id}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );

    const data = response.data;
    return data as GuidingQuestionnaire;
  }

  static async users(id: string) {
    const response = await axios.get(
      `${appConfig.apiUrl}/guiding-questionnaire/${id}/users`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );

    const data = response.data;
    return data as GuidingQuestionnaireUser[];
  }

  static async delete(id: string) {
    await axios.delete(`${appConfig.apiUrl}/guiding-questionnaire/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
  }

  static async list() {
    const response = await axios.get(
      `${appConfig.apiUrl}/guiding-questionnaire`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
    const data = response.data;
    return data as GuidingQuestionnaire[];
  }

  static async modify(
    questionnaire: Omit<GuidingQuestionnaire, 'id'> & { id?: string },
  ) {
    console.log({ questionnaire });
    const response = await axios(`${appConfig.apiUrl}/guiding-questionnaire`, {
      data: questionnaire,
      method: questionnaire.id ? 'PUT' : 'POST',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });

    const data = response.data;

    return data;
  }

  static async submitTest(id: string) {
    await axios.post(
      `${appConfig.apiUrl}/guiding-questionnaire/${id}/submit`,
      {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
  }

  static async viewTest(id: string) {
    await axios.post(
      `${appConfig.apiUrl}/guiding-questionnaire/${id}/view`,
      {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
  }
}

export default GuidingQuestionnaireService;
