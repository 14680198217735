import {
  Box,
  Button,
  Container,
  Input,
  Textarea,
  Checkbox,
  Text,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect, useState, useContext } from 'react';
import { LanguageContext } from '../../locales/LocaleContext';
import { getDirection } from '../../locales/locale';
import { useNavigate, useParams } from 'react-router-dom';
import newslatterService from '../../services/newslatter-service';
import newsHeader from '../../assets/newsHeader.png';
import newsPlaceholder from '../../assets/news-placeholder.png';
import UserService from '../../services/user-service';
import CompanyService from '../../services/company-service';
import style from '../../assets/style.json';
import { appConfig } from '../../configs/app';

import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { NewsVector } from '../../assets/NewsVector';
import UtilSrvice from '../../services/util-service';
import { TrashIcon } from '../../assets/TrashIcon';
import { EditIcon2 } from '../../assets/EditIcon2';
import Swal from 'sweetalert2';
import { MenuIcon } from '../../assets/MenuIcon';
import { useTranslation } from 'react-i18next';

const FileSaver = require('file-saver');
const mime = require('mime');

const NewslattterView = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState<any>({});
  const [allNewslatter, setAllNewslatter] = useState<any[]>([]);
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const { locale } = useContext(LanguageContext);
  const dir = getDirection(locale);

  const [newslatter, setNewslatter] = useState<any>({
    title: '',
    content: '',
    link: '',
    file: null,
  });
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const [goingDeleteId, setGoingDeleteId] = useState<string>();

  const { id } = useParams();

  const [file, setFile] = useState<any | null>(null);

  const youtubeLinkToEmbedded = (link: string) => {
    if (!link) return link;

    var regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?(?:.*&)?v=|v\/)|youtu\.be\/)([^\?&"\'>]+)/;
    var match = link.match(regex);
    if (match) {
      return 'https://www.youtube.com/embed/' + match[1];
    } else {
      return link;
    }
  };

  useEffect(() => {
    load();
    updateNewslatter();
  }, [id]);

  const getImageLink = (filePath: string, fileName: string) => {
    return `${
      appConfig.apiUrl
    }/file?path=${filePath}&token=${localStorage.getItem(
      'token',
    )}&fileName=${fileName}`;
  };

  useEffect(() => {
    setIsValid(
      newslatter.title.trim().length > 1 &&
        newslatter.content.trim().length > 1,
    );
  }, [newslatter]);

  const updateNewslatter = async () => {
    if (id) {
      const selectedNewletter = await newslatterService.read(id);
      console.log(selectedNewletter);

      setNewslatter(selectedNewletter);
    }
  };

  const remove = async (id: string) => {
    const result = await Swal.fire({
      title: t('newsletterView.deleteNewsletter') + '?',
      text: t('newsletterView.deleteWarning'),
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: t('newsletterView.cancel'),
      confirmButtonText: t('newsletterView.delete'),
    });
    if (result.isConfirmed) {
      setGoingDeleteId(id);
      await newslatterService.delete(id);
      await load();
      navigate('/newslatter');
      setGoingDeleteId('');
    }
  };

  const load = async () => {
    const user = await UserService.info();
    setUserInfo(user);
    console.log(user);
    const resNewslatter = await newslatterService.list();
    setAllNewslatter(resNewslatter.reverse());
  };

  return (
    <Container
      position={'absolute'}
      top="200px"
      maxW="full"
      minH="100vh"
      bg="#fafafa"
      padding={isPc ? 'unset' : 0}
      centerContent
    >
      <Box
        position={'relative'}
        width="full"
        rounded={'10px'}
        maxW={style['pc-view']}
        bg={isPc ? 'white' : '#fff'}
        minHeight={'300px'}
        boxShadow={isPc ? '0px 0px 25px rgba(62, 62, 62, 0.08)' : 'unset'}
        borderTop={isPc ? '9px solid #20273D' : 'unset'}
        display={'flex'}
        gap="56px"
        paddingBlock={'20px'}
        paddingInline="30px"
      >
        {userInfo.companyId === '123' && isPc && (
          <Box
            display={'flex'}
            gap="28px"
            position={'absolute'}
            right={dir === 'rtl' ? '121px' : '121px'}
            left={dir === 'rtl' ? 'unset' : 'unset'}
            top={'16px'}
            zIndex={'3'}
          >
            <Button
              onClick={(ev) => {
                ev.stopPropagation();
                remove(newslatter.id);
              }}
              padding={'0px'}
              color="white"
              bg="transparent"
            >
              <TrashIcon />
            </Button>
            <Button
              onClick={(ev) => {
                ev.stopPropagation();
                navigate(`/admin/modify-newslatter/${newslatter?.id}`);
              }}
              padding={'0px'}
              color="white"
              bg="transparent"
            >
              <EditIcon2 color="white" />
            </Button>
          </Box>
        )}

        <Box flexGrow={'1'}>
          {isPc && (
            <>
              <Box
                transform={dir === 'rtl' ? 'scaleX(1)' : 'scaleX(-1)'}
                position={'absolute'}
                right={dir === 'rtl' ? '0' : '0'}
                left={dir === 'rtl' ? 'unset' : '0'}
                top="0"
              >
                <NewsVector />
              </Box>

              <Image
                src={newsHeader}
                position="absolute"
                left={dir === 'rtl' ? 'unset' : 'unset'}
                right={dir === 'rtl' ? '34px' : '34px'}
                top={0}
              />
            </>
          )}
          <Box display="flex" width={'full'} justifyContent="space-between">
            <Text
              fontSize={'40px'}
              fontWeight="600"
              paddingBottom="30px"
              color={'#0E314E'}
              overflowWrap="break-word"
            >
              {newslatter.title}
            </Text>
            {userInfo.companyId === '123' && !isPc && (
              <Menu>
                <MenuButton bg="transparent" as={Button}>
                  <MenuIcon />
                </MenuButton>
                <MenuList overflowY="auto" maxH={'300px'} maxW={'150px'}>
                  <MenuItem
                    color="#3FB2D2"
                    display="flex"
                    justifyContent={'space-between'}
                  >
                    {t('newsletterView.editing')}
                    <EditIcon2 />
                  </MenuItem>
                  <MenuItem
                    display="flex"
                    justifyContent={'space-between'}
                    color="#3FB2D2"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      remove(newslatter.id);
                    }}
                  >
                    {t('newsletterView.delete')}
                    <TrashIcon />
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Box>
          {newslatter.isShowVideo ? (
            <iframe
              src={youtubeLinkToEmbedded(newslatter.link)}
              className="responsive-iframe"
              allowFullScreen
            />
          ) : (
            <Image
              width={'100%'}
              paddingBottom="10px"
              src={
                newslatter.filePath
                  ? getImageLink(newslatter.filePath, newslatter.fileName)
                  : newsPlaceholder
              }
            />
          )}

          {newslatter && newslatter.createdAt && (
            <Text
              fontSize={'16px'}
              color="#A9A9A9"
              fontWeight="400"
              paddingBottom="20px"
            >
              {UtilSrvice.isoSrtingToDDMMYYY_HHMM(newslatter.createdAt)}
            </Text>
          )}
          <Text fontSize={'16px'} wordBreak="normal" fontWeight="400">
            <span
              dangerouslySetInnerHTML={{
                __html: newslatter.content.replaceAll('\n', '<br/>'),
              }}
            />
          </Text>
        </Box>
        {isPc && (
          <Box>
            <Box
              position={'relative'}
              zIndex="2"
              rounded={'10px'}
              padding={'20px'}
              flexShrink={'0'}
              width={'309px'}
              marginTop="85px"
              bg="white"
              boxShadow={'0px 0px 25px rgba(62, 62, 62, 0.08)'}
            >
              {allNewslatter
                .filter((n) => n.id !== id)
                .map((n, idx, list) => {
                  return (
                    <Box
                      display={'flex'}
                      alignItems="center"
                      key={n.id}
                      paddingBlock={'20px'}
                      width={'100%'}
                      gap="16px"
                      cursor="pointer"
                      borderBottom={
                        idx !== list.length - 1 ? '1px solid #E3E4E8' : ''
                      }
                      onClick={() => {
                        navigate(`/view-newslatter/${n.id}`);
                      }}
                    >
                      <Image
                        width={'88px'}
                        height={'88px'}
                        rounded="10px"
                        objectFit={'cover'}
                        src={
                          n.filePath
                            ? getImageLink(n.filePath, n.fileName)
                            : newsPlaceholder
                        }
                      />
                      <Box
                        display={'flex'}
                        flexDirection="column"
                        justifyContent={'space-between'}
                        gap="8px"
                      >
                        <Text
                          fontSize={'14px'}
                          fontWeight="700"
                          color="#0E314E"
                        >
                          {n.title}
                        </Text>
                        <Text
                          fontSize={'12px'}
                          color="#A9A9A9"
                          fontWeight="400"
                        >
                          {UtilSrvice.isoSrtingToDDMMYYY_HHMM(n.createdAt)}
                        </Text>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};
export default NewslattterView;
