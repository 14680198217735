export function AIIcon() {
  return (
    <svg
      id="&#x421;&#x43B;&#x43E;&#x439;_1"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
    >
      <g fill="currentColor">
        <path d="m14.5 5.625h-1.125v-.625c0-2.4125977-1.9628906-4.375-4.375-4.375h-2c-2.4121094 0-4.375 1.9624023-4.375 4.375v.625h-1.125c-.7578125 0-1.375.6166992-1.375 1.375v3c0 .7583008.6171875 1.375 1.375 1.375h1.1629028c.1893311 1.6826782 1.604187 3 3.3370972 3h.1835938c.1645508.5753174.6893921 1 1.3164063 1h1c.7578125 0 1.375-.6166992 1.375-1.375s-.6171876-1.375-1.3750001-1.375h-1c-.6270142 0-1.1518555.4246826-1.3164063 1h-.1835937c-1.4472656 0-2.625-1.1777344-2.625-2.625v-6c0-1.9990234 1.6259766-3.625 3.625-3.625h2c1.9990234 0 3.625 1.6259766 3.625 3.625v6c0 .2070313.1679688.375.375.375h1.5c.7578125 0 1.375-.6166992 1.375-1.375v-3c0-.7583008-.6171875-1.375-1.375-1.375zm-7 7.75h1c.3447266 0 .625.2802734.625.625s-.2802734.625-.625.625h-1c-.3447266 0-.625-.2802734-.625-.625s.2802734-.625.625-.625zm-6.625-3.375v-3c0-.3447266.2802734-.625.625-.625h1.125v4.25h-1.125c-.3447266 0-.625-.2802734-.625-.625zm14.25 0c0 .3447266-.2802734.625-.625.625h-1.125v-4.25h1.125c.3447266 0 .625.2802734.625.625z" />
        <path d="m7.5 5.125h-.5c-.1640625 0-.3085938.1064453-.3583984.2631836l-1.25 4c-.0615235.1977539.0488281.4082031.2460937.4697266.2021484.0629883.4082031-.0483398.4707031-.2460938l.2301636-.7368164h1.822876l.2301636.7368164c.0507812.1606445.1982422.2631836.3583984.2631836.0371094 0 .0742188-.0053711.1123047-.0170898.1972656-.0615234.3076172-.2719727.2460938-.4697266l-1.25-4c-.0498047-.1567383-.194336-.2631836-.3583985-.2631836zm-.9271851 3 .6771851-2.1679687.6771851 2.1679687z" />
        <path d="m10.25 5.125c-.2070313 0-.375.1679688-.375.375v4c0 .2070313.1679688.375.375.375s.375-.1679688.375-.375v-4c0-.2070312-.1679687-.375-.375-.375z" />
      </g>
    </svg>
  );
}
