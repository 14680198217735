import {
  Box,
  Button,
  CircularProgress,
  Container,
  Text,
  VStack,
  ScaleFade,
  HStack,
  Table,
  Tr,
  Th,
  Thead,
  Tbody,
  Td,
  SlideFade,
  Flex,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from '@chakra-ui/react';
import { log } from 'console';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ExposureService from '../../services/exposure-service';
import TasklistService from '../../services/tasklist-service';
import UserService from '../../services/user-service';
import style from '../../assets/style.json';
import UtilSrvice from '../../services/util-service';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../locales/LocaleContext';

const ExposureDetailed = ({ role }: { role: any }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, companyId } = useParams();
  const [data, setData] = useState<any>({});
  const [currUser, setCurrUser] = useState<any>({});
  const { isLoading, setIsLoading } = useContext(LanguageContext);

  const isHaveRoleIdInParents = (
    roleId: any,
    parentIds: any[],
    allRoles: any[],
  ): any => {
    if (!parentIds || parentIds.length === 0) return false;
    if (parentIds?.some((id) => id === roleId)) return true;
    const parents = parentIds?.map((id) =>
      allRoles.find((mapRole: any) => mapRole.id === id),
    );
    return parents.reduce((acc: boolean, parent: any) => {
      if (acc) return acc;
      else return isHaveRoleIdInParents(roleId, parent.parentIds, allRoles);
    }, false);
  };

  const load = async () => {
    setIsLoading(true);
    try {
      if (id) {
        let res = companyId
          ? await ExposureService.companyCategory(id, companyId)
          : await ExposureService.category(id);

        const user = await UserService.info();
        setCurrUser(user);

        res = {
          ...res,
          employeeExposures: res.employeeExposures,
        };

        const pEmployeeExposures = res.employeeExposures.map(
          async (employee: any) => {
            try {
              const employeeTaskList = await TasklistService.list(employee.id);

              const roleToTaskMap = employeeTaskList.reduce(
                (acc: any, Tasklist: any) => {
                  if (companyId) {
                    acc[Tasklist.roleId] = Tasklist.id;
                  } else {
                    const taskRole = res?.company?.roles.find(
                      (mapRole: any) => mapRole.id === Tasklist.roleId,
                    );
                    if (
                      isHaveRoleIdInParents(
                        role?.id,
                        taskRole?.parentIds,
                        res?.company?.roles,
                      ) &&
                      Tasklist.list?.some((task: any) => task.categoryId === id)
                    ) {
                      acc[Tasklist.roleId] = Tasklist.id;
                    }
                  }
                  return acc;
                },
                {},
              );

              return {
                ...employee,
                roleToTaskMap,
              };
            } catch (error: any) {
              console.error(
                `Error fetching employee task list: ${error.message}`,
              );
              throw error; // Rethrow to propagate the error
            }
          },
        );

        console.log(res);

        setData(res);
        setIsLoading(false);

        // Wait for all promises to resolve
        const employeeExposures = await Promise.all(pEmployeeExposures);

        const newRes = { ...res, employeeExposures };
        console.log(newRes);

        setData(newRes);
        setIsLoading(false);
        console.log(newRes);
      }
    } catch (error: any) {
      console.error(`Error in load function: ${error.message}`);
      // Handle error as needed, e.g., show error message to user
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  const onClickTasks = async (id: string, tasksId: string) => {
    navigate(`/tasks/${tasksId}?user_id=${id}`);
  };

  return (
    //@ts-ignore
    <Container
      maxW="full"
      minH="100vh"
      position="absolute"
      top={'90px'}
      py={20}
      centerContent
    >
      <SlideFade in>
        {isLoading ? (
          <Flex justify="center" mb={10}>
            <Skeleton height="30px" width="200px" borderRadius="md" />
          </Flex>
        ) : (
          <Text
            color={'white'}
            dir="rtl"
            marginBottom={'77px'}
            fontWeight={500}
            fontSize={32}
          >
            {data?.name}
          </Text>
        )}
      </SlideFade>
      <Box
        w="100%"
        overflowX={'auto'}
        maxW={style['pc-view']}
        bg="#fff"
        boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
        rounded="10px"
        padding={'30px'}
      >
        <Text mb={'30px'} fontWeight="400" color="#a9a9a9">
          {UtilSrvice.timestampToDDMMYYYY(new Date().getTime())}
        </Text>

        {isLoading ? (
          <>
            {' '}
            <Table size="sm">
              <Thead borderBottom={'1.5px solid #DFDFDF'}>
                <Tr>
                  <Th
                    fontSize={'16px'}
                    fontWeight={700}
                    lineHeight={'29px'}
                    paddingBottom="12px"
                    textAlign="start"
                  >
                    Employee
                  </Th>
                  <Th
                    fontSize={'16px'}
                    fontWeight={700}
                    lineHeight={'29px'}
                    paddingBottom="12px"
                    textAlign="start"
                  >
                    Progress
                  </Th>
                  <Th
                    fontSize={'16px'}
                    fontWeight={700}
                    lineHeight={'29px'}
                    paddingBottom="12px"
                    textAlign="start"
                  >
                    Role
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {[...Array(3)].map((_, i) => (
                  <Tr key={i} rounded={'10px'} border={'none'}>
                    <Td
                      bg={i % 2 === 0 ? 'white' : 'white'}
                      border={'none'}
                      roundedRight="10px"
                      textAlign="start"
                    >
                      <Skeleton height="10px" width="40%" />
                    </Td>
                    <Td bg={i % 2 === 0 ? 'white' : '#FFF'} border={'none'}>
                      <Flex justify="start" gap={1}>
                        <SkeletonCircle size="10" />
                      </Flex>
                    </Td>
                    <Td
                      bg={i % 2 === 0 ? 'white' : '#FFF'}
                      border={'none'}
                      textAlign="start"
                      roundedLeft="10px"
                    >
                      <Flex justify="start" gap={1}>
                        <SkeletonText
                          mt="4"
                          noOfLines={1}
                          spacing="4"
                          width={'10px'}
                          height={'1px'}
                        />
                        <SkeletonText
                          mt="4"
                          noOfLines={1}
                          spacing="4"
                          width={'10px'}
                          height={'1px'}
                        />
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </>
        ) : (
          <>
            <Table size="sm">
              <Thead borderBottom={'1.5px solid #DFDFDF'}>
                <Tr>
                  <Th
                    fontSize={'16px'}
                    fontWeight={700}
                    lineHeight={'29px'}
                    paddingBottom="12px"
                    textAlign="start"
                  >
                    {t('exposureDetailed.employee')}
                  </Th>

                  <Th
                    fontSize={'16px'}
                    fontWeight={700}
                    lineHeight={'29px'}
                    paddingBottom="12px"
                    textAlign="start"
                  >
                    {t('exposureDetailed.progress')}
                  </Th>
                  <Th
                    fontSize={'16px'}
                    fontWeight={700}
                    lineHeight={'29px'}
                    paddingBottom="12px"
                    textAlign="start"
                  >
                    {t('exposureDetailed.role')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.employeeExposures
                  ?.sort((a: any, b: any) => a.exposure - b.exposure)
                  // TODO: show only related employees (if the emplyoee has a role with task of a risk)
                  ?.map((empl: any, i: number) => {
                    const employee = {
                      ...empl,
                      ...(data?.company?.employees?.filter(
                        (x: any) => x.id === empl.id,
                      )?.[0] || {}),
                    };
                    const exposure =
                      employee.sum > 0 ? (employee.exposure || 0) * 100 : 100;

                    const roles = data?.company?.roles?.filter((role: any) => {
                      return !(
                        !empl?.roleToTaskMap ||
                        !empl?.roleToTaskMap[role.id] ||
                        !employee.id
                      );
                    });

                    if (!employee.isActive) return null;
                    if (!employee.sum) return null;

                    return (
                      <Tr
                        rounded={'10px'}
                        border={'none'}
                        key={'section-' + i}
                        pos={'relative'}
                      >
                        <Td
                          bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
                          border={'none'}
                          roundedRight="10px"
                          textAlign="start"
                        >
                          <Text
                            flex={1}
                            whiteSpace="nowrap"
                            px={2}
                            fontSize={15}
                            fontWeight="bold"
                            color="#3FB2D2"
                          >
                            {roles && roles.length > 0 ? (
                              <span>
                                {roles.map((role: any, idx: number) => {
                                  const isLast = idx === roles.length - 1;
                                  return (
                                    <>
                                      <Link
                                        className={
                                          !empl?.roleToTaskMap ||
                                          !empl?.roleToTaskMap[role.id] ||
                                          !employee.id
                                            ? 'disabled'
                                            : ''
                                        }
                                        to={`/tasks/${
                                          empl?.roleToTaskMap
                                            ? empl?.roleToTaskMap[role.id]
                                            : ''
                                        }?user_id=${employee.id}`}
                                      >
                                        {employee.name}
                                      </Link>
                                      <span>{isLast ? '' : ', '}</span>
                                    </>
                                  );
                                })}
                              </span>
                            ) : (
                              employee.name
                            )}
                          </Text>
                        </Td>

                        <Td
                          display="flex"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          textAlign="center"
                          border={'none'}
                          bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
                          roundedLeft={'10px'}
                        >
                          <ScaleFade in initialScale={0}>
                            <HStack spacing={1}>
                              <Text textAlign={'end'} width={'50px'}>
                                {exposure.toFixed(0)}%
                              </Text>
                              <CircularProgress
                                value={exposure}
                                size="40px"
                                thickness="10px"
                                color={
                                  exposure < 25
                                    ? 'red.500'
                                    : exposure < 50
                                    ? 'yellow.300'
                                    : exposure < 85
                                    ? '#F6A860'
                                    : '#3FB2D2'
                                }
                              />
                            </HStack>
                          </ScaleFade>
                        </Td>

                        <Td
                          bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
                          border={'none'}
                          textAlign="start"
                        >
                          <Text
                            px={5}
                            fontSize={15}
                            textAlign="start"
                            gap="5"
                            alignItems={'flex-start'}
                            justifyContent="center"
                            whiteSpace="nowrap"
                            width={'100%'}
                          >
                            {roles && roles.length > 0 ? (
                              <span>
                                {roles.map((role: any, idx: number) => (
                                  <>
                                    {role.name}
                                    <span>
                                      {idx < roles.length - 1 ? ', ' : ''}
                                    </span>
                                  </>
                                ))}
                              </span>
                            ) : (
                              '--'
                            )}
                          </Text>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ExposureDetailed;
