import { useEffect, useRef } from 'react';
import { appConfig } from '../../configs/app';
import { useParams } from 'react-router-dom';
import { getMimeType } from '../../services/mime';

const FileSaver = require('file-saver');
const Logs = () => {
  const { number } = useParams();
  const isStartDowland = useRef(false);
  const downloadFile = async (fileName: string, number: number) => {
    isStartDowland.current = true;
    let response = await fetch(
      `${appConfig.apiUrl}/file/logs/${number}?token=${localStorage.getItem(
        'token',
      )}`,
    );
    let data = await response.blob();

    let splittedFileName = fileName.split('.');
    let extension = splittedFileName.at(splittedFileName.length - 1);

    let fixedData = new Blob([data], { type: getMimeType(extension ?? '') });
    FileSaver.saveAs(fixedData, fileName);
  };

  useEffect(() => {
    if (isStartDowland.current) return;
    if (!number) return;
    downloadFile('logs.txt', parseInt(number));
  }, [number]);

  return <div></div>;
};

export default Logs;
