import { Box, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import splashSrc from "../assets/splash.png";

export function SplashGuard({ children }: { children: any }) {
  const [isSplashScreen, setIsSplashScreen] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    updateSplashScreen();
  }, []);

  const updateSplashScreen = () => {
    const rid = searchParams.get("rid");
    if (rid) {
      setTimeout(() => {
        setIsSplashScreen(false);
      }, 1000);
    } else {
      setIsSplashScreen(false);
    }
  };

  if (isSplashScreen)
    return (
      <Box position="fixed" width="100%" height="100%">
        <Image width="100%" height="100%" objectFit="cover" src={splashSrc} />
      </Box>
    );
  else return <>{children}</>;
}
