import {
  Box,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { ArrowDownIcon } from '../assets/ArrowDownIcon';
import { ClockIcon } from '../assets/ClockIcon';
import { DateIcon } from '../assets/DateIcon';
import { LikeIcon } from '../assets/LikeIcon';
import { ReapetIcon } from '../assets/ReapetIcon';
import { Calander } from './Calander';
import { PrimaryButton } from './Footer/styleComponent/PrimaryButton';
import { useTranslation } from 'react-i18next';

export function RangePicker({ selectedDates, setSelectedDates }: any) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isShowStartDate, setIsShowStartDate] = useState(true);
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const changeShowDateRef = useRef<any>(null);
  const changeShowDateRef2 = useRef<any>(null);
  const [selectedMonth, setSelectedMonth] = useState(
    selectedDates[0].getMonth(),
  );
  const [selectedYear, setSelectedYear] = useState(
    selectedDates[0].getFullYear(),
  );
  const month = [
    t('month.january'),
    t('month.february'),
    t('month.march'),
    t('month.april'),
    t('month.may'),
    t('month.june'),
    t('month.july'),
    t('month.august'),
    t('month.september'),
    t('month.october'),
    t('month.november'),
    t('month.december'),
  ];
  const thisYear = new Date().getFullYear();
  const years = Array.from(Array(10).keys()).map((idx) => thisYear - 5 + idx);

  useEffect(() => {
    if (isShowStartDate && changeShowDateRef.current) {
      const start = new Date(selectedDates[0].toString());
      changeShowDateRef.current(start);
    } else if (!isShowStartDate && changeShowDateRef2.current) {
      const end = new Date(selectedDates[1].toString());
      changeShowDateRef2.current(end);
    }
  }, [isShowStartDate]);

  useEffect(() => {
    if (isShowStartDate && changeShowDateRef.current) {
      const start = new Date(selectedDates[0].toString());
      changeShowDateRef.current(
        (prev: Date) => new Date(prev.setFullYear(selectedYear, selectedMonth)),
      );
    } else if (!isShowStartDate && changeShowDateRef2.current) {
      const end = new Date(selectedDates[1].toString());
      changeShowDateRef2.current(
        (prev: Date) => new Date(prev.setFullYear(selectedYear, selectedMonth)),
      );
    }
  }, [selectedMonth, selectedYear]);

  const units = {
    minute: t('rangePicker.minute'),
    day: t('rangePicker.day'),
    week: t('rangePicker.week'),
    month: t('rangePicker.month'),
    year: t('rangePicker.year'),
  };

  function formatDate(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  function formatString(str: string) {
    const [year, month, day] = str.split('-');
    return [day, month, year.slice(-2)].join('/');
  }

  return (
    <div>
      <Button
        padding={'12px 15px'}
        border="1.2521px solid #E2E2E2"
        onClick={onOpen}
        bg="#fff"
        fontSize={18}
        height="54px"
        width={isPc ? 'unset' : '100%'}
      >
        <Box display="flex" alignItems={'center'} gap="8px">
          <DateIcon />
          {formatString(formatDate(selectedDates[0]))} -{' '}
          {formatString(formatDate(selectedDates[1]))}
        </Box>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={'100%'} width={'540px'}>
          <ModalHeader
            display="flex"
            alignItems={'center'}
            justifyContent="center"
            textAlign={'center'}
            gap="30px"
            position={'relative'}
          >
            <Text color="#475154">{t('rangePicker.filterDates')}</Text>
          </ModalHeader>
          <ModalCloseButton
            position={'absolute'}
            top={'-40px'}
            color={'white'}
            border={'1px solid white'}
            rounded={'100px'}
            left={0}
            right="unset"
          />
          <ModalBody>
            <Box rounded={'10px'} padding={'20px'} bg={'#F5F8FA'}>
              <Text
                fontSize={'18px'}
                fontWeight="600"
                display={'flex'}
                alignItems="center"
                mb={'10px'}
              >
                <DateIcon />
                תאריך התחלה
              </Text>
              <Box
                display={'flex'}
                justifyContent="space-between"
                alignItems="center"
                mb={'30px'}
                flexDirection={isPc ? 'row' : 'column'}
                gap={isPc ? 'unset' : '10px'}
              >
                <Box display={'flex'} gap="9px">
                  <Menu>
                    <MenuButton>
                      <Box
                        display={'flex'}
                        flexDirection="row"
                        textAlign={'right'}
                        alignItems="center"
                        justifyContent="space-between"
                        width={'90px'}
                        color={'#3FB2D2'}
                      >
                        <Box>
                          <Text
                            color={'#3FB2D2'}
                            fontSize="18px"
                            fontWeight="700"
                          >
                            {month[selectedMonth]}
                          </Text>
                        </Box>
                        <Box>
                          <ArrowDownIcon color="#3FB2D2" />
                        </Box>
                      </Box>
                    </MenuButton>
                    <MenuList overflowY="auto" maxH={'300px'}>
                      {month.map((month: string, idx) => {
                        return (
                          <MenuItem
                            // color="red.600"
                            _hover={{
                              bg: '#F5F8FA',
                            }}
                            display="flex"
                            justifyContent={'space-between'}
                            onClick={() => {
                              setSelectedMonth(idx);
                            }}
                          >
                            <Box>{month}</Box>
                            {idx === selectedMonth ? (
                              <Box
                                display={'flex'}
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Text fontSize="14px" fontWeight="700">
                                  ✓
                                </Text>
                              </Box>
                            ) : null}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Menu>
                  <Menu>
                    <MenuButton>
                      <Box
                        display={'flex'}
                        flexDirection="row"
                        textAlign={'right'}
                        alignItems="center"
                        justifyContent="space-between"
                        width={'60px'}
                      >
                        <Box>
                          <Text fontSize="18px" fontWeight="700">
                            {selectedYear}
                          </Text>
                        </Box>
                        <Box>
                          <ArrowDownIcon />
                        </Box>
                      </Box>
                    </MenuButton>
                    <MenuList overflowY="auto" maxH={'300px'}>
                      {years.map((year: number) => {
                        return (
                          <MenuItem
                            // color="red.600"
                            _hover={{
                              bg: '#F5F8FA',
                            }}
                            display="flex"
                            justifyContent={'space-between'}
                            onClick={() => {
                              setSelectedYear(year);
                            }}
                          >
                            <Box>{year}</Box>
                            {year === selectedYear ? (
                              <Box
                                display={'flex'}
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Text fontSize="14px" fontWeight="700">
                                  ✓
                                </Text>
                              </Box>
                            ) : null}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Menu>
                </Box>
                <Box display={'flex'} gap={'5px'} alignItems="center">
                  <Button
                    bg={'#fff'}
                    boxShadow={
                      isShowStartDate
                        ? '0px 4px 30px rgba(63, 178, 210, 0.34)'
                        : '0px 0px 25px rgba(62, 62, 62, 0.08)'
                    }
                    border={
                      isShowStartDate
                        ? '1.25px solid #3FB2D2'
                        : '1.25px solid #fff'
                    }
                    rounded={'10px'}
                    onClick={() => {
                      setIsShowStartDate(true);
                    }}
                  >
                    {formatString(formatDate(selectedDates[0]))}
                  </Button>
                  <Text>-</Text>
                  <Button
                    bg={'#fff'}
                    boxShadow={
                      !isShowStartDate
                        ? '0px 4px 30px rgba(63, 178, 210, 0.34)'
                        : '0px 0px 25px rgba(62, 62, 62, 0.08)'
                    }
                    border={
                      !isShowStartDate
                        ? '1.25px solid #3FB2D2'
                        : '1.25px solid #fff'
                    }
                    rounded={'10px'}
                    onClick={() => {
                      setIsShowStartDate(false);
                    }}
                  >
                    {formatString(formatDate(selectedDates[1]))}
                  </Button>
                </Box>
              </Box>

              {isShowStartDate ? (
                <Calander
                  changeShowDateRef={changeShowDateRef}
                  setSelectedMonth={setSelectedMonth}
                  setSelectedYear={setSelectedYear}
                  date={selectedDates[0]}
                  setDate={(date) => {
                    setSelectedDates((prev: any) => [date, prev[1]]);
                  }}
                />
              ) : (
                <Calander
                  changeShowDateRef={changeShowDateRef2}
                  setSelectedMonth={setSelectedMonth}
                  setSelectedYear={setSelectedYear}
                  date={selectedDates[1]}
                  setDate={(date) => {
                    setSelectedDates((prev: any) => [prev[0], date]);
                  }}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter width={'full'}>
            <PrimaryButton styles={{ minW: 'full' }} onClick={onClose}>
              {t('rangePicker.save')}
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
