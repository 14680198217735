import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  useMediaQuery,
  Skeleton,
  Tooltip,
} from '@chakra-ui/react';

import CompanyService from '../../services/company-service';
import ReportsService from '../../services/reports-service';
import { appConfig } from '../../configs/app';
import { RangeDatepicker } from 'chakra-dayzed-datepicker';
import style from '../../assets/style.json';
import { DownloadIcon } from '../../assets/DownloadIcon';
import { RangePicker } from '../../components/RangePicker';
import { ArrowDownIcon } from '../../assets/ArrowDownIcon';
import { useTranslation } from 'react-i18next';
import { risksFilesService } from '../../services/risks-files-service';
import { CirclePlusIcon } from '../../assets/CirclePlusIcon';
import { ReapetIcon } from '../../assets/ReapetIcon';
import { getMimeType } from '../../services/mime';
import Swal from 'sweetalert2';
import { TrashIcon } from '../../assets/TrashIcon';

const FileSaver = require('file-saver');
const mime = require('mime');

const RisksFiles = () => {
  const { t } = useTranslation();
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  let { companyId } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [companies, setCompanies] = useState<any | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [riskId, setRiskId] = useState('');
  const [downloadRiskId, setDownloadRiskId] = useState('');
  const [loadData, setLoadData] = useState(false);
  const uploadFile = async (id: string) => {
    setRiskId(id);
    const input = document.getElementById('fileInput');

    input?.click();
  };

  const load = async () => {
    setLoadData(true);
    try {
      if (companyId !== undefined) {
        const company = await CompanyService.read(companyId);
        setSelectedCompany(company);
      } else {
        let companies = await CompanyService.list();
        companies = await Promise.all(
          companies.map(async (company: any) => {
            return {
              ...company,
              reports: await ReportsService.list(company.id),
            };
          }),
        );

        setCompanies(companies);
      }
    } finally {
      setLoadData(false);
    }

    setLoaded(true);
  };

  const downloadFile = async (fileName: string, path: string) => {
    /*
    DO
    NOT
    TOUCH
    */

    console.log(fileName);
    console.log(path);

    // now you can touch

    try {
      let response = await fetch(
        `${appConfig.apiUrl}/file?path=${path}&token=${localStorage.getItem(
          'token',
        )}&fileName=${fileName}`,
      );
      let data = await response.blob();

      let splittedFileName = fileName.split('.');
      let extension = splittedFileName.at(splittedFileName.length - 1);

      let fixedData = new Blob([data], { type: getMimeType(extension ?? '') });
      FileSaver.saveAs(fixedData, fileName);
    } finally {
      setDownloadRiskId('');
    }
  };

  const handleFileInput = async (e: any) => {
    const file = e.target!.files[0];
    if (!file) return;
    if (file.name.endsWith('.doc') || file.name.endsWith('.docx')) {
      await risksFilesService.uploadFile(file, selectedCompany.id, riskId);
    } else {
      Swal.fire({
        icon: 'error',
        title: t('risksFiles.errorTitle'),
        text: t('risksFiles.errorText'),
      });
    }
    load();
  };

  const handleDelete = async (riskId: string) => {
    await risksFilesService.deleteRiskFile(riskId, selectedCompany.id);
    load();
  };

  const handleRestart = async () => {
    setLoading(true);
    await risksFilesService.restartAssistant(selectedCompany.id);
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    //@ts-ignore
    <Container
      position="absolute"
      top={'90px'}
      maxW="full"
      minH="100vh"
      py={20}
      centerContent
    >
      <Text
        color={'white'}
        dir="rtl"
        marginBottom={'77px'}
        fontWeight={500}
        fontSize={32}
      >
        {t('risksFiles.title')}
      </Text>
      <Box
        w="100%"
        boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
        maxW={style['pc-view']}
        p={'21px'}
        rounded="10px"
        bg="white"
      >
        {!companies && selectedCompany && (
          <Box
            display="flex"
            rounded={'10px'}
            gap="21px"
            padding="21px"
            bg="#F5F8FA"
            flexDirection={isPc ? 'row' : 'column'}
          >
            <Box
              display={'flex'}
              width="100%"
              flexDirection={isPc ? 'row' : 'column'}
              justifyContent={isPc ? 'space-between' : 'center'}
              alignItems="center"
              gap="16px"
            >
              <Box>
                <Text
                  textAlign={isPc ? 'start' : 'center'}
                  color={'0E314E'}
                  fontWeight="600"
                  fontSize="22px"
                >
                  {selectedCompany.name}
                </Text>
              </Box>
              <Box display={'flex'} gap={2} alignItems="center">
                <Tooltip hasArrow label={t('risksFiles.restartTooltip')}>
                  <Box
                    width={'40px'}
                    height={'40px'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    rounded={'50%'}
                    bg={'#3FB2D2'}
                    color="white"
                  >
                    ?
                  </Box>
                </Tooltip>
                <Button
                  color={'white'}
                  minW={isPc ? '200px' : 'full'}
                  height="60px"
                  textAlign="center"
                  border="3px solid #FFFFFF"
                  background={'#3FB2D2'}
                  fontSize="18px"
                  fontWeight={700}
                  rounded={'100px'}
                  filter="drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))"
                  display="flex"
                  gap="8px"
                  isLoading={loading}
                  isDisabled={loading}
                  alignItems="center"
                  onClick={handleRestart}
                >
                  {t('risksFiles.restart')}
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        <iframe
          style={{ display: 'none' }}
          id="preview-iframe"
          width="100%"
          height="600px"
        />

        {loaded ? (
          <>
            {!companies && selectedCompany && (
              <>
                <Table size="sm">
                  <Thead borderBottom={'1.5px solid #DFDFDF'}>
                    <Tr opacity={0.7}>
                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                      >
                        {t('risksFiles.riskName')}
                      </Th>
                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                      >
                        {t('risksFiles.download')}
                      </Th>
                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                      >
                        {t('risksFiles.file')}
                      </Th>
                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                      >
                        {t('risksFiles.delete')}
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {selectedCompany.exposureCategories.map(
                      (risk: any, index: number) => {
                        return (
                          <Tr
                            key={`employee-${index}`}
                            rounded={'10px'}
                            border={'none'}
                            position="relative"
                          >
                            <Td
                              roundedRight={'10px'}
                              bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
                              border={'none'}
                              textAlign="start"
                            >
                              {risk.name}
                            </Td>
                            <Td
                              roundedLeft={'10px'}
                              bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
                              border={'none'}
                              textAlign="start"
                            >
                              <Button
                                isDisabled={
                                  !risk.filePath ||
                                  !risk.fileName ||
                                  downloadRiskId === risk.id
                                }
                                isLoading={
                                  downloadRiskId === risk.id || loadData
                                }
                                onClick={(ev) => {
                                  setDownloadRiskId(risk.id);
                                  downloadFile(risk.fileName, risk.filePath);
                                  ev.stopPropagation();
                                }}
                                textAlign="center"
                                fontSize="18px"
                                fontWeight={700}
                                rounded={'100px'}
                                bg={'#fff'}
                                color={style['primary-color']}
                              >
                                <Box
                                  gap={'17px'}
                                  display={'flex'}
                                  alignItems={'center'}
                                >
                                  {t('risksFiles.download')}
                                  <DownloadIcon />
                                </Box>
                              </Button>
                            </Td>
                            <Td
                              roundedLeft={'10px'}
                              bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
                              border={'none'}
                              textAlign="start"
                            >
                              <Button
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  uploadFile(risk.id);
                                }}
                                isLoading={loadData}
                                textAlign="center"
                                fontSize="18px"
                                fontWeight={700}
                                rounded={'100px'}
                                bg={'#fff'}
                                color={style['primary-color']}
                              >
                                <Box
                                  gap={'17px'}
                                  display={'flex'}
                                  alignItems={'center'}
                                >
                                  <Text>
                                    {!risk.filePath || !risk.fileName ? (
                                      <span>{t('risksFiles.add')}</span>
                                    ) : (
                                      <span>{t('risksFiles.replace')}</span>
                                    )}
                                  </Text>
                                  <CirclePlusIcon />
                                </Box>
                              </Button>
                            </Td>
                            <Td
                              roundedLeft={'10px'}
                              bg={index % 2 !== 1 ? 'white' : '#F5F8FA'}
                              border={'none'}
                              textAlign="start"
                            >
                              <Button
                                onClick={(ev) => {
                                  handleDelete(risk.id);
                                }}
                                isLoading={loadData}
                                textAlign="center"
                                fontSize="18px"
                                isDisabled={!risk.filePath || !risk.fileName}
                                fontWeight={700}
                                rounded={'100px'}
                                bg={'#fff'}
                                color={style['primary-color']}
                              >
                                <Box
                                  gap={'17px'}
                                  display={'flex'}
                                  alignItems={'center'}
                                >
                                  <Text>{t('risksFiles.delete')}</Text>
                                  <TrashIcon />
                                </Box>
                              </Button>
                            </Td>
                          </Tr>
                        );
                      },
                    )}
                  </Tbody>
                </Table>
                <input
                  type="file"
                  id="fileInput"
                  style={{ visibility: 'hidden', width: 0, height: 0 }}
                  onChange={(e) => handleFileInput(e)}
                ></input>
              </>
            )}
          </>
        ) : (
          <Box className="flex w-full gap-3">
            <Skeleton height="50px" width={'80%'} />
            <Skeleton height="50px" width={'20%'} />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default RisksFiles;
