const english = {
  translation: {
    onboardingResults: {
      title: 'Onboarding results',
      download: 'Download',
      userName: 'User name',
      userPhone: 'Phone',
      companyName: 'Company name',
    },
    onboarding: {
      header: 'Welcome to our reporting system',
      personalInformation: 'Personal information',
      toc: 'Terms of service',
      form: 'Form',
      name: 'Full name',
      roles: 'Roles',
      next: 'Next',
      prev: 'Back',
    },
    onboardingManagement: {
      title: 'Onboarding questionnaire management',
      add: 'Add',
    },
    adminOnboarding: {
      settings: 'Onboarding settings',
      roles: 'Roles',
      save: 'Save',
      answers: 'Answers',
      title: 'Onboarding questionnaire',
      phone: 'Phone',
      loadPhones: 'Load phones',
      remove: 'Remove',
      errorTitle: 'Error',
      errorText: 'Only Excel files can be uploaded',
      manual: 'Manual entry',
      saveCompanyOnboarding: 'Save company data',
      onboardingQuestionnaireManagement: 'Onboarding questionnaire management',
      onboardingQuestionnaire: 'Onboarding questionnaire',
    },
    sanctions: {
      title: 'International Sanctions',
      search: 'Search',
      placeholder: 'Search by full or partial name in English only...',
      query: 'Search person',
      errorTitle: 'Error',
      errorText: 'Only Excel files can be uploaded',
      setEurope: 'Update European sanctions',
      setAmerica: 'Update American sanctions',
      setInterpol: 'Update Interpol sanctions',
      europeName: 'European name list',
      americaName: 'American name list',
      interpolName: 'Interpol name list',
      minChars: 'Must enter at least 3 characters',
      loadData: 'Loading data...',
      noResults: 'No results found.',
    },
    assistant: {
      waiting: 'Waiting for response',
      placeholder: 'Type your message here',
      title: 'Assistant',
      errorTitle: 'Error',
      errorMessage: 'An error occurred, please try again later',
    },
    risksFiles: {
      delete: 'Delete',
      upload: 'Upload',
      download: 'Download',
      file: 'File',
      riskName: 'Risk name',
      title: 'Bot management',
      restart: 'Rebuild bot',
      restartTooltip:
        "Important: Rebuilding the bot is an exceptional and powerful action. It is performed automatically every night, but pressing the button allows you to execute it immediately and update all the bot's data. The process consumes a significant amount of tokens from OpenAI (and consequently, costs). It is crucial to use this feature with caution and to wait approximately 5 minutes before using the bot again",
      errorTitle: 'Error',
      errorText: 'Only Word files can be uploaded',
      add: 'Add',
      replace: 'Replace',
    },
    month: {
      january: 'Jan',
      february: 'Feb',
      march: 'Mar',
      april: 'Apr',
      may: 'May',
      june: 'Jun',
      july: 'Jul',
      august: 'Aug',
      september: 'Sep',
      october: 'Oct',
      november: 'Nov',
      december: 'Dec',
    },
    calendar: {
      sunday: 'Sun',
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
    },
    modifyQuestionnaire: {
      lang: 'Language',
      isRequired: 'Required question',
      title: 'Edit questionnaire',
      name: 'Questionnaire name',
      questionContent: 'Question content',
      options: 'Options',
      option: 'Option',
      question: 'Question',
      addQuestion: 'Add question',
      addOption: 'Add option',
      deleteQuestion: 'Delete question',
      deleteOption: 'Delete option',
      save: 'Save',
      isCorrect: 'Correct',
      isNotCorrect: 'Not correct',
    },
	guidingQuestionnaire: {
		delete: "Delete",
		deleteWarning: "The info cannot be recovered after deletion",
		cancel: "Cancel",
		hello: "Hello",
		submit: "Submit",
		testDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec pur',
		test: "Questionnaire:",
		error: "Error!",
		wrongAnswers: "Some answers are wrong",
		notAllQuestionsAnswered: "Not all questions have been answered",
		success: "Success!",
		successMessage: "The questionnaire was submitted successfully",
		noQuestionnaires: "No questionnaires found",
	},
    modifyGuidingQuestionnaire: {
      lang: 'Language',
      isRequired: 'Required question',
      title: 'Edit questionnaire',
      name: 'Questionnaire name',
      questionContent: 'Question content',
      options: 'Options',
      option: 'Option',
      question: 'Question',
      addQuestion: 'Add question',
      addOption: 'Add option',
      deleteQuestion: 'Delete question',
      deleteOption: 'Delete option',
      save: 'Save',
      questionnaireUsers: 'Questionnaire users',
      status: {
        pending: 'Pending',
        read: 'Read',
        done: 'Done',
      },
    },
    intervalPicker: {
      minute: 'Minute',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      taskDetails: 'Task details',
      startDate: 'Start date',
      hour: 'Hour',
      back: 'Back',
      every: 'Every',
      save: 'Save',
    },
    rangePicker: {
      minute: 'Minute',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      filterDates: 'Filter dates',
      save: 'Save',
    },
    sidebar: {
      onboardingManagement: 'Onboarding',
      sanctions: 'International Sanctions',
      role: 'Role',
      main: 'Main',
      training: 'Training',
      history: 'History',
      about: 'About',
      termsOfService: 'Terms of service',
      privacyPolicy: 'Privacy policy',
      contactUs: 'Contact us',
      signOut: 'Sign out',
    },
    topbar: {
      back: 'Back',
      noRolesFound: 'No roles found',
      chooseRole: 'Choose role',
    },
    about: {
      title: 'About SBY',
      contents: `The company SBY (WE STAND BY YOU) was founded in collaboration with Benny Katz law firm in order to provide a response to managers who are exposed to numerous criminal events, most of which they are often unaware of. In recent years, criminal enforcement has expanded and numerous investigations have been opened against CEOs, directors, and officials, some resulting in arrests. The ruling broadened the CEO's responsibility and established that they are obligated to do everything necessary to prevent criminal events. SBY specializes in providing personal coverage for managers by identifying exposures and risks through a comprehensive survey tailored precisely to the personal criminal risks of the CEO and the nature of the company's activities. Presenting the survey to the CEO discreetly and building a system of continuous solutions and treatment in dealing with criminal exposure in "Plan 18" - implementation and personal accompaniment through the use of advanced means.`,
    },
    addTasksForm: {
      deleteFile: 'Delete file',
      deleteWarning: 'The info cannot be recovered after deletion',
      cancel: 'Cancel',
      delete: 'Delete',
      error: 'Error',
      invalidDate: 'Invalid date',
      success: 'Success',
      taskListCreated: 'Task list successfully created',
      addList: 'Add list',
      job: 'Job',
      roles: 'Roles',
      tasks: 'Tasks',
      noRisks: 'Company has no risk',
      addRisk: 'Add risk',
      taskName: 'Task name',
      taskDescription: 'Enter task description',
      addFile: 'Add file',
      taskDetails: 'Task details',
      file: 'File',
      download: 'Download',
      addTask: 'Add task',
      save: 'Save',
    },
    companies: {
      companies: 'Companies',
      addCompany: 'Add company',
      name: 'Name',
      field: 'Field',
      performance: 'Performance',
    },
    contact: {
      contact: 'Contact',
      instructions: `SBY platform helps companies stay protected from personal and criminal exposure for individuals in various legal fields, with full support from us. If there are significant matters that you are unsure about, it is advisable to consult with us. Our team has worked extensively to provide you with a simple and efficient platform, but if something is still unclear or if you encounter a problem, you are welcome to contact us using one of the following methods`,
      address: '144 Menachem Begin Blvd., Midtown Bild, Floor 45, Tel Aviv',
    },
    dashboard: {
      error: 'Error',
      unauthorized: 'You are not authorized to access this page',
      prevailTogether: 'We stand together',
      welcome: 'Welcome',
      training: 'Important Training',
      questionnaire: 'Questionnaire',
      viewTraining: 'View training',
      viewQuestionnaire: 'View questionnaire',
      myTasks: 'My Tasks',
      show: 'Show',
      performance: 'Performance',
      actions: 'Actions',
      processing: 'Processing',
      addTraining: 'Add training',
      companies: 'Companies',
      addCompany: 'Add company',
      addQuestionnaire: 'Add questionnaire',
      viewCompanies: 'View companies',
      questionnaires: 'Questionnaires',
      showQuestionnaires: 'Show questionnaires',
    },
    exposure: {
      performance: 'Performance',
      generalPerformance: 'General performance',
      processing: 'Processing',
      risks: 'Risks',
      check: 'Check',
    },
    exposureDetailed: {
      employee: 'Employee',
      progress: 'Progress',
      role: 'Role',
    },
    login: {
      pleaseLogin: 'Please login to proceed',
      enterMfaCode: 'Enter verification code',
      enterEmailPass: 'Please enter your email and password',
      hello: 'Hello',
      enterCode: 'Enter code',
      codeSent: 'Verification code sent to',
      mfaCall: 'Receive code via phone call',
      mfaDeferred: 'Available in',
      seconds: 'seconds',
      phoneNumber: 'Phone number',
      rememberMe: 'Remember me',
      loginButton: 'Login',
    },
    modifyCompany: {
      onboardingQuestionnaireManagement: 'Onboarding questionnaire management',
      isAfterOnboarding: 'After onboarding',
      onboardingQuestionnaire: 'Onboarding questionnaire',
      invalidName: 'Invalid name',
      enterCompanyName: 'Please enter company name',
      enterRiskNames: 'Please enter names for all risks',
      uniqueRiskNames: 'Risk names must be unique',
      enterRoleNames: 'Please enter names for all risks',
      uniqueRoleNames: 'Role names must be unique',
      invalidEmployee: 'Invalid employee',
      invalidEmployeeName: 'One of the employee names is invalid',
      invalidPhoneNumber: 'Invalid phone number',
      enterPhoneNumbers: 'Please enter phone numbers for every employee',
      editCompany: 'Edit company',
      addCompany: 'Add company',
      companyDetails: 'Company details',
      companyName: 'Company name',
      field: 'Field',
      siteLink: 'Site link',
      active: 'Active',
      language: 'Language',
      risks: 'Risks',
      riskName: 'Risk name',
      deleteRisk: 'Delete risk',
      deleteWarning: 'The info cannot be recovered after deletion',
      enterPassword: 'Enter password',
      cancel: 'Cancel',
      delete: 'Delete',
      invalidPassword: 'Invalid password',
      addRisk: 'Add risk',
      roles: 'Roles',
      showRiskProgress: 'Show risk progress',
      showNewsletters: 'Show newsletters',
      reporting: 'Reporting',
      addRole: 'Add role',
      employees: 'Employees',
      addEmployee: 'Add employee',
      name: 'Name',
      phone: 'Phone',
      role: 'Role',
      email: 'Email',
      chooseRole: 'Choose role',
      deleteEmployee: 'Delete employee',
      employeeName: 'Employee name',
      moreDetails: 'More details',
      approve: 'Approve',
    },
    modifyNewsletter: {
      deleteFile: 'Delete file',
      deleteWarning: 'The info cannot be recovered after deletion',
      cancel: 'Cancel',
      delete: 'Delete',
      addTraining: 'Add training',
      generalDetails: 'General details',
      titleDescription: 'Title description',
      video: 'Video',
      picture: 'Picture',
      linkTo: 'Link to',
      title: 'Title',
      choosePicture: 'Choose picture',
      deletePicture: 'Delete picture',
      recipients: 'Recipients',
      companyName: 'Company name',
      selectAll: 'Select all',
      content: 'Content',
      enterContent: 'Enter content',
      save: 'Save',
    },
    newsletter: {
      results: 'Results',
      deleteNewsletter: 'Delete the newsletter',
      deleteWarning: 'The info cannot be recovered after deletion',
      cancel: 'Cancel',
      delete: 'Delete',
      hello: 'Hello',
      peaceOfMind: '',
      addTraining: 'Add training',
      edit: 'Edit',
      editing: 'Edit',
    },
    newsletterView: {
      deleteNewsletter: 'Delete the newsletter',
      deleteWarning: 'The info cannot be recovered after deletion',
      cancel: 'Cancel',
      delete: 'Delete',
      editing: 'Edit',
    },
    privacyPolicy: {
      title: 'SBY privacy policy',
      conditions: `This Privacy Policy ("the Policy") describes to the user ("the User") the manner of collection, use, and sharing of the personal information provided by you and/or collected about you through the PRC system ("the System" and/or "PRC") and/or by you. Your input of your details into the system and/or information you choose to share with SBY Ltd. ("the Company"), all in accordance with the details outlined in the following policy. The Policy constitutes an integral part of the website's terms of use. The Policy is written using male pronouns for convenience, but intended for individuals of all genders. We recommend that you read the privacy policy carefully, and if you do not agree to the terms of the policy, please refrain from using the PRC system. Your continued use will constitute your consent to the terms of use.`,
      contents: `1. Local Laws and Regulation

1.1 This privacy policy aims to comply with the laws and regulations applicable in any country from which anonymous and personal information is collected, as described in this privacy policy, including the Privacy Protection Law, 1981 (hereinafter: "the Law") and the Privacy Protection (Information Security), 2017 (hereinafter: "the Regulations").

2. Information Collected by the Company and Its Use

2.1 Anonymous Information Collected: When using the system, the Company may collect information related to system usage that does not allow for the identification of individuals and/or the disclosure of users' identities, such as aggregated information and analysis of usage patterns (hereinafter: "Anonymous Information"). This is for the purpose of improving the services and enriching the content of the system, its quality, and the level of service provided to users by the Company, as well as for the use of the collected information for statistical purposes, conducting research, and preparing anonymous analysis. The Company is authorized to collect, use, and disclose anonymous information, and to disclose it to third parties, for the purpose of providing, improving, and promoting the Company's system and services. Anonymous information may be retained indefinitely in the Company's archives for future use.
2.2 Personal Information Collected: This information allows for the full identification of the user or can reasonably be used to identify the user through additional information (hereinafter: "Personal Information"). The Company may collect personal information that you input and provide in the system (such as: full name, ID number, email address, date of birth, etc.).
2.3 Use of Personal Information: The Company collects personal information for the following purposes (all or part of them):
2.4 For the purpose of providing the services provided to the user by the Company within the framework of the system and/or using and/or improving the services in the system.
2.5 For support and response to user inquiries and communication with users in the system.
2.6 For user identification when contacting the Company and/or on its behalf.
2.7 For customer relations, including communication for the purpose of updating users on the Company's latest updates and providing users with information regarding content that may interest them.
2.8 Technical information from the user's mobile device and/or computer for fraud prevention purposes.
2.9 To comply with legal requirements, regulatory obligations, and requirements of a government authority or court order.
2.10 For any other purpose specified in the policy or terms of use of any of the relevant services in the system.
2.11 The user undertakes to provide accurate, precise, and complete information and hereby confirms the accuracy of the information provided by him. It is clarified that the user is not obliged to provide any information to the Company through the system; however, if the information is not provided, the Company may not be able to provide services (all or part of them) to that user.
2.12 If the Company requests to add uses and/or collect additional personal information, it will request the user's prior consent. However, if such consent is not given, the Company may not be able to provide services (all or part of them) to that user.
2.13 Information Databases: The user acknowledges that information provided by the user is collected by the Company as detailed in this policy, accumulated in the Company's databases and in the Company's information database and/or in the database of service providers on its behalf, and can be viewed by any user who registers for the Company's services and pays to subscribe to the services.
2.14 The user is aware that the Company will maintain, manage, and use the information in the information database to promote its operations, including operations with other entities, and the user agrees to this and declares that he has no claim against the Company or anyone on its behalf as a result, provided that the use of the information complies with the provisions of this privacy policy.

3. Sharing of Information with Third Parties

The Company transfers information to third parties only in the cases specified below:

3.1 With the explicit consent of the user.
3.2 To government authorities in accordance with their statutory powers.
3.3 Personal information will be transferred by the Company to related companies, including subsidiaries, joint ventures, or other companies under common control, or businesses managing services.
3.4 In the event of a legal dispute between the user and the Company requiring disclosure of the user's details.
3.5 If the user performs actions within the system that are contrary to the law and/or that interfere with proper operation.
3.6 If a judicial order is received instructing the disclosure of the user's details or information about him to a third party.
3.7 In any case of suspected fraud or security issues.
3.8 Third parties may collect, process, store, and share your information similar to those mentioned above, but these actions are subject to the terms of use and privacy policies of those third parties. Therefore, the Company is not responsible for the actions described above or for the privacy policies and terms of use that apply to these actions performed by third parties.

3.9 The user may notify the Company by email that he revokes any previous consent given to the Company for the use of his personal information, as detailed in the privacy policy, and in this case, the Company will cease to use the personal information and provide services.

4. Information Security

The Company operates according to industry-standard regulations to secure the information of all its users. However, there are no electronic means on the Internet that can secure the information stored on the Company's servers 100%. Therefore, the Company implements rules and regulations to protect your personal information. The Company cannot guarantee that the information in its servers will not be lost, damaged, deleted, breached, or disclosed.

5. Data Retention

5.1 Information about you is stored in databases managed by the Company and/or by a third party on its behalf, subject to the Privacy Protection (Information Security) Regulations, 2017.
5.2 The databases may be located outside the borders of the State of Israel. By using the system, the user agrees to the transfer and storage of his information outside the borders of Israel.

6. Direct Mailing and Advertising

6.1 The Company may contact you and offer you benefits, advertisements, and/or other information and/or contact through direct mailing as defined in Section 17(c) of the Privacy Protection Law, 1981, and/or advertisements as defined in Section 30A(b) of the Communications (Bezeq and Services) Law, 1982.
6.2 If the Company sends direct mail or advertisements, it may instruct the Company to remove it from our mailing lists by contacting the Company at the address specified in the mailing and/or advertisement.

7. Updates and Amendments to the Policy

7.1 The policy may change from time to time, and it is your responsibility to stay updated on the latest and binding version published on the PRC system. Your continued use of the system, after changes to the system as stated above, constitutes your consent to these amendments to the privacy policy and your commitment to the terms formulated as part of these amendments.

8. Cookies

8.1 For the smooth and proper operation of the PRC system, including the collection of statistical data about usage and verification of details, the Company may use "cookies" (text files created by your browser on command from system computers).
8.2 The system uses cookies for several key purposes:
8.3 Essential cookies: for the proper operation of the system, including identifying the user when entering and/or registering with the system. These cookies are necessary for the proper functioning of the system.
8.4 Performance tracking cookies: for collecting anonymous, statistical information about user activity in the system (such as information about the most viewed pages, time spent on each page) for the improvement of the system.
8.5 Functional cookies (preferences): for customizing default preferences for user preferences (such as choosing interface language, passwords, registration details). Without these cookies, the functionality of the system may be impaired.
8.6 You can change your browser settings to prevent the PRC system from using cookies. However, blocking the use of some or all cookies may not allow for proper browsing and use of the system.`,
    },
    reports: {
      reportsList: 'Reports list',
      history: 'History',
      downloadReport: 'Download report',
      companyName: 'Company name',
      chooseCompany: 'Choose company',
      dateRange: 'Date range',
      date: 'Date',
      employeeName: 'Employee name',
      overview: `This report aggregates daily reports regarding task completion in the system,
         related comments, and attached files. The report displays information about the
         status of each task at the selected date. The report is saved and locked on a
         daily basis and cannot be edited by any party.`,
    },
    tasklists: {
      lists: 'Lists',
      addList: 'Add list',
      trustee: 'Trustee',
      employee: 'Employee',
      endDate: 'End date',
      tasks: 'Tasks',
      unassigned: 'Unassigned',
      deleteTasksList: 'Delete tasks list',
      deleteWarning: 'The info cannot be recovered after deletion',
      cancel: 'Cancel',
      delete: 'Delete',
    },
    tasksForm: {
      nextReset: 'The task will reset on',
      deleteFile: 'Delete file',
      deleteWarning: 'The info cannot be recovered after deletion',
      cancel: 'Cancel',
      delete: 'Delete',
      approve: 'Approve',
      error: 'Error',
      tasksApproval: 'Tasks approval',
      appreciation: 'We appreciate your understanding and commitment.',
      comprehension: 'I read carefully and understood everything I confirmed',
      readAndConfirm: 'Please read the tasks and confirm them',
      success: 'Success',
      tasksSent: 'The tasks were sent successfully',
      tryAgainLater: 'An error occurred, please try again later',
      addComments: 'Add comment and details',
      enterComment: 'Enter comment',
      save: 'Save',
      planningExecution: 'Planning v execution',
      myTasks: 'My tasks',
      formBelongs: 'This form belongs',
      addFile: 'Add file',
      additionalDetails: 'Additional details',
      file: 'File',
      download: 'Download',
      comments: 'Comments',
      noTasks: 'You have no tasks today',
    },
    termsOfUse: {
      proceed: 'Proceed',
      termsAndCopyright: 'Terms of use and copyright',
      readAndApprove: 'I have read and agree to the terms of use',
      contents: `Definitions:

The company - SBY company including its managers, employees, agents on its behalf and company directors.
The user - the customer, including the company that purchased the product/service, the customer's employees and/or anyone on its behalf.
Third party - user of the product who is not a customer of the company directly. The product - the PRC system will also be called "the website" and/or "the service".
Content - the information, presentation documents and images appearing in the product.
Law - the laws of the State of Israel.
Copyright - the rights to the product.
Use license - the use of the product is conditional on receiving a time-limited license from the company.

Agreement:

The user's agreement to the terms and conditions listed below constitutes a binding legal agreement concluded between the user (whether personally or on behalf of an entity/company) and the company regarding the user's access and use of the product - the PRC system as well as any other media form, media channel, website, mobile or application to the mobile associated, linked or otherwise connected to it.
You agree that by accessing the product you have read, understood and agreed to be bound by all of these terms and conditions. If you do not agree to all of these terms and conditions, you are expressly prohibited from using the product and must stop using it immediately.
The terms below apply to any engagement between the company and the user regarding access, use of the product, product content and product copyrights and the use of the product constitutes your consent to all the terms detailed below.

Copyright:

Unless otherwise stated, the product and all source code, databases, functionality, software, product design, text, paragraphs, audio, video, images, and graphics, herein referred to as the "Content," as well as the trademarks, service marks, and logos, herein referred to as the "Marks," are owned and/or under the sole control of the company and protected by copyright, trademark laws, various intellectual property rights, and unfair competition laws in Israel and around the world. No use of any of these may be made without prior written approval from the company.
The content and information in the system are provided as they are ("As-Is") and are for your information and personal use only, except as expressly stated in these terms and conditions.
Do not copy, reproduce, aggregate, republish, upload, post, publicly display, encode, translate, transmit, distribute, sell, license, or use any part of the website or content or marks for any commercial purpose whatsoever, without express prior written permission from the company.
If you are entitled to use the Product, you are granted a time-limited license to access the Product, use it, and download or print a copy of any portion of the Content accessed by you solely for your personal, non-commercial use.

Terms of Use:

By using the website, you declare and undertake:
1. That all the information you upload to the product is legal, correct, and complete.
2. That all the registration details you send are correct, accurate, current, and complete.
3. You have the legal qualifications and legal authority to agree and comply with the terms of this agreement.
4. You are not a minor according to the jurisdiction where you live.
5. You will not access the Service using automated or non-human tools whether through a bot, script, or otherwise.
6. You will not use the product for any illegal or unauthorized purpose.
7. Your use of the product will not violate any law or regulation.

If you provide any information that is false, inaccurate, not current, and/or incomplete, the Company has the right to suspend or cancel your account and refuse any current or future use of the Product or any part thereof.
You may be required to register for the system. You agree to keep your password confidential and will be responsible for all use of your account and password. The Company reserves the right to remove, reclaim, or change a username you have chosen if it is determined, in its sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.

Termination of product use:

These terms and conditions will remain in effect as long as you use the site. Without limiting any other provision of these Terms and Conditions, the Company reserves the right, in its sole discretion and without notice or liability, to deny access to and use of the Product (including blocking a particular IP address) for any reason whatsoever, including, without limitation, for breach of any representation, warranty, or covenant contained in these terms and conditions or of any applicable law or regulation. The Company may terminate your use or participation in the Site or delete your account and any content or information you have posted at any time, without warning, at its sole discretion.
In addition to terminating or suspending your account, the Company reserves the right to take appropriate legal action, including, without limitation, civil, criminal, or injunction action.

Changes:

The company reserves the right to change and/or remove the website content at any time and for any reason at its sole discretion without prior notice. However, the company has no obligation to update any information on its website. Also, the company reserves the right to change and/or stop using the product or part of it without prior notice at any time. The company will not be liable to the user and/or to any third party for any change, price change, suspension, or discontinuance of the product.
The company cannot guarantee that the website will be available at all times. The Company may encounter hardware, software, or other problems or the need to perform maintenance related to the Website, resulting in interruptions, delays, or errors. The company reserves the right to change, update, suspend, stop, or otherwise change the product/system at any time and for any reason without notice to the user. The user agrees that the company has no responsibility whatsoever for any loss, damage, or inconvenience caused by his inability to access or use the website during any downtime or interruption of the website's activity. Nothing in these Terms and Conditions shall be construed as requiring the Company to maintain and support the Product or to provide any corrections, updates, or releases in connection therewith.

Disclaimer:

The use of the PRC product and the services accompanying it is for a legal purpose only and within the limits imposed by law and the sole responsibility of the user. The company will not have any direct and/or indirect liability in connection with the product and the user's use of the product, presentations, and content, for the accuracy and completeness of the content displayed in the system, including, without limitation, for damages and/or losses that will be caused to the user and/or a third party as a result of using the product. Without detracting from the above, the company will not be responsible for any:
1. Fault
2. For any use of a product that is illegal, including use that constitutes copyright infringement.
3. For the correctness of the information uploaded by the users of the product and/or the completeness of the information in the product and/or the completeness of the information on websites/documents linked to the product and will not be held responsible for mistakes, errors, and inaccuracies in the content uploaded and appearing in the product and any damage that may be caused to the user and/or to a third party resulting from use of the website.
4. Any unauthorized access to the product and/or to the company's servers and to all personal and/or financial information stored in the product.
5. Any interruption or interruption of transmission to or from the website.
6. For bugs, viruses, Trojan horses, or any other malicious software that may be transmitted to or through the system by any third party.
7. Any error or omission in any content and materials or for any loss or damage of any kind caused as a result of the use of any content posted, transmitted, or otherwise available through the system.
8. For the presentations presented on the website, for the accuracy or completeness of the presentations and/or the content of any websites linked to the website/any information/documents on the website/other content on the website.
9. For damage and/or loss caused to users and/or a third party as a result of inaccuracy/completeness of the content and/or presentations uploaded to the product.
10. For any malfunction and/or damage of any kind caused as a result of the user's access to the website and use of the product.
11. For content and/or services uploaded to the product by a third party, including links to other websites and services, and/or connections to mobile and other applications.
12. The company will not be a party in any way and will not be responsible for monitoring and managing any activity between the customer and third parties, including services and products of third parties.
The product and its use are not a substitute for legal advice.

Limitation of Liability:

In no case will the company, including its managers, employees, agents, and board of directors, be liable to the user and/or to any third party for any direct and/or indirect damage, loss of data, or other damages arising from the use of the website, even if the company has informed you of the possibility of such damages. Despite everything stated in this document, the company's liability towards a user will be limited at any time to the amount paid by the user to the company.

Indemnity:

You agree to indemnify the company, its employees, and officers from and against any loss, damage, liability, claim, or demand including fees and expenses for any damage caused to the company by you or by a third party due to or resulting from:
1. Use of the product.
2. Violation of these terms and conditions.
3. Any breach of the representations and your responsibilities set forth in these terms and conditions.
4. Infringement of third party rights including but not limited to intellectual property rights.
5. Any harmful act towards another user of the product with whom you connected through the use of the system.

Notwithstanding the above, the company reserves the right to assume, at the user's expense, the sole protection and control of any matter for which you are required to indemnify the company or someone on its behalf, and you undertake to cooperate with the company at your own expense. The Company will make reasonable efforts to notify you of a claim, action, or proceeding that is subject to indemnification at the time the Company is notified thereof.

Saving Information:

The company will save certain data that the user transfers to the system for the purpose of managing the system's performance, as well as data relating to the user's use of the website. Although the company performs regular backups of data, the user is solely responsible for all the data he transfers or related to any activity he performed using the system. The user agrees that the company shall not be liable to him for any loss or corruption of any such information, and he hereby waives any right of claim against the company arising from any such loss or corruption of such data.

Jurisdiction:

These terms will be subject to and defined in accordance with the laws of Israel. The company and the user irrevocably agree that the Israeli courts will have exclusive jurisdiction to decide any dispute that may arise in connection with these terms. It is agreed that the local jurisdiction will be the courts in Tel Aviv.

Other:

These regulations and any policy or operating rules published by us on the system or in relation to the system constitute the agreement between the user and the company. The company's failure to exercise or enforce any right or provision of these terms and conditions shall not act as a waiver of said right or provision. These terms and conditions operate to the fullest extent permitted by law. The company may transfer all its rights and obligations to others at any time. The company will not be responsible and will not bear liability for any loss, damage, delay, or inaction caused by any reason beyond its reasonable control. If any provision or part of a provision in these terms and conditions is determined to be illegal, void, or unenforceable, that provision or part of the provision is deemed severable from these terms and conditions and does not affect the validity and enforceability of all other provisions.
No joint venture, partnership, employment, or agency relationship was created between the company and the customer as a result of these terms and conditions or use of the website. The user agrees that these terms and conditions will not be interpreted against the company by virtue of their editing. The user hereby waives all protections available to him based on the electronic form of these terms and conditions and the lack of signature by the parties on the execution of these terms and conditions.
The company will not be held responsible for any legal action taken against the user in cases of illegal acts, by the authorities, or any third party.

SBY Privacy Policy

This privacy policy (hereinafter referred to as the "Policy") describes to the user (hereinafter referred to as the "User") the method of collection, use, and sharing of personal information provided by you and/or collected from you through the PRC system (hereinafter referred to as the "System" and/or "PRC") and/or through you entering your details in the system and/or information that you choose to share with SBY Ltd. ("the company"), all in accordance with the policy below. The policy is an integral part of the site's terms of use. The policy is written in masculine language for convenience only and is intended for both women and men. We recommend that you read the privacy policy carefully, and if you do not agree to the terms of the policy, please refrain from using the PRC system. Your continued use will constitute your agreement to the usage policy.
1. Local Laws and Regulations
1.1 The purpose of this privacy policy is to comply with the laws and regulations that apply in each country from which anonymous and personal information is collected, as described in this privacy policy, including the Privacy Protection Act 1981 (hereinafter referred to as "the Act") and the Privacy Protection (Information Security) Regulations, the nine G-2017 (hereinafter referred to as "Regulations").
2. Information Collected by the Company and Its Use
2.1 Collected Anonymous Information: When using the system, the company may collect information related to the use of the system that does not allow the identification of the individual and/or the disclosure of the identity of the users, such as aggregated information and analysis of usage patterns (hereinafter referred to as "anonymous information") for the purpose of improving services and enriching content of the system, its quality, and the nature of the services provided to users of the system by the company, as well as for the use of the information collected for the purposes of performing statistics, conducting research, and conducting anonymous analyses. The company may collect, use, and publish anonymous information, and disclose it to third parties, for the purpose of providing, improving, and advertising the company's system and services. Anonymous information may be permanently stored in the company's archives for future use.
2.2 Collected Personal Information: This information enables the identification of the user fully or with reasonable effort by adding additional information (hereinafter referred to as "personal information"). The company may collect personal information that you enter and provide in the system (such as full name, identity number, e-mail address, date of birth, etc.).
2.3 Use of Personal Information: The company collects personal information for the following purposes (all or some):
2.4 In order to provide the services provided to the user by the company within the framework of the system and/or use and/or improve the services in the system.
2.5 To support and provide answers to users' questions and communication with the user of the system.
2.6 To identify the user when contacting the company and/or someone on its behalf.
2.7 For customer relations, including communication with the aim of updating the users regarding the latest updates of the company as well as providing the users with information regarding the content that may be of interest to the users.
2.8 Technical information from the user's mobile device and/or computer to prevent fraud.
2.9 In order to comply with the provisions of the law, regulatory obligations, and requirements of a government authority or legal order. 2.10 For any other purpose specified in the policy or terms of use of any of the relevant services in the system.
2.11 The user undertakes to provide only correct, accurate, and complete details and hereby confirms the correctness of the details provided by him. It should be clarified that the user is under no obligation to provide any information to the company through the system; however, if the information is not provided, the company may or may not be able to provide services (all or part of them) to that user.
2.12 As the company requests to add uses and/or collect additional personal information, it will request the user's consent in advance; however, if this approval is not given, the company may not be able to provide services (all or part of them) to that user.
2.13 Databases: The user declares that he is aware that information provided by the user is collected by the company as specified in this policy, accumulated in the company's databases and the company's database and/or in the database of service providers on its behalf and are available for review by any user who has registered for the company's services and paid to be a subscriber to the bathroom.
2.14 The user knows that the company will hold, manage, and use the information in the database for the purpose of promoting its operations, including its operations with other entities, and the user agrees to this and declares that he does not and will not have any claim against the company and/or anyone on its behalf as a result, provided that the use of the information is in accordance with policy instructions This privacy.
3. Sharing Information with Third Parties. The company transfers information to third parties only in the cases detailed below:
3.1 As long as the user has given his express consent.
3.2 To the state authorities in accordance with the powers granted to them by law.
3.3 Personal information will be transferred by the company to related companies including subsidiaries, joint ventures, or other companies under common control or businesses that manage a business related to the services.
3.4 In the event of a legal dispute between the user and the company that would require disclosure of the user's information.
3.5 If the user performs actions in the system that are contrary to the law and/or in a way that will harm its proper functioning.
3.6 If a judicial order is obtained ordering the disclosure of the user's details or information about him to a third party.
3.7 In any case of suspected fraud or security issues.
3.8 Third parties may collect information and/or process and/or save and/or share your information as mentioned above; however, these actions are carried out subject to the terms of use and privacy policies of those third parties. Therefore, the company is not responsible for such actions and/or privacy policies and terms of use that apply to these actions carried out by third parties.
3.9 The user may notify the company in an email circular that he cancels any previous consent given to the company by him for the use of his personal information, as detailed in the privacy policy, and in this case, the company will cease to use the personal information and provide the services.
4. Information Security. The company operates according to procedures accepted in the industry for the purpose of securing the information of all its users. At the same time, there are no electronic means on the Internet that can 100% secure the information stored on the company's servers. In light of the above, the company implements rules and procedures for the purpose of protecting your personal information. A company cannot guarantee that the information on its servers will not be lost/damaged/destroyed/hacked/discovered.
5. Saving Information
5.1 Your information is stored in databases managed by the company and/or by a third party on its behalf subject to privacy protection regulations.
5.2 The databases may be located outside the borders of the State of Israel. By actually using the system, the user agrees to the transfer of his information and its storage outside the borders of Israel.
6. Direct Mail and Advertisement
6.1 The company may contact you and offer you benefits, advertising material, and/or other information and/or contact via direct mail as defined in section 17 C of the Privacy Protection Law, 5711 - 1981, and/or advertising material as defined in section 30a (b) of the law on Communication (Bezeq and services) 1982.
6.2 To the extent that the company carries out such mailing or advertising, you will be entitled to request removal from our mailing lists by contacting the company at the address specified in the mailing and/or advertisement.
7. Updates and Amendments to the Policy
7.1 The policy may change from time to time, and it is your responsibility to update yourself with the latest and binding version published in the PRC system. Your continued use of the system after changes to the system, as mentioned, hereby constitutes your agreement to these amendments to the privacy policy and your agreement to be bound by the terms formulated as part of these amendments.
8. Cookies
8.1 For the purpose of the regular and proper operation of the PRC system, including collecting statistical data about usage and verifying details, the company may make use of "cookies" (text files that your browser creates according to a command from the system's computers).
8.2 The system uses cookies for several main purposes:
8.3 Essential cookies: for the proper operation of the system, including identifying the user when he enters and/or registers in the system. These cookies are necessary for the proper operation of the system.
8.4 Performance tracking cookies: to collect anonymous, statistical information about the user's activity in the system (such as information on the most viewed pages, dwell times on each page) to improve the system.
8.5 Functional cookies (preferences): to adjust the default to the user's preferences (such as choosing the interface language, passwords, registration details). Without these cookies, the functionality of the system may be impaired.
8.6 You can change the browser settings and thus not allow the PRC system to manage cookies. However, it is likely that blocking the use of some or all of the cookies will not allow for proper browsing and use of the system.`,
    },
  },
};

export default english;
