export function TaskListIcon(props: any) {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `scale(${props.scale ? props.scale : 1})`,
      }}
    >
      <path
        d="M3.375 0.6875C1.82156 0.6875 0.5625 1.94656 0.5625 3.5V18.5C0.5625 20.0534 1.82156 21.3125 3.375 21.3125H14.625C16.1784 21.3125 17.4375 20.0534 17.4375 18.5V9.43262C17.4375 8.68637 17.1409 7.97144 16.6135 7.44409L10.6809 1.51147C10.1531 0.98413 9.43816 0.6875 8.69238 0.6875H3.375ZM3.375 2.5625H8.0625V7.25C8.0625 8.80344 9.32156 10.0625 10.875 10.0625H15.5625V18.5C15.5625 19.018 15.143 19.4375 14.625 19.4375H3.375C2.85703 19.4375 2.4375 19.018 2.4375 18.5V3.5C2.4375 2.98203 2.85703 2.5625 3.375 2.5625ZM9.9375 3.41943L14.7056 8.1875H10.875C10.357 8.1875 9.9375 7.76797 9.9375 7.25V3.41943ZM5.25 11.9375C4.7325 11.9375 4.3125 12.357 4.3125 12.875C4.3125 13.393 4.7325 13.8125 5.25 13.8125H12.75C13.2675 13.8125 13.6875 13.393 13.6875 12.875C13.6875 12.357 13.2675 11.9375 12.75 11.9375H5.25ZM5.25 15.6875C4.7325 15.6875 4.3125 16.107 4.3125 16.625C4.3125 17.143 4.7325 17.5625 5.25 17.5625H12.75C13.2675 17.5625 13.6875 17.143 13.6875 16.625C13.6875 16.107 13.2675 15.6875 12.75 15.6875H5.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
