import axios from 'axios';
import { appConfig } from '../configs/app';

class risksFilesService {
  static async uploadFile(file: File, companyId: string, riskId: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('companyId', companyId);
    formData.append('riskId', riskId);

    const response = await axios.post(
      `${appConfig.apiUrl}/company/uploadRiskFile`,
      formData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
    const data = response.data;
    return data;
  }

  static async deleteRiskFile(riskId: string, companyId: string) {
    const response = await axios.delete(
      `${appConfig.apiUrl}/company/riskFile?riskId=${riskId}&companyId=${companyId}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
    const data = response.data;
    return data;
  }

  static async restartAssistant(companyId: string) {
    const response = await axios.post(
      `${appConfig.apiUrl}/company/restartAssistant`,
      { companyId },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
    const data = response.data;
    return data;
  }
}

export { risksFilesService };
