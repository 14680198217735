export function NewsVector() {
  return (
    <svg
      width="450"
      height="167"
      viewBox="0 0 450 167"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M307.724 -195.53C216.063 -231.433 200.583 -347.269 142.39 -382.948C85.3282 -417.929 30.5921 -369.693 16.8405 -250.135C1.21244 -114.271 -62.4375 -76.4704 -90.1047 25.8024C-101.741 68.8177 -89.1654 146.281 -48.2303 162.517C5.53342 183.839 51.264 116.924 113.849 88.2626C170.02 62.5356 251.656 68.653 305.631 57.186C358.517 45.951 415.981 48.4195 443.584 -24.072C461.867 -72.079 445.157 -141.701 307.726 -195.529L307.724 -195.53Z"
          fill="#3FB2D2"
        />
      </g>
    </svg>
  );
}
