import { useId } from "@chakra-ui/react";
import styles from "../assets/style/modules/switch.module.scss";

type PrivateProps = {
  isCheck: boolean;
  toggleIsCheck: (value: any) => void;
  children: React.ReactNode;
  isDisabled?: boolean;
};

export function Switch({
  isCheck,
  toggleIsCheck,
  children,
  isDisabled = false,
}: PrivateProps) {
  const id = useId();

  return (
    <div className="switch">
      <input
        disabled={isDisabled}
        type="checkbox"
        id={id}
        checked={isCheck}
        onChange={toggleIsCheck}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
}
