import axios from 'axios';
import { appConfig } from '../configs/app';

class OnboardingAnswersService {
  static async getOnboardingAnswers(onboardingId: string) {
    try {
      const response = await axios.get(
        `${appConfig.apiUrl}/onboarding-answer/questionnaire/${onboardingId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );
      return response.data.answers;
    } catch (error) {
      return null;
    }
  }

  static async getCompanyOnboardingAnswers(companyId: string) {
    try {
      const response = await axios.get(
        `${appConfig.apiUrl}/onboarding-answer/company/${companyId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      );
      return response.data.answers;
    } catch (error) {
      return null;
    }
  }
}

export default OnboardingAnswersService;
