import { Box, Container, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { NewsVector } from "../../assets/NewsVector";
import newsHeader from "../../assets/newsHeader.png";
import style from "../../assets/style.json";
import bg from "../../assets/bg.png";
import { Logo } from "../../components/Footer/styleComponent/Logo";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const [isPc] = useMediaQuery("(min-width: 1300px)");
  const { pathname } = useLocation();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setIsShow(!pathname.includes("p-privacy-policy"));
  }, [pathname]);

  return (
    <>
      <Box position="absolute" width={"100%"} height="16px" bg="#3FB2D2"></Box>
      {isShow && (
        <Container
          backgroundColor={true ? "" : "#20273d"}
          backgroundPosition="center"
          backgroundImage={bg}
          width={"100%"}
          height={"400px"}
          maxW={"100%"}
          position="absolute"
          display="flex"
          justifyContent={"center"}
        >
          <Box marginTop={"60px"}>
            <Logo isShowStand={false} isDark={true} />
          </Box>
        </Container>
      )}
      <Container
        position={"absolute"}
        top="200px"
        maxW="full"
        minH="100vh"
        padding={isPc ? "unset" : 0}
        bg="#f4fbff"
        centerContent
      >
        <Box
          position={"relative"}
          width="full"
          rounded={"10px"}
          maxW={style["pc-view"]}
          bg={isPc ? "white" : "#f4fbff"}
          minHeight={"300px"}
          boxShadow={isPc ? "0px 0px 25px rgba(62, 62, 62, 0.08)" : "unset"}
          borderTop={isPc ? "9px solid #20273D" : "unset"}
          display={"flex"}
          flexDirection="column"
          paddingBlock={"20px"}
          paddingInline="30px"
          paddingInlineEnd={isPc ? "200px" : "30px"}
        >
          {isPc && (
            <>
              <Box position={"absolute"} left="0" top="0">
                <NewsVector />
              </Box>

              <Image
                src={newsHeader}
                position="absolute"
                left={"34px"}
                top={0}
              />
            </>
          )}
          <Text dir="rtl" mb={16} fontWeight="bold" fontSize={30}>
            {t('privacyPolicy.title')}
          </Text>
          <Text>
            <pre dir="rtl" style={{ whiteSpace: 'pre-wrap' }}>
              {t('privacyPolicy.conditions')}
            </pre>
          </Text>
          <Box mt={"30px"}>
            <pre dir="rtl" style={{ whiteSpace: 'pre-wrap' }}>
              {t('privacyPolicy.contents')}
            </pre>
          </Box>
        </Box>
      </Container>
    </>
  );
};
export default PrivacyPolicy;
