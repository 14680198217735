export function CirclePlusIcon(props: any) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0.9375C6.78594 0.9375 0.9375 6.78594 0.9375 14C0.9375 21.2141 6.78594 27.0625 14 27.0625C21.2141 27.0625 27.0625 21.2141 27.0625 14C27.0625 6.78594 21.2141 0.9375 14 0.9375ZM14 3.3125C19.9025 3.3125 24.6875 8.09753 24.6875 14C24.6875 19.9025 19.9025 24.6875 14 24.6875C8.09753 24.6875 3.3125 19.9025 3.3125 14C3.3125 8.09753 8.09753 3.3125 14 3.3125ZM14 8.0625C13.3439 8.0625 12.8125 8.5945 12.8125 9.25V12.8125H9.25C8.5945 12.8125 8.0625 13.3439 8.0625 14C8.0625 14.6561 8.5945 15.1875 9.25 15.1875H12.8125V18.75C12.8125 19.4055 13.3439 19.9375 14 19.9375C14.6561 19.9375 15.1875 19.4055 15.1875 18.75V15.1875H18.75C19.4055 15.1875 19.9375 14.6561 19.9375 14C19.9375 13.3439 19.4055 12.8125 18.75 12.8125H15.1875V9.25C15.1875 8.5945 14.6561 8.0625 14 8.0625Z"
        fill="currentColor"
      />
    </svg>
  );
}
