import en from './en';
import he from './he';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from './LanguageDetector';

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translations: en.translation,
      },
      he: {
        translations: he.translation,
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
  });

i18n.languages = ['en', 'he'];

export default i18n;
