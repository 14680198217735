import {
  Box,
  Button,
  Container,
  SlideFade,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import CompanyService from '../../services/company-service';
import TasklistService from '../../services/tasklist-service';
import UserService from '../../services/user-service';
import style from '../../assets/style.json';
import { CirclePlusIcon } from '../../assets/CirclePlusIcon';
import { TrashIcon } from '../../assets/TrashIcon';
import { EditTaskIcon } from '../../assets/EditTaskIcon';
import { useTranslation } from 'react-i18next';

const Tasklists = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [tasklists, setTasklists] = useState<any[]>([]);
  const [company, setCompany] = useState<any>({});
  const [user, setUser] = useState<any>({});

  const load = async () => {
    setLoading(true);
    const companyId = searchParams.get('company_id');
    if (companyId) {
      const res = await TasklistService.listCompany(companyId);

      setTasklists(res);
      const currCompany = await CompanyService.read(companyId);
      setCompany(currCompany);
      setLoading(false);
      return;
    }
    const res = await TasklistService.list(id);
    const currUser = await UserService.info(id);
    const currCompany = await CompanyService.read(currUser.companyId);
    setCompany(currCompany);

    setUser(currUser);
    setTasklists(res);
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  const getEndDate = (tasklist: any) => {
    return tasklist.list.reduce((acc: any, curr: any) => {
      const endDate = new Date(curr.interval.end);
      return endDate > acc ? endDate : acc;
    }, new Date(0));
  };

  console.log({ tasklists, company, employees: company.employees });

  return (
    //@ts-ignore
    <Container
      maxW="full"
      minH="100vh"
      py={20}
      position="absolute"
      top={'90px'}
      centerContent
    >
      <SlideFade in>
        <Text
          color={'white'}
          dir="rtl"
          marginBottom={'77px'}
          fontWeight={500}
          fontSize={32}
        >
          {t('tasklists.lists')}
        </Text>
      </SlideFade>
      <Box
        overflowX="auto"
        w="90vw"
        maxW={style['pc-view']}
        boxShadow="0px 0px 25px rgba(62, 62, 62, 0.08)"
        bg="#fff"
        p={'20px'}
        rounded="10px"
      >
        {!!loading && (
          <Box
            display="flex"
            minH={500}
            alignItems="center"
            justifyContent="center"
          >
            <div className="lds-default">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </Box>
        )}
        {!loading && (
          <>
            {!!searchParams.get('company_id') && (
              <Button
                onClick={() =>
                  navigate(
                    `/admin/tasks?company_id=${searchParams.get('company_id')}`,
                  )
                }
                minW={'188px'}
                textAlign="center"
                fontSize="18px"
                fontWeight={700}
                rounded={'100px'}
                borderWidth={'1.5px'}
                borderColor={style['primary-color']}
                bg={'#fff'}
                color={style['primary-color']}
                marginBottom="30px"
              >
                <Box gap={'17px'} display={'flex'} alignItems={'center'}>
                  <Text>{t('tasklists.addList')}</Text>
                  <CirclePlusIcon />
                </Box>
              </Button>
            )}

            <Text
              fontSize={'18px'}
              fontWeight={400}
              color="#475154"
              textAlign={'center'}
              marginBottom="60px"
            >
              {company.name}
            </Text>
            <Box overflowX={'auto'}>
              <SlideFade in>
                <Table>
                  <Thead borderBottom={'1.5px solid #DFDFDF'}>
                    <Tr>
                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                        position="sticky"
                        right={'0'}
                        zIndex={5}
                        bg={'#fff'}
                      >
                        {t('tasklists.trustee')}
                      </Th>

                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                      >
                        {t('tasklists.employee')}
                      </Th>
                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                      >
                        {t('tasklists.endDate')}
                      </Th>
                      <Th
                        fontSize={'16px'}
                        fontWeight={700}
                        lineHeight={'29px'}
                        paddingBottom="12px"
                        textAlign="start"
                      >
                        {t('tasklists.tasks')}
                      </Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tasklists.map((tasklist: any, i: number) => {
                      const roleId = tasklist?.assigneeId;
                      const role = company?.roles?.find(
                        (roleItem: any) => roleItem.id === roleId,
                      );
                      const roleText = role?.name || t('tasklists.unassigned');

                      const employees = company?.employees
                        .filter((e: any) => e.isActive)
                        .filter((employee: any) =>
                          employee.roleIds.includes(roleId),
                        );

                      const employeeText = employees
                        .map((employee: any) => employee.name)
                        .join(', ');

                      return (
                        <Tr
                          key={'tasklist-' + tasklist.id + i}
                          rounded={'10px'}
                          border={'none'}
                          position="relative"
                        >
                          <Td
                            onClick={() =>
                              navigate(
                                `/tasks/${tasklist.id}${
                                  !!id ? `?user_id=${id}` : ''
                                }`,
                              )
                            }
                            cursor="pointer"
                            color="#3FB2D2"
                            roundedRight={'10px'}
                            bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
                            border={'none'}
                            textAlign="start"
                            position="sticky"
                            right={'0'}
                            zIndex={5}
                          >
                            {roleText}
                          </Td>
                          <Td
                            bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
                            border={'none'}
                            textAlign="start"
                          >
                            {employeeText}
                          </Td>
                          <Td
                            bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
                            border={'none'}
                            textAlign="start"
                          >
                            {getEndDate(tasklist).toLocaleDateString()}
                          </Td>
                          <Td
                            bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
                            border={'none'}
                            textAlign="start"
                          >
                            {tasklist.checked || 0}/{tasklist.total}
                            {!searchParams.get('company_id') && (
                              <Text
                                fontSize={10}
                                color={
                                  (tasklist.checked || 0) ===
                                  (tasklist.total || 0)
                                    ? '#3FB2D2'
                                    : '#E55353'
                                }
                              >
                                {(tasklist.checked || 0) ===
                                (tasklist.total || 0)
                                  ? 'Complete'
                                  : 'Incomplete'}
                              </Text>
                            )}
                          </Td>

                          <Td
                            bg={i % 2 === 0 ? 'white' : '#F5F8FA'}
                            border={'none'}
                            textAlign="start"
                            roundedLeft={'10px'}
                            width="150px"
                            minWidth="150px"
                          >
                            {!!searchParams.get('company_id') && (
                              <>
                                <Button
                                  color={'#3FB2D2'}
                                  bg="transparent"
                                  onClick={() =>
                                    navigate(
                                      `/admin/tasks?company_id=${searchParams.get(
                                        'company_id',
                                      )}&copyFrom=${tasklist.id}`,
                                    )
                                  }
                                >
                                  <EditTaskIcon />
                                </Button>
                                <Button
                                  color={'#3FB2D2'}
                                  bg="transparent"
                                  onClick={async () => {
                                    const result = await Swal.fire({
                                      title:
                                        t('tasklists.deleteTasksList') +
                                        ': ' +
                                        tasklist.title +
                                        '?',
                                      text: t('tasklists.deleteWarning'),
                                      showCancelButton: true,
                                      confirmButtonColor: '#3085d6',
                                      cancelButtonColor: '#d33',
                                      cancelButtonText: t('tasklists.cancel'),
                                      confirmButtonText: t('tasklists.delete'),
                                    });
                                    if (result.isConfirmed) {
                                      await TasklistService.delete(tasklist.id);
                                      load();
                                    }
                                  }}
                                >
                                  <TrashIcon />
                                </Button>
                              </>
                            )}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </SlideFade>
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Tasklists;
