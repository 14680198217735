import axios from 'axios';
import { appConfig } from '../configs/app';
import * as xlsx from 'xlsx';

class SanctionsService {
  static async setAmerica(file: File) {
    const arr = (await this.fileToArr(file)) as any;
    const response = await axios.post(
      `${appConfig.apiUrl}/sanctions/america`,
      {
        names: arr.map((item: any) => item['name']),
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
  }

  static async setInterpol(file: File) {
    const arr = (await this.fileToArr(file)) as any;
    console.log(arr);

    const response = await axios.post(
      `${appConfig.apiUrl}/sanctions/interpol`,
      {
        names: arr.map((item: any) => item['name']),
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
  }

  static async setEurope(file: File) {
    const arr = (await this.fileToArr(file)) as any;

    const response = await axios.post(
      `${appConfig.apiUrl}/sanctions/europe`,
      {
        names: arr.map((item: any) => item['NameAlias_WholeName']),
      },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
  }

  private static async fileToArr(file: File) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (e) => {
        try {
          if (!e || !e.target || !e.target.result)
            throw new Error('File not found');
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const array = xlsx.utils.sheet_to_json(worksheet, {
            dateNF: 'dd/mm/yyyy',
          });
          resolve(array);
        } catch (e) {
          reject(e);
        }
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  static async getByQuery(query: string) {
    const response = await axios.get(
      `${appConfig.apiUrl}/sanctions?query=${query}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    );
    return response.data;
  }
}

export { SanctionsService };
