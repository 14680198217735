export function DownloadIcon(props: any) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2111 2.69824C9.88922 2.69824 9.62799 2.95948 9.62799 3.28135V11.5047L7.70896 9.57601C7.48183 9.34772 7.11269 9.34602 6.8844 9.57373C6.6564 9.80085 6.655 10.17 6.88212 10.3983L9.79768 13.3287C9.90731 13.4386 10.056 13.5006 10.2111 13.5006C10.3662 13.5006 10.5149 13.4389 10.6245 13.3287L13.5401 10.3983C13.7672 10.17 13.7658 9.80085 13.5378 9.57373C13.3095 9.34632 12.9404 9.34801 12.7132 9.57601L10.7942 11.5047V3.28135C10.7942 2.95948 10.533 2.69824 10.2111 2.69824ZM3.50531 11.4449C3.18343 11.4449 2.9222 11.7062 2.9222 12.028V14.3605C2.9222 15.3252 3.70677 16.1098 4.67153 16.1098H15.7507C16.7154 16.1098 17.5 15.3252 17.5 14.3605V12.028C17.5 11.7062 17.2388 11.4449 16.9169 11.4449C16.595 11.4449 16.3338 11.7062 16.3338 12.028V14.3605C16.3338 14.6821 16.0723 14.9436 15.7507 14.9436H4.67153C4.34995 14.9436 4.08842 14.6821 4.08842 14.3605V12.028C4.08842 11.7062 3.82719 11.4449 3.50531 11.4449Z"
        fill="currentColor"
      />
    </svg>
  );
}
