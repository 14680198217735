import { Box, useMediaQuery } from "@chakra-ui/react";
import style from "../../../assets/style.json";

export function DashboardBox({
  children,
  order,
}: {
  children: any;
  order: any;
}) {
  const [isPc] = useMediaQuery("(min-width: 1300px)");
  return (
    <Box
      w="100%"
      height={"100%"}
      bg="#fff"
      display="flex"
      alignItems="center"
      position="relative"
      justifyContent="center"
      flexDirection="row"
      boxShadow={style["box-shadow"]}
      border={style["container-border"]}
      rounded={style["border-radius1"]}
      textAlign="center"
      paddingBottom={isPc ? style["container-padding-bottom"] : "20px"}
      paddingTop={isPc ? style["container-padding-top"] : "22px"}
      paddingInline={isPc ? style["container-padding-inline"] : "20px"}
      order={order}
    >
      {children}
    </Box>
  );
}
