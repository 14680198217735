import { Box, Container, Image } from "@chakra-ui/react";
import style from "../../../assets/style.json";
import part1 from "../../../assets/part1.png";
import logoDark from "../../../assets/logo-dark.png";
import part2 from "../../../assets/part2.png";

export function Logo({ style, isDark, isShowStand = false }: any) {
  return (
    <Box
      display="flex"
      width={"max-content"}
      height="max-content"
      flexDirection={"column"}
      alignItems="center"
      justifyContent="center"
      maxW="full"
      gap={"6px"}
      {...style}
    >
      <Image src={isDark ? logoDark : part1} />
      {!isDark && isShowStand && <Image src={part2} />}
    </Box>
  );
}
