export function MenuIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 17.4688C14.1217 17.4688 15.0312 18.3783 15.0312 19.5C15.0312 20.6217 14.1217 21.5312 13 21.5312C11.8783 21.5312 10.9688 20.6217 10.9688 19.5C10.9688 18.3783 11.8783 17.4688 13 17.4688ZM13 10.9688C14.1217 10.9688 15.0312 11.8783 15.0312 13C15.0312 14.1217 14.1217 15.0312 13 15.0312C11.8783 15.0312 10.9688 14.1217 10.9688 13C10.9688 11.8783 11.8783 10.9688 13 10.9688ZM13 4.46875C14.1217 4.46875 15.0312 5.37834 15.0312 6.5C15.0312 7.62166 14.1217 8.53125 13 8.53125C11.8783 8.53125 10.9688 7.62166 10.9688 6.5C10.9688 5.37834 11.8783 4.46875 13 4.46875Z"
        fill="#0E314E"
      />
    </svg>
  );
}
