import axios from "axios";
import { appConfig } from "../configs/app";

class ExposureService {
  static async read() {
    const response = await axios.get(`${appConfig.apiUrl}/exposure`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
    const data = response.data;
    return data;
  }

  static async getById(id: string) {
    const response = await axios.get(`${appConfig.apiUrl}/exposure/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
    const data = response.data;
    return data;
  }

  static async categories() {
    const response = await axios.get(`${appConfig.apiUrl}/exposure/categories`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
    const data = response.data;
    return data;
  }
  static async companyCategories(id: string) {
    const response = await axios.get(`${appConfig.apiUrl}/exposure/company/categories/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
    const data = response.data;
    return data;
  }

  static async category(id: string) {
    const response = await axios.get(`${appConfig.apiUrl}/exposure/category/${id}`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
    const data = response.data;
    return data;
  }

  static async companyCategory(id: string, companyId:String) {
    const response = await axios.get(`${appConfig.apiUrl}/exposure/company/category/${id}/${companyId}`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
    const data = response.data;
    return data;
  }

  static async companies() {
    const response = await axios.get(`${appConfig.apiUrl}/exposure/all`, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
    const data = response.data;
    return data;
  }


}

export default ExposureService;
