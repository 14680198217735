import axios from "axios";
import { appConfig } from "../configs/app";

class ReportsService {
    static async list(companyId: string) {
        try {
            const response = await axios.get(`${appConfig.apiUrl}/reports/${companyId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            return response.data;
        } catch (error) {
            return null;
        }
    }

    static async current(companyId: string) {
        try {
            const response = await axios.get(`${appConfig.apiUrl}/reports/current/${companyId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            return response.data;
        } catch (error) {
            return null;
        }
    }

    static async getForEmployee(employeeId: string) {
        try {
            const response = await axios.get(`${appConfig.apiUrl}/reports/user/${employeeId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            return response.data;
        } catch (error) {
            return null;
        }
    }
}

export default ReportsService;