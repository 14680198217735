export const appConfig = {
  apiUrl:
    window.location.origin.includes('sby') ||
    window.location.origin.includes('compleyes')
      ? window.location.origin.includes('dev')
        ? 'https://server-dev.sbykatzlaw.com'
        : 'https://api.sbykatzlaw.com'
      : 'http://localhost:8081',
  // 'https://api.sbykatzlaw.com',
};
