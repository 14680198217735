import axios from 'axios';
import { appConfig } from '../configs/app';

class AuthService {
  static async isLoggedIn() {
    return !!localStorage.getItem('token');
  }

  static role: any = null;

  static createResponseInterceptor() {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        // Reject promise if usual error
        if (error.response?.status !== 401) {
          return Promise.reject(error);
        }

        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        axios.interceptors.response.eject(interceptor);

        const accessToken = await AuthService.refreshToken();
        error.response.config.headers.Authorization = `Bearer ${accessToken}`;
        return axios(error.response.config);
      },
    );
  }

  static async login(
    identifier: string,
    verifyCode: string,
    isRemember: boolean,
    rid?: string | null,
  ) {
    const url =
      rid !== null
        ? `${appConfig.apiUrl}/login?rid=${rid}`
        : `${appConfig.apiUrl}/login`;
    const response = await axios.post(url, {
      identifier,
      verifyCode,
      isRemember,
    });
    const data = response.data;
    if (!data || !data.token) {
      return;
    }

    localStorage.setItem('token', data.token);
    localStorage.setItem('refresh_token', data.refresh_token);
    return data;
  }

  static async sendCode(identifier: string) {
    const response = await axios.post(`${appConfig.apiUrl}/sendCode`, {
      identifier,
    });
    const data = response.data;
  }

  static async sendCall(identifier: string) {
    const response = await axios.post(`${appConfig.apiUrl}/callCode`, {
      identifier,
    });
    const data = response.data;
  }

  static async refreshToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
      throw new Error(
        'Cannot refresh access token because refresh token is missing',
      );
    }

    const res = await axios.get(`${appConfig.apiUrl}/token/refresh`, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });

    localStorage.setItem('token', res.data.token);
    localStorage.setItem('refresh_token', res.data.refresh_token);
    return res.data.token;
  }

  static async logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
  }

  /**
   * Password Reset
   */

  static async initiateReset(email: string) {
    const response = await axios.post(`${appConfig.apiUrl}/reset`, {
      email,
    });
    const data = response.data;
    return data;
  }

  static async checkResetToken(token: string) {
    const response = await axios.get(`${appConfig.apiUrl}/reset/${token}`);
    const data = response.data;
    return data;
  }

  static async reset(token: string, password: string) {
    const response = await axios.post(`${appConfig.apiUrl}/reset/${token}`, {
      password,
    });
    const data = response.data;
    return data;
  }

  static setRole(role: any) {
    this.role = role;
  }

  static getRole() {
    return this.role;
  }
}

AuthService.createResponseInterceptor();

export default AuthService;
