import React from "react";
import useEventListener from "./useEventListener";

export default function useClickOutside(
  ref: React.RefObject<HTMLElement>,
  cb: (event: Event) => void
) {
  useEventListener(
    "click",
    (e) => {
      if (ref.current == null || ref.current.contains(e.target)) return;
      cb(e);
    },
    document
  );
}
