export function EditTaskIcon(props: any) {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `scale(${props.scale ? props.scale : 1})`,
      }}
    >
      <path
        d="M3.375 0.6875C1.82156 0.6875 0.5625 1.94656 0.5625 3.5V18.5C0.5625 20.0534 1.82156 21.3125 3.375 21.3125H14.625C16.1784 21.3125 17.4375 20.0534 17.4375 18.5V3.5C17.4375 1.94656 16.1784 0.6875 14.625 0.6875H3.375ZM3.375 2.5625H14.625C15.143 2.5625 15.5625 2.98203 15.5625 3.5V18.5C15.5625 19.018 15.143 19.4375 14.625 19.4375H3.375C2.85703 19.4375 2.4375 19.018 2.4375 18.5V3.5C2.4375 2.98203 2.85703 2.5625 3.375 2.5625ZM5.71875 4.4375C5.20125 4.4375 4.78125 4.8575 4.78125 5.375C4.78125 5.8925 5.20125 6.3125 5.71875 6.3125H12.2812C12.7987 6.3125 13.2188 5.8925 13.2188 5.375C13.2188 4.8575 12.7987 4.4375 12.2812 4.4375H5.71875ZM5.25 8.65625C4.7325 8.65625 4.3125 9.07625 4.3125 9.59375C4.3125 10.1112 4.7325 10.5312 5.25 10.5312H9.46875C9.98625 10.5312 10.4062 10.1112 10.4062 9.59375C10.4062 9.07625 9.98625 8.65625 9.46875 8.65625H5.25ZM12.75 8.65625C12.232 8.65625 11.8125 9.07578 11.8125 9.59375C11.8125 10.1117 12.232 10.5312 12.75 10.5312C13.268 10.5312 13.6875 10.1117 13.6875 9.59375C13.6875 9.07578 13.268 8.65625 12.75 8.65625ZM5.25 11.9375C4.7325 11.9375 4.3125 12.3575 4.3125 12.875C4.3125 13.3925 4.7325 13.8125 5.25 13.8125H8.0625C8.58 13.8125 9 13.3925 9 12.875C9 12.3575 8.58 11.9375 8.0625 11.9375H5.25ZM12.75 11.9375C12.232 11.9375 11.8125 12.357 11.8125 12.875C11.8125 13.393 12.232 13.8125 12.75 13.8125C13.268 13.8125 13.6875 13.393 13.6875 12.875C13.6875 12.357 13.268 11.9375 12.75 11.9375ZM5.25 15.2188C4.7325 15.2188 4.3125 15.6388 4.3125 16.1562C4.3125 16.6737 4.7325 17.0938 5.25 17.0938H9C9.5175 17.0938 9.9375 16.6737 9.9375 16.1562C9.9375 15.6388 9.5175 15.2188 9 15.2188H5.25ZM12.75 15.2188C12.232 15.2188 11.8125 15.6383 11.8125 16.1562C11.8125 16.6742 12.232 17.0938 12.75 17.0938C13.268 17.0938 13.6875 16.6742 13.6875 16.1562C13.6875 15.6383 13.268 15.2188 12.75 15.2188Z"
        fill="currentColor"
      />
    </svg>
  );
}
